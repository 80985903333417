<template>
	<div class="content">
		<div class="uni-padding-wrap uni-common-mt">

			<div v-for="(item, index) in data.list" :key="item.name" @click="cellclicked(item, index)"
				style="margin-bottom: 0px;" class="banner">

				<wx-open-launch-weapp v-if="wxready && item.type=='MiniProgram'" class='wxbtn' :username="item.id"
					:path="item.path" ref="wxLaunchBtn" id="launch-btn">
					<script type="text/wxtag-template">
						<div style="width: 500px;height:500px;background-color:">
						</div>
					</script>
				</wx-open-launch-weapp>

				<img :src="item.img" style="width: 100%;" mode="widthFix"></img>
			</div>

		</div>
	</div>
</template>

<script>
	import {
		mapActions,
		mapState,
		store
	} from 'vuex'
	import hhstore from '../utils/HHStore'
	import help from '../utils/Help'

	export default {
		data() {
			return {
				data: [],
				wxready: false,
			}
		},
		async mounted() {
			this.data = JSON.parse(hhstore.get('yinge'))
			console.info(this.data)
			document.title = this.data.name

			this.wxconfig()
		},

		onShow() {},
		methods: {
			...mapActions(['post', 'syncpost']),
			async cellclicked(item, index) {
				if (item.type == "H5") {
					this.launchH5(item)
				} else if (item.type == "MiniProgram") {
					this.launchMiniProgram(item)
				}
			},
			
			launchH5(item) {
				help.click_log(item.name, item.src);
				window.location.href = item.src;
			},
			launchMiniProgram(item) {
				help.click_log(item.name, item.src);
				window.location.href = item.src
			},
			async wxconfig(data) {
				try {
					var data = await this.syncpost({
						url: "wx/getSignPackage",
						data: {
							"url": window.location.href,
						}
					});
				} catch (e) {
					console.info(e)
					return;
				}

				wx.config({
					debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印
					appId: data.appId, // 必填，公众号的唯一标识
					timestamp: data.timestamp.toString(), // 必填，生成签名的时间戳
					nonceStr: data.nonceStr, // 必填，生成签名的随机串
					signature: data.signature, // 必填，签名
					jsApiList: ['getLocation'], // 必填，需要使用的JS接口列表
					openTagList: ['wx-open-launch-weapp'] // 可选，需要使用的开放标签列表，例如['wx-open-launch-app']
				});

				wx.ready(() => {
					if (hhstore.get('token'))
						this.wxready = true
					// config信息验证后会执行ready方法，所有接口调用都必须在config接口获得结果之后，config是一个客户端的异步操作，所以如果需要在页面加载时就调用相关接口，则须把相关接口放在ready函数中调用来确保正确执行。对于用户触发时才调用的接口，则可以直接调用，不需要放在ready函数中
					console.info("ready")
				});

				wx.error((res) => {
					// config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名
					console.info(res)
				});

			},
		}
	}
</script>

<style scoped>
	.wxbtn {
		top: 0px;
		left: 0px;
		right: 0px;
		bottom: 0px;
		z-index: 1;
		background-color: rgba(255, 0, 0, 0);
		overflow: hidden;
		position: absolute;
	}

	.banner {
		overflow: hidden;
		position: relative;
	}
</style>
