<template>
	<div class="bg">
		<br /><br />
		<button @click="success">支付成功</button>
		<br /><br /><br />
		<button @click="failure">支付失败</button>
	</div>
</template>
<script>
	import {
		mapActions,
		mapState
	} from 'vuex'
	import {
		Toast
	} from 'vant';
	import urlParams from '../utils/UrlParams'
	export default {
		name: 'Pay',
		components: {

		},
		data: function() {
			return {

			}
		},
		props: {

		},
		beforeMount() {

		},
		mounted() {

		},
		beforeDestroy() {

		},
		computed: {
			...mapState([])
		},
		methods: {
			...mapActions(['post']),
			success() {
				let orderSn = urlParams.get('orderSn')
				if(!orderSn){
					Toast.fail('参数错误');
					return;
				}
				
				this.post({
					url: "hwdy/pay_notify",
					data: {
						"orderSn": orderSn,
						"status": 'PAYED'
					},
					success: function(data) {
						Toast.success('成功');
						window.location.href = data;
					},
					error: function(code, err) {
						Toast.fail(err);

					}
				});
			},
			failure() {
				let orderSn = urlParams.get('orderSn')
				if(!orderSn){
					Toast.fail('参数错误');
					return;
				}
				
				this.post({
					url: "hwdy/pay_notify",
					data: {
						"orderSn": orderSn,
						"failReason": '支付失败',
						"status": 'PAY_FAIL'
					},
					success: function(data) {
						Toast.success('成功');
						window.location.href = data;
					},
					error: function(code, err) {
						Toast.fail(err);
					}
				});
			}
		}
	}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
