<template>
	<div class="content">

		<div class="scrolldiv">
			<div v-for="(item, index) in data.list" :key="item.name" @click="cellclicked(item, index)"
				style="margin-bottom: 0px;" class="itemdiv">

				<wx-open-launch-weapp v-if="wxready && item.type == 'MiniProgram'" class='wxbtn' :username="item.id"
					:path="item.path" ref="wxLaunchBtn" id="launch-btn">
					<script type="text/wxtag-template">
						<div style="width: 500px;height:500px;background-color:"></div>
											</script>
				</wx-open-launch-weapp>

				<img class="itemimg" :src="item.img" mode="widthFix" @click="imgclick(item)" />
				<div class="itemdesc">
					<div class="itemdesc-left">
						<img class="itemicon" :src="item.icon" v-if="item.icon" />
						<span class="itemname">{{ item.name }}</span>
					</div>
					<div class="itemdesc-right" v-if="item.price">{{ isNaN(item.price) ? "" : "￥" }}{{ item.price }}</div>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
import {
	mapActions,
	mapState,
	store
} from 'vuex'
import hhstore from '../utils/HHStore'
import help from '../utils/Help'

export default {
	data() {
		return {
			data: {
				name: '美团限时特惠',
				list: [{
					"type": "MiniProgram",
					"name": "辣堡4件套",
					"path": "/index/pages/h5/h5?weburl=https%3A%2F%2Fdpurl.cn%2FU3BLtVnz&lch=cps:waimai:5:bae4ac0615d00a1ca6febe75bcce73d1:001:245:338408&f_token=1&f_userId=1",
					"id": "gh_870576f3c6f9",
					"price": "32",
					"src": "https://click.meituan.com/t?t=1&c=2&p=GlfrY75z0n5b",
					"img": "http://img.123zhs.com/h5/meituan/kfc4jt.jpg?x-oss-process=image/resize,w_300/quality,q_80",
					"icon": "http://img.123zhs.com/mp-qyw/logo/kfc.png?x-oss-process=image/resize,w_100/quality,q_80",
				}, {
					"type": "MiniProgram",
					"name": "辣翅2对",
					"path": "/index/pages/h5/h5?weburl=https%3A%2F%2Fdpurl.cn%2FKqdv0Vjz&lch=cps:waimai:5:bae4ac0615d00a1ca6febe75bcce73d1:001:239:338408&f_token=1&f_userId=1",
					"src": "https://click.meituan.com/t?t=1&c=2&p=VP_oY75z0n5b",
					"id": "gh_870576f3c6f9",
					"price": "9.9",
					"img": "http://img.123zhs.com/h5/meituan/kfclc.jpg?x-oss-process=image/resize,w_300/quality,q_80",
					"icon": "http://img.123zhs.com/mp-qyw/logo/kfc.png?x-oss-process=image/resize,w_100/quality,q_80",
				}, {

					"type": "MiniProgram",
					"name": "雪王大圣代",
					"path": "/index/pages/h5/h5?weburl=https%3A%2F%2Fdpurl.cn%2F9Hc75Lrz&lch=cps:waimai:5:bae4ac0615d00a1ca6febe75bcce73d1:001:236:338408&f_token=1&f_userId=1",
					"id": "gh_870576f3c6f9",
					"src": "https://click.meituan.com/t?t=1&c=2&p=8BThY75z0n5b",
					"price": "1",
					"img": "http://img.123zhs.com/h5/meituan/bxmc.jpg?x-oss-process=image/resize,w_300/quality,q_80",
					"icon": "http://img.123zhs.com/mp-qyw/logo/mxbc.png?x-oss-process=image/resize,w_100/quality,q_80",
				}, {
					"type": "MiniProgram",
					"name": "香草拿铁双杯",
					"path": "/index/pages/h5/h5?weburl=https%3A%2F%2Fdpurl.cn%2FEOc55L3z&lch=cps:waimai:5:bae4ac0615d00a1ca6febe75bcce73d1:001:242:338408&f_token=1&f_userId=1",
					"id": "gh_870576f3c6f9",
					"src": "https://click.meituan.com/t?t=1&c=2&p=FGLpY75z0n5b",
					"price": "36",
					"img": "http://img.123zhs.com/h5/meituan/xbk.jpg?x-oss-process=image/resize,w_300/quality,q_80",
					"icon": "http://img.123zhs.com/mp-qyw/logo/xbk.png?x-oss-process=image/resize,w_100/quality,q_80",
				}, {
					"type": "MiniProgram",
					"name": "生椰拿铁",
					"path": "/index/pages/h5/h5?weburl=https%3A%2F%2Fdpurl.cn%2FIKztBVGz&lch=cps:waimai:5:bae4ac0615d00a1ca6febe75bcce73d1:001:213:338408&f_token=1&f_userId=1",
					"id": "gh_870576f3c6f9",
					"src": "https://click.meituan.com/t?t=1&c=2&p=SKPiY75z0n5b",
					"price": "9.9",
					"img": "http://img.123zhs.com/h5/meituan/rx.jpg?x-oss-process=image/resize,w_300/quality,q_80",
					"icon": "http://img.123zhs.com/mp-qyw/logo/rx.png?x-oss-process=image/resize,w_100/quality,q_80",
				}, {
					"type": "MiniProgram",
					"name": "水牛乳双拼",
					"path": "/index/pages/h5/h5?weburl=https%3A%2F%2Fdpurl.cn%2F9T0ACwIz&lch=cps:waimai:5:bae4ac0615d00a1ca6febe75bcce73d1:001:241:338408&f_token=1&f_userId=1",
					"id": "gh_870576f3c6f9",
					"src": "https://click.meituan.com/t?t=1&c=2&p=JBe_Y75z0n5b",
					"price": "16",
					"img": "http://img.123zhs.com/h5/meituan/xc.jpg?x-oss-process=image/resize,w_300/quality,q_80",
					"icon": "http://img.123zhs.com/mp-qyw/logo/xc.png?x-oss-process=image/resize,w_100/quality,q_80",
				}, {
					"type": "MiniProgram",
					"name": "茉莉奶绿中杯",
					"path": "/index/pages/h5/h5?weburl=https%3A%2F%2Fdpurl.cn%2F1Y7n5X8z&lch=cps:waimai:5:bae4ac0615d00a1ca6febe75bcce73d1:001:214:338408&f_token=1&f_userId=1",
					"id": "gh_870576f3c6f9",
					"src": "https://click.meituan.com/t?t=1&c=2&p=0vTrY75z0n5b",
					"price": "9.9",
					"img": "http://img.123zhs.com/h5/meituan/cbd.jpg?x-oss-process=image/resize,w_300/quality,q_80",
					"icon": "http://img.123zhs.com/mp-qyw/logo/cbd.png?x-oss-process=image/resize,w_100/quality,q_80",
				}, {
					"type": "MiniProgram",
					"name": "辣翅3件套",
					"path": "/index/pages/h5/h5?weburl=https%3A%2F%2Fdpurl.cn%2Ffm2cewjz&lch=cps:waimai:5:bae4ac0615d00a1ca6febe75bcce73d1:001:235:338408&f_token=1&f_userId=1",
					"id": "gh_870576f3c6f9",
					"src": "https://click.meituan.com/t?t=1&c=2&p=rdroY75z0n5b",
					"price": "9.9",
					"img": "http://img.123zhs.com/h5/meituan/hls3jt.jpg?x-oss-process=image/resize,w_300/quality,q_80",
					"icon": "http://img.123zhs.com/mp-qyw/logo/hls.png?x-oss-process=image/resize,w_100/quality,q_80",
				}, {
					"type": "MiniProgram",
					"name": "辣翅1对",
					"path": "/index/pages/h5/h5?weburl=https%3A%2F%2Fdpurl.cn%2FgQIv2RWz&lch=cps:waimai:5:bae4ac0615d00a1ca6febe75bcce73d1:001:244:338408&f_token=1&f_userId=1",
					"id": "gh_870576f3c6f9",
					"src": "https://click.meituan.com/t?t=1&c=2&p=o0HtY75z0n5b",
					"price": "5",
					"img": "http://img.123zhs.com/h5/meituan/hlslc.jpg?x-oss-process=image/resize,w_300/quality,q_80",
					"icon": "http://img.123zhs.com/mp-qyw/logo/hls.png?x-oss-process=image/resize,w_100/quality,q_80",
				}, {
					"type": "MiniProgram",
					"name": "大牌蛋糕",
					"path": "/index/pages/h5/h5?weburl=https%3A%2F%2Fdpurl.cn%2FGlsBbJgz&lch=cps:waimai:5:bae4ac0615d00a1ca6febe75bcce73d1:001:225:338408&f_token=1&f_userId=1",
					"id": "gh_870576f3c6f9",
					"src": "https://click.meituan.com/t?t=1&c=2&p=RNzoY75z0n5b",
					"price": "3折起",
					"img": "http://img.123zhs.com/h5/meituan/cake.jpg?x-oss-process=image/resize,w_300/quality,q_80",
					// "icon": "http://img.123zhs.com/mp-qyw/logo/rx.png?x-oss-process=image/resize,w_100/quality,q_80",
				}, {
					"type": "MiniProgram",
					"name": "欢聚4人餐",
					"path": "/index/pages/h5/h5?weburl=https%3A%2F%2Fdpurl.cn%2FpPSoQAnz&lch=cps:waimai:5:bae4ac0615d00a1ca6febe75bcce73d1:001:228:338408&f_token=1&f_userId=1",
					"id": "gh_870576f3c6f9",
					"src": "https://click.meituan.com/t?t=1&c=2&p=C2LqY75z0n5b",
					"price": "199",
					"img": "http://img.123zhs.com/h5/meituan/hdl.jpg?x-oss-process=image/resize,w_300/quality,q_80",
					"icon": "http://img.123zhs.com/mp-qyw/logo/hdl.png?x-oss-process=image/resize,w_100/quality,q_80",
				}, {
					"type": "MiniProgram",
					"name": "牛蛙煲",
					"path": "/index/pages/h5/h5?weburl=https%3A%2F%2Fdpurl.cn%2Fd216xWRz&lch=cps:waimai:5:bae4ac0615d00a1ca6febe75bcce73d1:001:227:338408&f_token=1&f_userId=1",
					"id": "gh_870576f3c6f9",
					"src": "https://click.meituan.com/t?t=1&c=2&p=9CTgY75z0n5b",
					"price": "119",
					"img": "http://img.123zhs.com/h5/meituan/pgl.jpg?x-oss-process=image/resize,w_300/quality,q_80",
					"icon": "http://img.123zhs.com/mp-qyw/logo/pgl.png?x-oss-process=image/resize,w_100/quality,q_80",
				},]
			},
			wxready: false,
		}
	},
	async mounted() {
		// this.data = JSON.parse(hhstore.get('yinge'))
		console.info(this.data)
		document.title = this.data.name

		this.wxconfig()
	},

	onShow() { },
	methods: {
		...mapActions(['post', 'syncpost']),
		async cellclicked(item, index) {
			if (item.type == "H5") {
				this.launchH5(item)
			} else if (item.type == "MiniProgram") {
				this.launchMiniProgram(item)
			}
		},

		launchH5(item) {
			help.click_log(item.name, item.src);
			window.location.href = item.src;
		},
		launchMiniProgram(item) {
			help.click_log(item.name, item.src);
			window.location.href = item.src
		},
		imgclick(item) {
			window.location.href = item.src
		},
		async wxconfig(data) {
			try {
				var data = await this.syncpost({
					url: "wx/getSignPackage",
					data: {
						"url": window.location.href,
					}
				});
			} catch (e) {
				console.info(e)
				return;
			}

			wx.config({
				debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印
				appId: data.appId, // 必填，公众号的唯一标识
				timestamp: data.timestamp.toString(), // 必填，生成签名的时间戳
				nonceStr: data.nonceStr, // 必填，生成签名的随机串
				signature: data.signature, // 必填，签名
				jsApiList: ['getLocation'], // 必填，需要使用的JS接口列表
				openTagList: ['wx-open-launch-weapp'] // 可选，需要使用的开放标签列表，例如['wx-open-launch-app']
			});

			wx.ready(() => {
				if (hhstore.get('token'))
					this.wxready = true
				// config信息验证后会执行ready方法，所有接口调用都必须在config接口获得结果之后，config是一个客户端的异步操作，所以如果需要在页面加载时就调用相关接口，则须把相关接口放在ready函数中调用来确保正确执行。对于用户触发时才调用的接口，则可以直接调用，不需要放在ready函数中
				console.info("ready")
			});

			wx.error((res) => {
				// config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名
				console.info(res)
			});

		},
	}
}
</script>

<style scoped>
.content {
	height: 100vh;
	background-color: #F8F8F8;
	overflow-y: scroll;

}

.scrolldiv {
	margin-left: 1.25rem;
	margin-top: 1.25rem;
	margin-bottom: 3.125rem;
	display: flex;
	flex-wrap: wrap;
	gap: .9375rem;
}

.itemdiv {
	width: 10.8rem;
	background-color: white;
	border-radius: 10px;
	padding-bottom: 10px;
	position: relative;
}

.itemimg {
	width: 100%;
	border-radius: 10px 10px 0px 0px;
}

.itemdesc {
	display: flex;
	align-items: center;
	margin-top: 0.5rem;
	justify-content: space-between;
	margin-left: 5px;
	margin-right: 5px;
}

.itemdesc-left {
	display: flex;
	align-items: center;
}

.itemicon {
	width: 1.5625rem;
}

.itemname {
	font-size: .875rem;
	font-weight: bold;
	color: #333;
	margin-left: .3125rem;
}

.itemdesc-right {
	font-size: .875rem;
	font-weight: bold;
	color: #E84A26;
}

.wxbtn {
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;
	z-index: 1;
	background-color: rgba(255, 0, 0, 0);
	overflow: hidden;
	position: absolute;
}

.banner {
	overflow: hidden;
	position: relative;
}
</style>
