<template>
	<div>
	</div>
</template>
<script>
	import {
		mapActions,
		mapState
	} from 'vuex'
	import validate from '../utils/Validate'
	import hhstore from '../utils/HHStore'
	import urlParams from '../utils/UrlParams'
	import {
		Toast
	} from 'vant';


	/* 用来微信授权用 ，授权完成后，根据  localStorage.auth_back 地址，跳回原网址 */

	export default {
		name: 'register',
		data: function() {
			return {

			}
		},
		props: {

		},
		beforeMount() {

		},
		mounted() {
			if (!hhstore.get("token")) {
				this.$router.push('/register')
				return
			}
			
			let appid = ''			
			if (process.env.NODE_ENV === "development") {
				appid = "wxfbd9f6d6aa3236be";
			} else {
				appid = "wx1a5b1b2a6bd07ea0";
			}
			
			const UA = navigator.userAgent.toLowerCase()
			const inWechat = UA.match(/.*?(micromessenger\/([0-9.]+))\s*/)
			if (inWechat) {
				if (!urlParams.get('code')) {
					window.location.href =
						"https://open.weixin.qq.com/connect/oauth2/authorize?appid=" + appid + "&redirect_uri=" +
						encodeURIComponent(window.location.origin + window.location.pathname) +
						"&response_type=code&scope=snsapi_base#wechat_redirec";
				} else {
					var authBack = hhstore.get('auth_back')
					var url = "wx/code2openid";
					var self = this;
					this.post({
						url: url,
						data: {
							"code": urlParams.get('code'),
							"uid": hhstore.get("uid"),
							"token": hhstore.get("token")
						},
						success: function() {
							if (authBack) {
								hhstore.del('auth_back');
								self.$router.push(authBack)
							} else {
								self.$router.push('/')
							}
						},
						error: function(code, err) {
							Toast.fail(err);
							if (authBack) {
								hhstore.del('auth_back');
								self.$router.push(authBack)
							} else {
								self.$router.push('/')
							}
						}
					});
				}
			}
		},
		beforeDestroy() {

		},
		computed: {
			...mapState([])
		},
		methods: {
			...mapActions(['post']),

		}
	}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
