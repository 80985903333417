<template>
    <div class="formdiv">
        <div v-show="!showContent">
            <div>输入提取码</div>
            <div>
                <input type="" name="" ref="code">
            </div>
            <div>
                <button v-on:click="btnclick">提交</button>
            </div>
        </div>
        <div v-show="showContent">
            <table>
                <tr>
                    <th></th>
                    <th>卡号</th>
                    <th>卡密</th>
                </tr>
                <tr v-for="(data, index) in datas" :key="index">
                    <td>{{index+1}}</td>
                    <td>{{data.card_number}}</td>
                    <td>{{data.card_secret}}</td>
                </tr>
            </table>
        </div>
    </div>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import { Toast } from 'vant';

export default {
    name: 'list',
    data: function() {
        return {
            showContent: false,
            datas: [],
        }
    },
    props: {

    },
    beforeMount() {

    },
    mounted() {

    },
    computed: {
        ...mapState([])
    },
    methods: {
        ...mapActions(['post']),
        btnclick: function() {
            var url = "oldCard/lingka";
            var self = this;
            this.post({
                url: url,
                data: {
                    "card_no": this.$refs.code.value,
                },
                success: function(data) {
                    self.datas = data;
                    self.showContent = true;
                },
                error: function(code, err) {
                    Toast.fail(err);
                }
            });

        }
    }
}
</script>
<style scoped>
.formdiv {
    width: 50vw;
    margin: auto;
    margin-top: 3rem;
}

textarea {
    width: 100%;
    height: 8rem;
}

input {
    width: 50%;
}

button {
    width: 30%;
    height: 1rem;
    margin-top: 1rem;
}

table {
    font-family: verdana, arial, sans-serif;
    font-size: 11px;
    color: #333333;
    border-width: 1px;
    border-color: #999999;
    border-collapse: collapse;
    width: 100%;
}

table th {
    background: #b5cfd2;
    border-width: 1px;
    padding: 8px;
    border-style: solid;
    border-color: #999999;
}

table td {
    background: #dcddc0;
    border-width: 1px;
    padding: 8px;
    border-style: solid;
    border-color: #999999;
}
</style>