<script>
import CryptoJs from 'crypto-js'
var md5 = require('md5')
export default {
	/**
	 * 加密
	 */
	encrypt(word, k, sec) {
		let key = CryptoJs.enc.Utf8.parse(md5(k).substr(0, 16));
		let iv = CryptoJs.enc.Utf8.parse(sec.substr(0, 16));
		let srcs = CryptoJs.enc.Utf8.parse(word);
		let encrypted = CryptoJs.AES.encrypt(srcs, key, {
			mode: CryptoJs.mode.CBC,
			padding: CryptoJs.pad.Pkcs7,
			iv: iv,
		});
		return encrypted.toString();
	},

	/**
	 * 解密
	 */
	decrypt(word, k, sec) {
		let key = CryptoJs.enc.Utf8.parse(md5(k).substr(0, 16));
		let iv = CryptoJs.enc.Utf8.parse(sec.substr(0, 16));
		let decrypt = CryptoJs.AES.decrypt(word, key, {
			mode: CryptoJs.mode.CBC,
			padding: CryptoJs.pad.Pkcs7,
			iv: iv,
		});
		return CryptoJs.enc.Utf8.stringify(decrypt).toString();
	},
	//解密方法,小程序过来的
	decrypt_mini(word, k, iv) {
		const kk = CryptoJs.enc.Utf8.parse(k); //十六位十六进制数作为密钥
		const iiv = CryptoJs.enc.Utf8.parse(iv); //十六位十六进制数作为密钥偏移量
		let encryptedHexStr = CryptoJs.enc.Hex.parse(word);
		let srcs = CryptoJs.enc.Base64.stringify(encryptedHexStr);
		let decrypt = CryptoJs.AES.decrypt(srcs, kk, {
			iv: iiv,
			mode: CryptoJs.mode.CBC,
			padding: CryptoJs.pad.Pkcs7
		});
		let decryptedStr = decrypt.toString(CryptoJs.enc.Utf8);
		return decryptedStr.toString();
	},

	// 用来视频券码的加密
	encryptAES(text, key, iv) {

		// 使用 AES 进行加密
		const encrypted = CryptoJs.AES.encrypt(
			CryptoJs.enc.Utf8.parse(text),
			CryptoJs.enc.Utf8.parse(key),
			{ iv: CryptoJs.enc.Utf8.parse(iv), mode: CryptoJs.mode.CBC, padding: CryptoJs.pad.Pkcs7 }
		);

		return encrypted.toString();
	},

	//用来视频券码的解密
	decryptAES(text, key, iv) {
		var result = CryptoJs.AES.decrypt(text, CryptoJs.enc.Utf8.parse(key), {
			iv: CryptoJs.enc.Utf8.parse(iv),
			mode: CryptoJs.mode.CBC,
			padding: CryptoJs.pad.Pkcs7
		});
		return result.toString(CryptoJs.enc.Utf8)
	},

	getkey() {
		return ['y', 's', 'h', '4', '2', '5', 'p', 't', 'j', 'h', 'c', 'p', 'o', '7', '8', '1'].join('')
	},

	getiv() {
		return ['5', '3', 'c', '5', 'a', '6', '4', 'c', '6', 'b', 'e', '8', '4', 'e', '9', '7'].join('')
	}

}
</script>
