<template>
    <div class="list">
        <div class="list_1"">
           
            <!-- 肯德基 -->
            <a class='astyle' v-show="cardType=='ky'" v-on:click="kfcclick">
                <img src="http://img.123zhs.com/assets/products/kfc.png?x-oss-process=image/resize,w_1500/quality,q_80" ></a>
            

        </div>
        
    </div>
    </div>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import hhstore from '../utils/HHStore'
import urlParams from '../utils/UrlParams'
import wxutils from '../utils/Wx'
import { Toast } from 'vant';
import Vue from 'vue';
import { NoticeBar } from 'vant';
import { Dialog } from 'vant';
var md5 = require('md5')
import { Form } from 'vant';
import { Field } from 'vant';

Vue.use(Form);
Vue.use(Field);
Vue.use(NoticeBar);

export default {
    name: 'list',
    components: {
        [Dialog.Component.name]: Dialog.Component,
    },
    data: function() {
        return {
            info: null,
            cardType: null,
            kfcurl: "http://kfc.quhaodian.cn/diancan/new.html?v=1.1&platmnt=yiluxue8&notify_url=http://api.zhsyh.cn/callback/kfc_ylx",
        }
    },
    props: {

    },
    beforeMount() {

    },
    mounted() {

        // 获取卡类型，存本地，每个网络请求都带上这个参数
        this.cardType = "ky"
        hhstore.set('card_type', this.cardType)

        if (!hhstore.get("token")) {
            hhstore.set('login_back', "/kfc")
            this.$router.push('/register')    
            return;
        }

        var self = this;
        var uid = hhstore.get("uid");
        var token = hhstore.get("token");
        this.post({
            url: "user/info",
            data: {
                "uid": uid,
                "token": token
            },
            success: function(data) {
                console.info(data)
                self.kfcurl = self.kfcurl + "&buyer_phone=" + data.phone
                self.info = data;                
            },
            error: function(code, err) {
                Toast.fail(err);
                if (code == 92834 || code == 10002 || code == 10003) {
                    hhstore.del("uid")
                    hhstore.del("phone")
                    hhstore.del("token")
                    hhstore.set('login_back', "/kfc"+window.location.search)
                    self.$router.push('/register')
                }
            }
        });

    },
    computed: {
        ...mapState([])
    },
    methods: {
        ...mapActions(['post']),
    
        kfcclick() {
            window.location.href = this.kfcurl
        },
    }
}
</script>
<style scoped>
.welcomediv{
    position: relative;
}
.dueDate {
    position: absolute;
    right: 0.5rem;
    bottom: 10px;
    color: #fff;
    font-size: 1rem;
    border-radius: 5px;
    border-color: #000;
    background-color: #C9B186;
    padding: 0px 5px;
}


.astyle{
    display: block;
}

.astyle > img{
    background-size: contain|cover;
    width: 100%;
    height: auto;
}

.wxbtn{
    display: block;
}
.topdiv{
    background-color: #c9b186;
    height: 25px;
}
</style>