<template>
	<div class="content">
		<div class="topimg">
		</div>

		<div class="inputdiv">
			<input v-model="phone" placeholder="请输入您的手机号用于接受兑换码" />
		</div>

		<div class="bottombtn" @click="btnclick">
			<img src="http://img.123zhs.com/h5/film/9.png">
		</div>
		<div class="title">
			<img src="http://img.123zhs.com/h5/film/2.png">
			<div class="kacontent">
				<div class="kabao">
					<div class="condeta">
						<div class="condeta-1">¥</div>
						<div class="condeta-2">5元</div>
						<div class="condeta-3">*1张</div>
					</div>
					<span>满25减5优惠券*1张</span>
				</div>
				<div class="kabao">
					<div class="condeta">
						<div class="condeta-1">¥</div>
						<div class="condeta-2">6元</div>
						<div class="condeta-3">*1张</div>

					</div>
					<span>满35减6优惠券*1张</span>
				</div>
				<div class="kabao">
					<div class="condeta">
						<div class="condeta-1">¥</div>
						<div class="condeta-2">9元</div>
						<div class="condeta-3">*1张</div>

					</div>
					<span>满50减9优惠券*1张</span>
				</div>
				<div class="kabao">
					<div class="condeta">
						<div class="condeta-1">¥</div>
						<div class="condeta-2">15元</div>
						<div class="condeta-3">*1张</div>

					</div>
					<span>满70减15优惠券*1张</span>
				</div>
				<div class="kabao">
					<div class="condeta">
						<div class="condeta-1">¥</div>
						<div class="condeta-2">19元</div>
						<div class="condeta-3">*1张</div>

					</div>
					<span>满95减19优惠券*1张</span>
				</div>
				<div class="kabao">
					<div class="condeta">
						<div class="condeta-1">¥</div>
						<div class="condeta-2">21元</div>
						<div class="condeta-3">*1张</div>

					</div>
					<span>满150减21优惠券*1张</span>
				</div>
			</div>
		</div>


		<div class="title">
			<img src="http://img.123zhs.com/h5/film/4.png">
			<span>
				<pre>
1.优惠券仅限在微信公众号 [高分电影票]中购买电影票时进行使用。全国所有地区影院和影片均可使用(港澳台地区暂不支持）。
2.优惠券仅限在电影票原价基础上进行使用，不可与其他优惠叠加使用。
3.仅限将优惠券充值到兑换账户，暂不支持给其他账号进行充值。
4.活动期间，每位用户可以多次兑换，每笔订单仅限使用1张，购票后预计出票时间5~30分钟，请提前购票耐心等待。</pre>
			</span>
		</div>
		<div class="title">
			<img src="http://img.123zhs.com/h5/film/5.png">
			<span>

				<pre>使用方法：
微信公众号 [高分电影票】- 电影票 - 立即购票，选择下单</pre>
			</span>

			<span>

				<pre>查看优惠券：
微信公众号 [高分电影票】- 电影票 - 立即购票- 优惠券，查看</pre>
			</span>
			<img class="shili" src="http://img.zhsyh.cn/film/tips2.png">
		</div>

		<div class="title">
			<img src="http://img.123zhs.com/h5/film/7.png">
			<div class="youxiaoqi1">自兑换之日起，<span style="font-weight: bold;color: #fff;">有效期1个月</span></div>
			<div class="youxiaoqi2">（请在有效期内使用，逾期作废不予补还）</div>
		</div>
		<div class="title">
			<img src="http://img.123zhs.com/h5/film/8.png">
			<span>
				<pre>
1.虚拟产品一旦兑换不支持退款，请仔细阅读以上规则后，进行兑换。
2.对使用或涉嫌使用不正当方式套取优惠券的用户将取消用户参与本次活动的资格，并有权撒销违规或作弊交易，收回全部权益，必要时追究法律责任。</pre>
			</span>
		</div>


	</div>
</template>

<script>
import {
	mapActions,
	mapState,
	store
} from 'vuex'
import hhstore from '../utils/HHStore'
import help from '../utils/Help'
import validate from '../utils/Validate'
import urlParams from '../utils/UrlParams'
import {
	Toast,
	Dialog
} from 'vant';
export default {
	data() {
		return {
			phone: '',
			ch: ''
		}
	},
	async mounted() {
		this.ch = urlParams.get('ch')

	},

	onShow() {


	},
	methods: {
		...mapActions(['post', 'syncpost']),
		btnclick() {

			if (!this.phone) {
				Toast.fail('请填写正确的手机号')
				return
			}

			if (!validate.phone(this.phone)) {
				Toast.fail("手机号格式不正确");
				return;
			}
			Toast.loading({
				message: '请求中...',
				forbidClick: true,
			});
			this.post({
				url: 'film/pu_login_coupon_gaofen',
				data: { phone: this.phone, ch: this.ch },
				success: (data) => {
					Toast.clear()
					Dialog.confirm({
						title: '提示',
						message: '领取成功，优惠券可在公众号:【高分电影票】，使用' + this.phone + '手机号登录，下单即可抵扣。',
						cancelButtonText: '确定',
						confirmButtonText: '前往购票'

					})
						.then(() => {
							Dialog.close()
							window.location.href = "https://film.123hzd.com/?ch=gaofen"
						})
						.catch(() => {
							// on cancel
						});
					this.phone = ''
				},
				error: function (code, err) {
					Toast.clear()
					Toast.fail(err);
				}
			});
		}
	}
}
</script>

<style scoped>
.content {
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	background-color: #162a4d;

}

.topimg {
	/* z-index: 0; */
	position: absolute;
	width: 100vw;
	height: 100vw;
	left: 0px;
	top: 0px;
	background-image: url('https://img.zhsyh.cn/film/balabala.png?x-oss-process=image/resize,w_1000/quality,q_80');
	background-repeat: no-repeat;
	background-size: 100%;
}

.title {

	display: flex;
	flex-direction: column;
	align-items: center;
	color: #e0e0e0;
	font-size: .875rem;
	border-color: #283c61;
	border-width: 2px;
	border-style: solid;
	margin: 1rem .5rem 0px .5rem;
	border-radius: 1rem;
	padding: 1.25rem;
	letter-spacing: 2px;
	width: 83vw;
}

.title img {
	width: 11.25rem;
}

.title span {
	margin-top: 8px;
}

.inputdiv {
	z-index: 1;
	margin-top: 90vw;
}

.inputdiv input {
	border-radius: .75rem;
	width: 21.8125rem;
	height: 3.125rem;
	border-width: 0px;
	text-align: center;
	margin-bottom: 1rem;
}

.kabao {
	margin-top: 1.25rem;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.kabao img {
	width: 9.25rem;
}

.kabao span {
	color: rgba(255, 255, 255, 0.7);
	margin-top: .625rem;
	font-size: .75rem;
}

pre {
	white-space: pre-wrap;
	text-align: left;
	line-height: 1.5;
}

.shili {
	width: 82vw !important;

}

.youxiaoqi1 {
	margin-top: 8px;

}


.bottombtn img {
	width: 10rem;
	height: 2.5rem;
	margin: 20px 0px 10px 0px;
}

.kacontent {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	gap: 10px;
	justify-content: space-around;

}

.condeta {
	background-image: url('http://img.zhsyh.cn/film/conpg2.png');
	background-size: cover;
	width: 9.25rem;
	height: 4rem;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
}

.condeta-1 {
	font-size: 15px;
	font-weight: bold;
	color: #FD0305;
}

.condeta-2 {
	font-size: 25px;
	font-weight: bold;
	color: #FD0305;
}

.condeta-3 {
	position: absolute;
	font-size: 15px;
	color: #7B0200;
	top: 6px;
	right: 12px;
}
</style>
