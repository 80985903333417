<script>
import hhstore from './HHStore'
import {
	postf
} from '../store/index.js'
import qs from 'qs'
var md5 = require('md5')
import store from '../store'

export default {
	sign(data) {
		const signData = Object.assign({}, data, {
			appid: '100024',
			timestamp: parseInt(new Date().getTime() / 1000),
			oncestr: this.genHash(20)
		})
		const filterSignData = {}
		Object.keys(signData).forEach((key) => {
			const item = signData[key]
			if (!['String', 'Number', 'Boolean'].includes(this.typeOf(item))) {
				return
			}
			filterSignData[key] = item
		})
		const sort = (a, b) => a.localeCompare(b)
		const beforeStr = `${qs.stringify(filterSignData, { sort })}&key=EkbusymJRGw9e4ZV8sggQePpzYsaRH`

		const sign = md5(beforeStr)
		return Object.assign(signData, { sign })

	},
	genHash(len = 16) {
		const chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678'
		const charsLen = chars.length
		let hash = ''
		for (let i = 0; i < len; i++) {
			hash += chars.charAt(Math.floor(Math.random() * charsLen))
		}
		return hash
	},
	typeOf(data) {
		const toString = Object.prototype.toString
		const match = toString.call(data).match(/^\[object (\S*)\]$/)
		return match[1]
	},
	async click_log(name, src) {
		await postf({
			url: "user/click_log",
			data: {
				phone: hhstore.get("phone"),
				url: src,
				title: name,
			}
		})
	},

	async getdot() {
		var uid = hhstore.get("uid");
		var token = hhstore.get("token");
		let data = await postf({
			url: 'user/dot',
			data: {
				"uid": uid,
				"token": token
			},
		});

		store.commit('SET_DOT', data)
	},

	formatRMB(amount) {
		if (!amount) return ''
		// 将金额转换为字符串，并移除小数点后多余的零
		const formattedAmount = Number(amount).toFixed(2).replace(/\.?0+$/, '')

		// 添加千位分隔符
		const parts = formattedAmount.split('.')
		parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')

		// 添加人民币符号和单位
		const formattedRMB = '￥' + parts.join('.')

		return formattedRMB
	},


}
</script>
