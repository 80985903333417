<template>
	<div class="list">
		<div style="height: 6.7rem;"></div>
		<!-- <div class="topimg"><img src="http://img.123zhs.com/assets/cz/20022023437932.png"></div> -->
		<div class="aui-palace" v-for="(item, index) in list">
			<h3><i class="icon icon-h"></i>{{ item.title }}<i class="icon icon-h"></i></h3>
			<div class="aui_box">
				<div @click="clicked(item2)" class="aui-palace-grid" v-for="(item2, index2) in item.children">
					<div class="aui-palace-grid-icon">
						<img v-bind:src="item2.icon">
					</div>
					<div class="aui-palace-grid-text">
						<span class="h">{{ item2.nickname }}</span>
						<span class="s">特权优惠</span>
					</div>
				</div>
			</div>
		</div>

		<div class="aui-palace ft">
			<ul class="ft_sm">
				<li class="li_hd">温馨提醒</li>
				<li>充值前请核对好您的充值号码，一经充值概不退换。</li>
				<li>如对本次充值相关内容有疑问，请联系客服</li>
			</ul>
		</div>

		<LoginPopup :showLogin.sync="showLogin"></LoginPopup>


	</div>
</template>
<script>
import LoginPopup from '../components/LoginPopup.vue'

import {
	mapActions,
	mapState
} from 'vuex'
import hhstore from '../utils/HHStore'
import urlParams from '../utils/UrlParams'
import wxutils from '../utils/Wx'
import {
	Toast
} from 'vant';
var md5 = require('md5')
import {
	Form
} from 'vant';
import { Popup } from 'vant';

import {
	Field
} from 'vant';
import {
	Dialog
} from 'vant';
import Vue from 'vue';
import aes from '../utils/Aes'

Vue.use(Form);
Vue.use(Field);
Vue.use(Popup);

export default {
	name: 'list',
	components: {LoginPopup,
		[Dialog.Component.name]: Dialog.Component,
	},
	data: function () {
		return {
			showLogin: false,
			
			list: []
		}
	},
	props: {

	},
	beforeMount() {

	},
	mounted() {
		hhstore.set('card_type', 'lmqvhe')
		this.post({
			url: "goods/list",
			data: {},
			success: data => {
				this.list = data
			},
			error: function (error) {
				console.info(error)
			}
		});

	},
	computed: {
		...mapState([])
	},
	methods: {
		...mapActions(['post']),
		
		clicked(item) {

			if (!hhstore.get("token")) {
				this.showLogin = true
				return
			}

			window.location.href = item.src
			console.info(item)
		}
	}
}
</script>
<style scoped>
.list {
	background-image: url(http://img.123zhs.com/assets/cz/junyi.png);
	background-position: center top;
	background-repeat: no-repeat;
	background-size: 100% auto;
	background-color: #ffffff;
	height: 100vh;
}

.topimg img {
	width: 100%;
}

.icon-h {
	background-image: url(http://img.123zhs.com/assets/cz/cc.png);
}

.icon {
	width: 0.6rem;
	height: 0.6rem;
	display: inline-flex;
	border: none;
	background-size: 0.6rem;
	background-position: center center;
	background-repeat: no-repeat;
	padding: 0 8px;

}

.aui-palace {
	text-align: center;
}

h3 {
	display: block;
	font-size: 1.1em;
	margin-block-start: 1em;
	margin-block-end: 1em;
	margin-inline-start: 0px;
	margin-inline-end: 0px;
	font-weight: bold;
	font-family: Microsoft YaHei, Helvetica Neue, Helvetica, Arial, sans-serif;
	color: #111;
}

.aui_box {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-top: 1rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	align-items: center;
	flex-wrap: wrap;
}

.aui-palace-grid {
	position: relative;
	margin-bottom: 0.625rem;
	width: 5rem;
	box-sizing: border-box;
}

.aui-palace-grid-icon {
	width: 3rem;
	height: auto;
	margin: 0 auto;
}

.aui-palace-grid-text {
	display: block;
	text-align: center;
	color: #111;
	overflow: hidden;
	padding-top: 0.17rem;
}

.aui-palace-grid-text .h {
	font-size: 0.8rem;
	line-height: 1.2rem;
}

.aui-palace-grid-text .s {
	font-size: 0.68rem;
	color: #f38e46;
	line-height: 1.2rem;
}

.aui-palace-grid-text span {
	display: block;
}

.aui_box img {
	width: 100%;
	height: auto;
	display: block;
	border: none;
	vertical-align: middle;
	border: 0;
	-ms-interpolation-mode: bicubic;
}

.aui-palace {
	padding: 0.2rem 0;
	padding-bottom: 0.3rem;
	position: relative;
	overflow: hidden;
	border-radius: 15px;
	text-align: center;
	background-color: #ffffff;
	box-shadow: 0px 2px 3px #efefef;
	margin-bottom: 0.3rem;
}

.aui-palace.ft {
	padding-top: 0.2rem;
	padding-bottom: 0.2rem;
}

.ft_sm {
	padding-left: 0.2rem;
	display: block;
	overflow: hidden;
}

ul {
	display: block;
	list-style-type: disc;
	margin-block-start: 1em;
	margin-block-end: 1em;
	margin-inline-start: 0px;
	margin-inline-end: 0px;
	padding-inline-start: 40px;
}

.ft_sm li {
	display: block;
	overflow: hidden;
	margin-top: 0.18rem;
	font-size: .70rem;
}

</style>
