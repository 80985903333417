<template>
	<div class="container" :style="{ 'background-image': containerBg }">
		<div class="content"
			v-bind:style="{ height: contentHeight, 'margin-top': statusBarHeight, 'padding-top': contentPaddingTop }">
			<div class="level1">
				<van-swipe class="swiper" indicator-dots="true" :width="swipeItemWidth" duration="200">
					<van-swipe-item class="swiper-item" :key="ii" v-for="(ll, ii) in listArray">
						<div class="level1-item" :key="index" v-for="(item, index) in ll"
							@click="cellclicked(item, index)">
							<tips :value="item.tips"></tips>
							<wx-open-launch-weapp v-if="wxready && item.type == 'MiniProgram'" class='wxbtn'
								:username="item.id" :path="item.path" ref="wxLaunchBtn" id="launch-btn">
								<script type="text/wxtag-template">
									<div style="width: 500px;height:500px;background-color:">
																</div>
															</script>
							</wx-open-launch-weapp>

							<img :src="item.img" mode="aspectFill"></img>
							<div class="level1-item-title">{{ item.name }}</div>
							<div class="level1-item-subtitle">{{ item.subtitle }}</div>

						</div>
					</van-swipe-item>
				</van-swipe>
			</div>
			<div class="content-scroll">
				<!-- <div class="menu" v-if="list2.length">
					<div class="menu-left">
						<div class="current-item">代金券</div>
					</div>
				</div> -->
				<!-- <div class="level2" v-if="list2.length">
					<div class="donglian-content">
						<div class="donglian-hf" @click="jifenclick">
							<wx-open-launch-weapp v-if="wxready" class='wxbtn' username="gh_16cdb15c2398"
								path="pages/index/index?ch=qyw" ref="wxLaunchBtn" id="launch-btn">
								<script type="text/wxtag-template">
									<div style="width: 500px;height:500px;background-color:">
										</div>
									</script>
							</wx-open-launch-weapp>
							<img src="http://img.123zhs.com/donglian/hf.jpeg" />
						</div>
					</div>
				</div> -->
				<div class="menu" v-if="list2.length">
					<div class="menu-left">
						<div class="current-item">每日必点</div>
					</div>
				</div>
				<div class="level2">
					<div class="level2-right" :key="index" v-for="(item, index) in list2"
						@click="cellclicked(item, index)">
						<wx-open-launch-weapp v-if="wxready && item.type == 'MiniProgram'" class='wxbtn'
							:username="item.id" :path="item.path" ref="wxLaunchBtn" id="launch-btn">
							<script type="text/wxtag-template">
								<div style="width: 500px;height:500px;background-color:">
															</div>
														</script>
						</wx-open-launch-weapp>
						<img :src="item.img" mode="aspectFill"></img>
					</div>
				</div>

				<div class="kfc" v-if="kfc.menuVoList.length && kfcitem">
					<div class="kfc-top">
						<div class="kfc-top-title">汉堡炸鸡</div>
						<div class="kfc-top-subtitle">肯德基低至6折</div>
					</div>
					<div class="kfc-content">
						<div class="kfc-content-item" v-for="(item, index) in kfc.menuVoList" :key="index"
							@click="kfcclicked()">
							<div class="kfc-content-item-image">
								<img :src="item.imageUrl" mode="aspectFill"></img>
							</div>
							<div class="kfc-content-item-name">{{ item.nameCn ? item.nameCn.substr(0, 6) : '' }}</div>
							<div class="kfc-content-item-price">
								立减{{ Math.round((item.show_price_original - item.show_price_discount) * 10) / 10 }}元
							</div>
						</div>
					</div>
				</div>
				<div class="menu2" v-if="filmList.length">
					<div class="menu-left">
						<div class="current-item">热门电影</div>
					</div>

					<div class="menu-right">
						<div @click="dyclicked()">查看更多 ></div>
					</div>
				</div>
				<div class="movies">
					<div class="movies-item" v-for="(item, index) in filmList" :key="index" @click="dyclicked()">
						<div class="movies-item-1">
							<img :src="item.pic" mode="aspectFill"></img>
						</div>
						<div class="movies-item-2">
							<div class="movies-item-2-1">{{ item.name ? item.name.substr(0, 12) : '' }}</div>
							<div class="movies-item-2-2">
								<div>评分</div>
								<div class="movies-item-2-2-score">
									{{ (item.grade && item.grade != '0.0') ? item.grade : "暂无评分" }}
								</div>
							</div>
							<div class="movies-item-2-3">主演：{{ item.cast ? item.cast.substr(0, 16) : '' }}</div>
							<div class="movies-item-2-4">{{ item.publishTime.split(' ')[0] }} 上映</div>
						</div>
						<div class="movies-item-3">
							<!-- <div>购票</div> -->
						</div>
					</div>
				</div>
			</div>
		</div>
		<van-popup v-model="showhydlg">
			<div class="hydlgcontent">
				<div class='hydlgclose' @click="hydlgclose">
					<img src="http://img.123zhs.com/h5/hy/close-circle-fill.png" />
				</div>
				<div @click="hydlgclick">
					<img class="hydlg" :src="hydlgbg" />
				</div>
			</div>
		</van-popup>

		<van-popup v-model="show38dlg">
			<div class="hydlgcontent">
				<div class='hydlgclose' @click="dlg38close">
					<img src="http://img.123zhs.com/h5/hy/close-circle-fill.png" />
				</div>
				<div @click="dlg38click">
					<img class="hydlg" src="http://img.123zhs.com/h5/38/38.jpg" />
				</div>
			</div>
		</van-popup>

		<van-popup v-model="showdjqdlg" @close="dlgdjqclose">
			<div class="hydlgcontent">
				<div class='hydlgclose' @click="dlgdjqclose()">
					<img src="http://img.123zhs.com/donglian/close3.png?x-oss-process=image/resize,w_50/quality,q_80" />
				</div>
				<div>
					<wx-open-launch-weapp @launch="dlgdjqclick" username="gh_16cdb15c2398"
						path="pages/index/index?ch=qyw" ref="wxLaunchBtn" id="launch-btn">
						<script type="text/wxtag-template">
							<img style="height: 16.67rem;" src="http://img.123zhs.com/donglian/tanchuang.png?x-oss-process=image/resize,w_500/quality,q_80" />
													</script>
					</wx-open-launch-weapp>
				</div>
			</div>
		</van-popup>

	</div>
</template>
<script>
import Tips from "../components/Tips.vue"
import {
	mapActions,
	mapState,
	store
} from 'vuex'
import hhstore from '../utils/HHStore'
import help from '../utils/Help'
import urlParams from '../utils/UrlParams'
import wxutils from '../utils/Wx'
import {
	Toast,
	Swipe,
	SwipeItem,
	NoticeBar,
	Dialog,
	Form,
	Sticky,
	Button,
	Field
} from 'vant';
import Vue from 'vue';

import aes from '../utils/Aes'
var md5 = require('md5')

Vue.use(Form);
Vue.use(Field);
Vue.use(NoticeBar);

export default {
	name: 'list',
	components: {
		[Dialog.Component.name]: Dialog.Component,
		[Swipe.name]: Swipe,
		[Sticky.name]: Sticky,
		[NoticeBar.name]: NoticeBar,
		[Button.name]: Button,
		[SwipeItem.name]: SwipeItem,
		'tips': Tips
	},
	data: function () {
		return {

			list: [],
			list2: [],
			kfc: {
				menuVoList: [],
				store: {
					address: ""
				}
			},
			wxready: false,
			kfcitem: null,
			dyitem: null,
			filmList: [],
			contentHeight: "calc(100vh - 160px)",
			statusBarHeight: "49",
			contentPaddingTop: "160px",
			cardType: null,
			showhydlg: false,
			hydlgbg: "http://img.123zhs.com/h5/hy/hytc5.png",
			containerBg: "url('http://img.123zhs.com/mp-qyw/img/main/top.png?x-oss-process=image/resize,w_750/quality,q_80')",
			show38dlg: false,
			showdjqdlg: false,

		}
	},
	props: {

	},
	beforeMount() {

	},
	async mounted() {
		// this.launchmdl({name:'mdl'},13588232814);
		// return;
		this.$store.commit('SET_HOMEPAGE', "/list2")

		// 获取卡类型，存本地，每个网络请求都带上这个参数
		urlParams.get('card_type') && hhstore.set('card_type', urlParams.get('card_type'))
		this.cardType = hhstore.get('card_type')

		hhstore.set('homepage', "/list2")
		hhstore.set('login_back', "/list2")
		if (this.cardType != 'qyw')
			this.containerBg =
				'url("https://img.123zhs.com/mp-qyw/ty_top.png?x-oss-process=image/resize,w_752/quality,q_80")'

		if (!hhstore.get("token")) {
			//尝试微信code登录
			var code = urlParams.get('code');
			if (code) {
				this.loginWithCode(code);
			} else {
				// 获取微信id
				if (this.cardType != 'qyw') {
					wxutils.auth("wx0ac531187e5fbb37");
				} else {
					if (process.env.NODE_ENV === "development") {
						wxutils.auth("wxfbd9f6d6aa3236be");
					} else {
						wxutils.auth("wx1a5b1b2a6bd07ea0");
					}
				}
				return
			}
		}

		if (this.cardType != 'qyw')
			this.hydlgbg = "http://img.123zhs.com/h5/hy/hytc6.jpeg"

		this.wxconfig()
		await this.homeindex()

		this.userinfo()
		this.getKfc()
		this.remenFilm()

		// this.show38dlgfn()
		// this.showdjqdlgfn()
	},
	computed: {
		listArray() {
			if (this.list) {
				const arr = this.chunk(this.list, 15)
				return arr
			} else {
				return []
			}
		},
		swipeItemWidth() {
			return document.body.clientWidth
		},
	},
	methods: {
		...mapActions(['post', 'syncpost']),

		loginWithCode(code) {
			this.post({
				url: "user/login_with_code",
				data: {
					"code": code,
				},
				success: (data) => {
					console.info(data)
					hhstore.set("uid", data.uid)
					hhstore.set("token", data.token)
					hhstore.set("phone", data.phone)
				},
				error: (code, msg) => {
					// 微信登录失败，返回注册页面
					hhstore.set('login_back', "/list2")
					this.$router.push('/register')
				}
			});

		},
		kfcclicked() {
			this.cellclicked(this.kfcitem, 0)
		},
		dyclicked() {
			this.cellclicked(this.dyitem, 0)
		},
		jifenclick() {
			window.location.href = "http://jifen.123hzd.com?ch=qyw&phone=" + hhstore.get('phone')
		},
		async wxconfig(data) {
			try {
				var data = await this.syncpost({
					url: "wx/getSignPackage",
					data: {
						"url": window.location.href,
					}
				});
			} catch (e) {
				console.info(e)
				return;
			}

			wx.config({
				debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印
				appId: data.appId, // 必填，公众号的唯一标识
				timestamp: data.timestamp.toString(), // 必填，生成签名的时间戳
				nonceStr: data.nonceStr, // 必填，生成签名的随机串
				signature: data.signature, // 必填，签名
				jsApiList: ['getLocation'], // 必填，需要使用的JS接口列表
				openTagList: ['wx-open-launch-weapp'] // 可选，需要使用的开放标签列表，例如['wx-open-launch-app']
			});

			wx.ready(() => {
				if (hhstore.get('token'))
					this.wxready = true
				// config信息验证后会执行ready方法，所有接口调用都必须在config接口获得结果之后，config是一个客户端的异步操作，所以如果需要在页面加载时就调用相关接口，则须把相关接口放在ready函数中调用来确保正确执行。对于用户触发时才调用的接口，则可以直接调用，不需要放在ready函数中
				console.info("ready")
			});

			wx.error((res) => {
				// config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名
				console.info(res)
			});

		},
		chunk(arr, size) {
			var objArr = new Array();
			var index = 0;
			var objArrLen = arr.length / size;
			for (var i = 0; i < objArrLen; i++) {
				var arrTemp = new Array();
				for (var j = 0; j < size; j++) {
					arrTemp[j] = arr[index++];
					if (index == arr.length) {
						break;
					}
				}
				objArr[i] = arrTemp;
			}
			return objArr;
		},

		async getKfc() {
			try {
				var location = await this.getLocation()
			} catch (e) {
				location = {
					latitude: "39.929986",
					longitude: "116.412893",
				}
			}

			console.info(location)
			this.post({
				url: "kfc/cb_nearby_products",
				data: {
					appid: 100024,
					latitude: location.latitude,
					longitude: location.longitude,
				},
				success: (data) => {
					this.kfc = data
				}
			});
		},
		remenFilm() {
			const homeFilmList = hhstore.get('homeFilmList');
			if (homeFilmList) {
				this.filmList = JSON.parse(homeFilmList)
			}

			this.post({
				url: "film/remen",
				data: {},
				success: (data) => {
					hhstore.set('homeFilmList', JSON.stringify(data));
					this.filmList = data
				}
			});

		},
		getLocation() {
			return new Promise((resolve, reject) => {
				const UA = navigator.userAgent.toLowerCase()
				const inWechat = UA.match(/.*?(micromessenger\/([0-9.]+))\s*/)
				if (inWechat) {
					wx.getLocation({
						// type: 'gcj02', // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'
						success: ({
							latitude,
							longitude
						}) => {
							resolve({
								latitude,
								longitude
							})
						},
						fail: () => {
							reject(new Error('location error'))
						}
					})

				} else if (navigator) {
					navigator.geolocation.getCurrentPosition(
						(res) => {
							resolve(res.coords)
						},
						(error) => {
							// Toast("定位失败：" + error.message)
							reject(error)
						}
					)
				} else {
					// Toast('该浏览器不支持定位')
					reject(new Error('location unsupported'))
				}
			})
		},
		userinfo() {

			var uid = hhstore.get("uid");
			var token = hhstore.get("token");

			if (!uid) {
				return;
			}

			this.post({
				url: "user/info",
				data: {
					"uid": uid,
					"token": token
				},
				success: (data) => {
					hhstore.set("phone", data.phone)
					// 微信未授权
					if (!data.wxsq) {
						if (this.cardType != 'qyw')
							wxutils.auth("wx0ac531187e5fbb37");
						else {
							if (process.env.NODE_ENV === "development") {
								wxutils.auth("wxfbd9f6d6aa3236be");
							} else {
								wxutils.auth("wx1a5b1b2a6bd07ea0");
							}
						}
					}

					this.showhydlg = data.showhydlg
				},
				error: (code, err) => {
					Toast.fail(err);
					if (code == 92834 || code == 10002 || code == 10003) {
						hhstore.del("uid")
						hhstore.del("phone")
						hhstore.del("token")
						hhstore.set('login_back', "/list2")
						this.$router.push('/register')
					}
				}
			});
		},

		async homeindex() {
			// 优先使用缓存
			try {
				this.list = JSON.parse(hhstore.get('product_list'))
			} catch (e) { }

			if (this.cardType != 'qyw')
				var url = "home2/index"
			else
				var url = "home2/qyw_gzh_index"

					;
			try {
				let data = await this.syncpost({
					url: url,
					data: {
						phone: hhstore.get("phone")
					}
				});

				this.list = data.list
				this.list2 = data.list2

				for (let item of data.list) {
					if (item.name == '肯德基') {
						this.kfcitem = item
					}
					if (item.name == '电影票') {
						this.dyitem = item
					}
				}
				hhstore.set('product_list', JSON.stringify(data.list))

			} catch (e) {
				console.info(e)
			}
		},

		cellclicked(item, index) {
			let uid = hhstore.get("uid");
			let token = hhstore.get("token");
			let phone = hhstore.get("phone");

			if (!token) {
				Dialog.confirm({
					title: '提示',
					message: '您还没有激活权益，立即去激活权益？',
				})
					.then(() => {
						hhstore.set('login_back', "/list2")
						this.$router.push('/register')
					})
				return;
			}

			if (item.type == "H5") {
				this.launchH5(item)
			} else if (item.type == "MiniProgram") {
				this.launchMiniProgram(item);
			} else if (item.type == "mthuodong") {
				this.launchmeituanhuodong(item);
			} else if (item.type == "kfczj") {
				this.launchkfczj(item);
			} else if (item.type == "xjjy") {
				this.launchxjjy(item);
			} else if (item.type == "kfc") {
				this.launchkfc(item, phone);
			} else if (item.type == "mdl") {
				this.launchmdl(item, phone);
			} else if (item.type == "list") {
				this.launchlist(item);
			}
		},

		launchH5(item) {
			let phone = hhstore.get('phone')
			let src = item.src.replace('{{phone}}', `phone=${phone}`)
			help.click_log(item.name, src);
			window.location.href = src;
		},
		launchmeituanhuodong() {
			this.$router.push('/meituan')
		},
		launchkfc(item, phone) {
			let kfcurl = item.src + "&phone=" + phone;
			console.info(kfcurl)
			help.click_log(item.name, kfcurl);
			window.location.href = kfcurl;
		},
		launchkfczj(item) {
			if (this.cardType != "qyw")
				var url =
					"http://kfc.quhaodian.cn/diancan/new.html?v=1.1&platmnt=yiluxue8&notify_url=http://api.zhsyh.cn/callback/kfc_ylx"
			else
				var url =
					"http://kfc.quhaodian.cn/diancan/new.html?v=1.1&platmnt=gh_90fb3ad61e18&notify_url=http://api.zhsyh.cn/callback/kfc"

			let phone = hhstore.get("phone");
			const kfcurl = url + "&buyer_phone=" + phone;

			help.click_log(item.name, kfcurl);
			window.location.href = kfcurl;
		},
		launchMiniProgram(item) {
			switch (item.name) {
				case "美团": {
					let url =
						"https://click.meituan.com/t?t=1&c=1&p=OWMpZ-uzIFOVe6JyOONs3VzolOCHG2b7aH-XB09jFlbbAeBCxOxg6qjsKAN88woEFromm3-t0TMkVus13UZv-mV9QzYRWoiWvHOWzHGFDhZEsG-f4OhpwMBskUkYe1H7wyD-OXwd3BWJxNIJ6992rrQNg3VlzPdn814kLFo9pIpQqHpPaadsevrr9dRj3WmkSTghpXIQ4T95FHmUy7OtCwbFB0vd45gLPKEn4wNs28XESggiomd_wMXaODBdU79Xbc8o7Jrx4eTTUg5T3q5tY0PwJylVx3q8lEYxiiyscEmkfMkHMbG4-6x4LuZYZ7eexb7GMNM8jl6FD0VhXNpBsndlp6AKLcHFuRW-A5FCU2m_O2uhBCB705YEQwqb0dBgtGt1qQfSK9zxiuohaUZORlRsOH0FFS-iUIRIOUl7QqIqk-RvCBEm9Ld-dYX_P85O5gE1xpugoeTX36yN0CEasLuq-laV4sYSmDuRTVkwX_co_kruuvMGkZXDnVSQyU6lSk_zEDYAXtyHihRYCmR_rw";
					help.click_log(item.name, url);
					window.location.href = url;
					break;
				}
				case "饿了么": {
					let url =
						"https://s.click.ele.me/t?union_lens=lensId%3AOPT%401626743733%4021055abf_0813_17ac17b7c5a_03fe%4001%3BeventPageId%3A20150318020002597&&e=-s023070Mn5BsGg2qhmaDDOc73FezbhVejwDEavIpPtqPuh1dUijt5Z1ODHB1BWfddHtKZul5MBKXPtOhK9RMNNHmqWGhtrizPDvEFbO1h61ay2BovXazIg03k68IP55iN1GjYhX5Zo8xC6tIYGCYeekL7OaxQ5db1kWEmbw0oNtxmxwSyRiUywhXrffCxrqPOvYZ1i7bVpj2Tmo29ftiHXGVHLiemYChm2Vk31UUyBNHmkxtkx3HcSCGyXmXEOHkI4zbNIm2zotbhNVoG0SQrKlXu8rWfxrR5SXf0HVjxLFx74wa76Ce0NAv0oBZxwSdlQOm0I9HVUnkqQIB7ZZ8iLZgHrX8i6lyweN3QpqgGlzEkn1lMcqpmCJvmsuQ5DCoh3b0TSarwiBrlBQxSTcKoTZ9I7hMpiUzvCt7kUO7iKVTLPqZdnlh9UhSm2cIhOpjHtYVsTWU1CwenTR8YKGVcfiTpjXk&";
					help.click_log(item.name, url);
					window.location.href = url;
					break;
				}
				case "滴滴打车": {
					this.launchdidi(item)
					break;
				}
				case "加油": {
					this.launchdidijiayou(item)
					break;
				}
				case "花小猪": {
					this.launchxiaozhu(item)
					break;
				}
				default:
					break;
			}

		},
		async launchdidi(item) {
			Toast.loading({
				message: '加载中...',
				forbidClick: true,
				duration: 0
			});

			try {
				// h5的
				let data = await this.syncpost({
					url: "xjjy/generate_link_url",
					data: {
						"pid": item.pid,
						"type": "h5",
					},
				});

				Toast.clear()
				help.click_log(item.name, data.link);
				window.location.href = data.link;
			} catch (e) {
				Toast.fail(e.message);
			}
		},
		//花小猪
		async launchxiaozhu(item) {
			Toast.loading({
				message: '加载中...',
				forbidClick: true,
				duration: 0
			});

			try {
				// h5的
				let data = await this.syncpost({
					url: "xjjy/generate_xiaozhu_url",
					data: {
						"pid": item.pid,
						"type": "h5",
					},
				});

				Toast.clear()
				help.click_log(item.name, data.link);
				window.location.href = data.link;
			} catch (e) {
				Toast.fail(e.message);
			}
		},
		async launchdidijiayou(item) {
			Toast.loading({
				message: '加载中...',
				forbidClick: true,
				duration: 0
			});

			try {
				// h5的
				let data = await this.syncpost({
					url: "xjjy/generate_jiayou_url",
					data: {
						"pid": item.pid,
						"type": "h5",
					},
				});

				Toast.clear()
				help.click_log(item.name, data.link);
				window.location.href = data.link;
			} catch (e) {
				Toast.fail(e.message);
			}
		},
		async launchxjjy(item) {
			let data = await this.syncpost({
				url: "xjjy/geturl",
				data: {
					"uid": hhstore.get("uid"),
					"token": hhstore.get("token")
				},
			});
			help.click_log(item.name, data);
			window.location.href = data;

		},
		launchlist(item) {
			hhstore.set('yinge', JSON.stringify(item))
			window.location.href = "/yinge"
		},
		launchdy() {

		},
		hwsign(data, secretKey) {
			data["timestamp"] = new Date().getTime();

			if (this.cardType) {
				data["extra"] = this.cardType;
			}

			let beforesign = this.jsonSort(data, true) + "&secretKey=" + secretKey
			console.info(beforesign);

			let sign = md5(beforesign).toUpperCase();
			console.info(sign);

			data['sign'] = sign;
			let urlparams = this.jsonSort(data, false)
			console.info(urlparams);

			return urlparams
		},

		jsonSort(jsonObj, toHump) {
			let arr = [];
			for (var key in jsonObj) {
				arr.push(key)
			}
			arr.sort();
			let str = '';
			for (var i in arr) {
				if (toHump) {
					str += this.toHump(arr[i]) + "=" + jsonObj[arr[i]] + "&"
				} else {
					str += arr[i] + "=" + jsonObj[arr[i]] + "&"
				}
			}
			return str.substr(0, str.length - 1)
		},
		// 海威的签名字符串链接
		joinmap(jsonObj) {
			let arr = [];
			for (var key in jsonObj) {
				arr.push(key)
			}
			arr.sort();
			let str = '';
			for (var i in arr) {
				str += jsonObj[arr[i]]
			}
			return str
		},
		// 下换线转驼峰
		toHump(name) {
			return name.replace(/\_(\w)/g, function (all, letter) {
				return letter.toUpperCase();
			});
		},
		launchmdl(item, phone) {

			let timestamp = new Date().getTime();
			if (this.cardType != "qyw") {
				var shareCode = "6492aNymP";
				var s = "408957BF28AD4A84B359D45D0DDCE8EE";
			} else {
				var shareCode = "647J8fs9s";
				var s = "4607280171F242359D12205473FD0FF5";
			}

			let urlparams = this.hwsign({
				"shareCode": shareCode,
				"userId": phone,
			}, s);
			let mdlurl = "https://ot.jfshou.cn/api/mcdonald/entrance?" + urlparams;
			// console.info(mdlurl)
			help.click_log(item.name, mdlurl);
			window.location.href = mdlurl;
		},
		
		hydlgclick() {
			this.$router.push('/actqy')
		},
		hydlgclose() {
			this.showhydlg = false
		},
		dlgdjqclick() {
			const today = new Date().toISOString().slice(0, 10);
			hhstore.set('djq_last_date1', today);
			this.showdjqdlg = false
		},
		dlgdjqclose() {
			console.info("dlgdjqclose")
			const today = new Date().toISOString().slice(0, 10);
			hhstore.set('djq_last_date1', today);
			this.showdjqdlg = false
		},
		showdjqdlgfn() {
			// 获取当前日期
			const today = new Date().toISOString().slice(0, 10);

			// 读取本地存储中的日期
			const lastDate = hhstore.get('djq_last_date1');

			// 如果本地存储中的日期与当前日期不同，则弹出弹窗并更新本地存储中的日期
			if (lastDate !== today && this.cardType == 'qyw') {
				this.showdjqdlg = true
			}
		},
		dlg38click() {
			const today = new Date().toISOString().slice(0, 10);
			hhstore.set('popup_last_date', today);
			this.$router.push('/expcards')
		},
		dlg38close() {
			const today = new Date().toISOString().slice(0, 10);
			hhstore.set('popup_last_date', today);
			this.show38dlg = false
		},
		show38dlgfn() {
			// 获取当前日期
			const today = new Date().toISOString().slice(0, 10);

			// 读取本地存储中的日期
			const lastDate = hhstore.get('popup_last_date');

			// 如果本地存储中的日期与当前日期不同，则弹出弹窗并更新本地存储中的日期
			if (lastDate !== today) {
				this.show38dlg = true
			}

		},


	}
}
</script>
<style scoped>
.container {
	overflow: hidden;
	background-color: #F8F8F8;
	width: 100vw;
	height: 100vh;
	-moz-background-size: 100%;
	background-size: 100%;
	/* background-image: url('http://img.123zhs.com/mp-qyw/img/main/top.png?x-oss-process=image/resize,w_750/quality,q_80'); */
	background-repeat: no-repeat;
}

.level1 {
	margin-left: 0.75rem;
	width: 22.6125rem;
	padding-left: 0.3525rem;
	padding-right: 0.3525rem;
	padding-top: 0.40625rem;
	padding-bottom: 0.625rem;
	background-color: #FFFFFF;
	border-radius: 0.625rem;
	overflow: hidden;
	border-color: #F3f3f3;
	border-style: solid;
	border-width: 1px;
}

.level1-item {
	position: relative;
	margin-top: 1.21875rem;
	margin-left: 0.1675rem;
	width: 4.2625rem;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.level1-item img {
	align-self: center;
	width: 2.6875rem;
	height: 2.6875rem;
	border-radius: 1.34375rem;

}

.level1-item-title {
	align-self: center;
	font-size: 0.875rem;
	margin-top: 0.65625rem;
	color: #333333;
}

.level1-item-subtitle {
	align-self: center;
	font-size: 0.75rem;
	color: #999999;
	/* font-weight: 350; */
}

.level2 {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	margin-top: 0.625rem;
}

.level2-right {
	position: relative;
	margin-right: 0.625rem;
}

.level2 img {
	width: 11.3rem;
	height: 5.3rem;
	border-radius: 0.3125rem;
}

.kfc {
	width: 23rem;
	border-radius: 0.46875rem;
	/* box-shadow: 0px 0px 6px #e1e1e1; */
	border-color: #F3f3f3;
	border-style: solid;
	border-width: 1px;
	overflow: hidden;
}

.kfc-top {
	width: 100%;
	height: 2.8125rem;
	background: -webkit-linear-gradient(left, #F7D7A8, #F6E5C8);
	background: -moz-linear-gradient(left, #F7D7A8, #F6E5C8);
	overflow: hidden;
	display: flex;
	align-items: center;
}

.kfc-top-title {
	color: #333333;
	font-size: 0.9375rem;
	color: #333333;
	font-weight: bold;
}

.kfc-top-subtitle {
	color: #555555;
	font-size: 0.75rem;
	margin-left: 0.46875rem;
}

.donglian-content {
	margin-right: 20px;
}

.donglian-hf {
	position: relative;
}

.donglian-hf img {
	width: 100%;
}

.kfc-content {
	display: flex;
	padding-left: 0.66875rem;
	background-color: white;
	padding-top: 0.5rem;
}

.kfc-content-item:first-child {
	margin-left: 0rem !important;
}

.kfc-content-item {
	position: relative;
	margin-left: 0.86875rem;
}

.kfc-content-item-image {
	position: relative;
	/* background-image: url("http://img.123zhs.com/mp-qyw/kfc_bg.png"); */
	width: 6.625rem;
	height: 6.625rem;
	display: flex;
	align-items: center;
	justify-content: center;
	overflow: hidden;
}

.kfc-content-item img {
	width: 6.625rem;
	height: 6.625rem;
	object-fit: cover;
}

.kfc-content-item-image-distance {
	position: absolute;
	bottom: 5.0625rem;
	font-size: 0.5625rem;
	background-color: #EA7B36;
	padding-right: 0.3125rem;
	padding-left: 0.15625rem;
	color: white;
	height: 0.9375rem;
	border-radius: 0px 0.46875rem 0.46875rem 0px;
	line-height: 0.9375rem;
}

.kfc-content-item-image-position {
	position: absolute;
	bottom: 3.75rem;
	font-size: 0.5625rem;
	background-color: #EA7B36;
	color: white;
	height: 0.9375rem;
	border-radius: 0px 0.46875rem 0.46875rem 0px;
	padding-right: 0.3125rem;
	padding-left: 0.15625rem;
	line-height: 0.9375rem;
}

.kfc-content-item-name {
	color: #333333;
	font-size: 0.875rem;
	margin-top: 0.40625rem;
	font-weight: bold;
}

.kfc-content-item-price {
	color: #EA3323;
	font-size: 0.75rem;
	margin-top: 0.3125rem;
	margin-bottom: 0.6875rem;
}

.menu {
	display: flex;
	justify-content: space-between;
	padding-right: 0.96875rem;
	align-items: baseline;
	margin-top: 0.46875rem;
}

.menu2 {
	display: flex;
	justify-content: space-between;
	padding-right: 0.96875rem;
	margin-top: 0.25rem;
	align-items: baseline;
	margin-top: 0.9375rem;
}

.menu-left {
	display: flex;
	flex-direction: row;
	align-items: center;
	font-size: 0.75rem;
	color: #555555;
}

.current-item {
	font-size: 0.9375rem;
	color: #333333;
	font-weight: bold;
}

.menu-right {
	font-size: 0.75rem;
	color: #999999;
}

.movies {
	display: flex;
	flex-direction: column;
	padding-top: 0.90625rem;
}

.movies-item {
	margin-bottom: 0.9375rem;
	display: flex;
	height: 5.9rem;
	width: 100%;
	align-items: center;
}

.movies-item-1 img {
	width: 4.16rem;
	height: 5.9rem;
	border-radius: 0.3125rem;
	margin-right: 0.4375rem;
}

.movies-item-2 {
	width: 13.09375rem;
	height: 5.9rem;
	display: flex;
	flex-direction: column;
}

.movies-item-2-1 {
	margin-top: 0.2rem;
	font-weight: bold;
	color: #333333;
	font-size: 0.875rem;
}

.movies-item-2-2 {
	display: flex;
	margin-top: 0.53125rem;
	font-weight: bold;
	color: #999999;
	font-size: 0.6875rem;
}

.movies-item-2-2-score {
	margin-left: 0.3125rem;
	color: #BB4B55;
}

.movies-item-2-3 {
	margin-top: 0.28125rem;
	font-weight: bold;
	color: #999999;
	font-size: 0.6875rem;
}

.movies-item-2-4 {
	margin-top: 0.28125rem;
	font-weight: bold;
	color: #999999;
	font-size: 0.6875rem;
}


.movies-item-3 view {
	text-align: center;
	line-height: 1.4375rem;
	color: white;
	width: 3.28125rem;
	height: 1.4375rem;
	background: -webkit-linear-gradient(left, #F1A256, #EB667F);
	background: -moz-linear-gradient(left, #F1A256, #EB667F);
	font-size: 0.6875rem;
	border-radius: 0.71875rem;
}

.content {
	overflow-y: scroll;
	width: 100vw;
}

.content::-webkit-scrollbar {
	display: none
}

.content-scroll {
	background-color: #F8F8F8;
	padding-left: 0.75rem;
	margin-top: 0.5625rem;
	overflow: hidden;
	padding-bottom: 3.0625rem;
}

.swiper-item {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	/* align-items: flex-start; */
	align-content: flex-start;
}

.swiper {
	height: 22.1875rem;
}

.more {
	position: absolute;
	right: 2rem;
	bottom: 2rem;
}

.overflow {
	position: absolute;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
	background-color: red;
}

.wxbtn {
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;
	z-index: 1;
	background-color: rgba(0, 0, 0, 0);
	overflow: hidden;
	position: absolute;
}

.hydlg {
	/* width: 20rem; */
	height: 16.67rem;
	display: block;
}

.hydlgcontent {
	position: relative;

}

.hydlgclose {
	position: absolute;
	top: 3px;
	right: 3px;
	/* background-color: red;
		width: 30px;
		height: 30px; */
}

.hydlgclose img {
	width: 25px;
	height: 25px;
}
</style>

<style>
.van-swipe__indicator {
	background-color: #666666;
}

.van-swipe__indicators {
	bottom: 0.325rem;
}
</style>
