<template>
	<div class="qrcode">
		<vue-qr :text="qrcode" :size="300"></vue-qr>
	</div>
</template>
<script>
	import urlParams from '../utils/UrlParams'
	import {
		Toast
	} from 'vant';
	import vueQr from 'vue-qr'
	export default {
		name: 'qrcode',
		data: function() {
			return {
				qrcode: "none"
			}
		},
		components: {
			vueQr
		},
		mounted() {
			this.qrcode = urlParams.get('text')
		},
	}
</script>

<style scoped>
	.qrcode {
		text-align: center;
		position: relative;
	}

	/*为什么没有效果*/
	.qrcode>img {
		width: 100%;
		margin: auto;
	}
</style>
