<template>
	<div class="bg">
		<div class="content">
			<div class="top">
				<img :src="info.img" />
				<span>{{ info.title }}</span>
			</div>
			<div class="haoma">充值号码</div>
			<div class="ipt"><input type="text" name="phone" :placeholder="info.tishi" v-model="account"></div>

			<div class="fenge"></div>

			<template v-for="(value, key, index) in kvs">
				<div class="haoma">{{ key }}</div>
				<div class="miane clearfix">
					<div class="mianeitem" v-for="(v, i) in kvs[key]" v-on:click="kvclick(key, v)"
						v-bind:class="{ mianeselect: v == selectedsku[key] }"
						v-if="!(Object.keys(kvs).length - 1 == index && price(key, v).length == 0)">
						<div class="mianeiteml1">{{ v }}</div>
						<template v-if="Object.keys(kvs).length - 1 == index">
							<div class="mianeiteml2">售价¥{{ price(key, v) }}</div>
							<div class="mianeiteml3">¥{{ originPrice(key, v) }}</div>
						</template>
					</div>
				</div>
			</template>

			<div class="xuzhi">
				<p>购买须知</p>
				<div v-if="info.xuzhi_html" v-html="info.xuzhi_html"></div>
				<pre v-else>{{ info.xuzhi }}</pre>
			</div>

			<div style="height: 20px;"></div>
		</div>

		<div class="btndiv">
			<button class="btndivleft" v-on:click="onorder()">我的订单</button>
			<button class="btndivright" v-on:click="onbuy()">确认充值</button>
		</div>

		<van-dialog v-model:show="showDialog" title="" show-cancel-button @confirm="confirmonbuy">
			<div>
				<div class="dialog-title">手机号确认</div>
				<div class="dialog-text3">{{ phone }}</div>
				<div class="dialog-text">温馨提示：</div>
				<div class="dialog-text">1、急用勿充！话费将在<span class="dialog-text2">96小时之内</span>到帐，部分区域可能出现延时，请您知晓！</div>
				<div class="dialog-text">2、请确认充值号码无误后再支付，<span class="dialog-text2">充值成功后不支持退款。</span></div>
				<div class="dialog-text">3、携号转网，和虚拟号码，暂时不支持下单！</div>
				<br />
			</div>
		</van-dialog>
	</div>
</template>
<script>
import {
	mapActions,
	mapState
} from 'vuex'
import validate from '../utils/Validate'
import hhstore from '../utils/HHStore'
import urlParams from '../utils/UrlParams'
import {
	Toast,
	Dialog
} from 'vant';
import { setTimeout } from 'timers';

/* 用来微信授权用 ，授权完成后，根据  localStorage.auth_back 地址，跳回原网址 */

export default {
	name: 'Pay',
	components: {
		[Dialog.Component.name]: Dialog.Component,
	},
	data: function () {
		return {
			phone: '',
			sku_id: '',
			goods_id: 5,
			showDialog: false,
			info: {},
			kvs: {},
			sku: null,
			selectedsku: {},
			img: "",
			account: "",
		}
	},
	props: {

	},
	beforeMount() {

	},
	mounted() {
		// 获取卡类型，存本地，每个网络请求都带上这个参数
		const cardType = urlParams.get('card_type')
		if (cardType) {
			hhstore.set('card_type', cardType)
		}

		if (!hhstore.get("token")) {
			console.info('pay vue not login');
			this.$router.push('/register')
			return
		}

		this.goods_id = urlParams.get('gid')
		this.account = urlParams.get('account') ? urlParams.get('account') : ''
		this.getDetail()
	},
	beforeDestroy() {

	},
	computed: {
		...mapState([])
	},
	methods: {
		...mapActions(['post']),
		price(k, v) {
			return this._price(k, v, "price")
		},
		originPrice(k, v) {
			return this._price(k, v, "original_price")
		},
		_price(k, v, item) {
			for (var i = this.sku.length - 1; i >= 0; i--) {
				const specs = JSON.parse(this.sku[i].specs)
				let check = true
				Object.keys(specs).forEach((key) => {
					if (k == key) {
						if (!(v == specs[key])) {
							check = false
						}
					} else {
						if (!(this.selectedsku && this.selectedsku[key] == specs[key])) {
							check = false
						}
					}
				})
				if (check) {
					return this.sku[i][item] / 100;
				}
			}
			return "";
		},
		getDetail() {
			var self = this
			this.post({
				url: "goods/detail",
				data: {
					goods_id: this.goods_id,
					"uid": hhstore.get("uid"),
					"token": hhstore.get("token"),
				},
				success: function (data) {
					// data.info.xuzhi = data.info.xuzhi.replace(/\r\n/g, "<br>")
					// data.info.xuzhi = data.info.xuzhi.replace(/\n/g, "<br>")
					self.kvs = data.kvs
					self.info = data.info
					self.sku = data.sku

					Object.keys(data.kvs).forEach((key, index) => {
						// if (index != Object.keys(data.kvs).length-1) {
						self.selectedsku[key] = data.kvs[key][0]
						// }
					})

				},
				error: function (code, err) {
					Toast.fail(err);
				}
			});
		},

		kvclick(key, value) {
			if (!this.selectedsku) {
				this.selectedsku = {};
			}
			this.selectedsku[key] = value
			this.$forceUpdate()
		},
		onorder() {
			this.$router.push('/order')
		},
		onbuy: function () {
			const phone = this.account.trim();
			if (phone.length == 0) {
				Toast.fail("请填写充值号码")
				return
			}

			// const r = /^\w+$/;
			// if (!phone.match(r)) {
			// 	Toast.fail("充值号码格式不正确")
			// 	return
			// }

			for (var key in this.kvs) {
				if (!this.selectedsku.hasOwnProperty(key)) {
					Toast.fail('请选择' + key);
					return;
				}
			}

			let sku_id = null
			for (var i = this.sku.length - 1; i >= 0; i--) {
				const specs = JSON.parse(this.sku[i].specs)
				let check = true
				Object.keys(specs).forEach((key) => {
					if (!(this.selectedsku && this.selectedsku[key] == specs[key])) {
						check = false
					}
				})
				if (check) {
					sku_id = this.sku[i].id;
				}
			}

			if (!sku_id) {
				Toast.fail("请选择需要充值的菜单");
				return;
			}

			this.sku_id = sku_id

			if (this.info.title == '话费充值') {
				if (phone.length != 11) {
					Toast.fail("手机号码长度不正确");
					return;
				}
				// Toast.fail("话费充值维护中！");
				// return;
				this.showDialog = true;
				var temp = '';
				for (var i = 0; i < phone.length; i++) {
					temp += phone[i]
					if (i == 2 || i == 6) {
						temp += " "
					}
				}
				this.phone = temp
				return
			}

			this.confirmonbuy()
		},
		confirmonbuy() {
			var phone = this.account.trim()
			const data = {
				"goods_id": this.goods_id,
				"sku_id": this.sku_id,
				"uid": hhstore.get("uid"),
				"token": hhstore.get("token"),
				"data": {
					"phone": phone
				}
			};

			const UA = navigator.userAgent.toLowerCase()
			const inWechat = UA.match(/.*?(micromessenger\/([0-9.]+))\s*/)
			const inDingDing = UA.match(/.*?(dingtalk\/([0-9.]+))\s*/)
			const cardType = hhstore.get('card_type')
			if (inWechat) {
				const inMini = window.__wxjs_environment === 'miniprogram';
				if (cardType == 'yhikmd') {
					this.sqb(data)
				} else if (inMini) {
					// 在微信小程序内
					var url = "wx/xiadan_mini";
					data['mini_type'] = hhstore.get("mini_type")
					this.post({
						url: url,
						data: data,
						success: (data) => {
							this.dobuymini(data)
						},
						error: (code, err) => {
							Toast.fail(err);
						}
					});

				} else {
					var url = "wx/xiadan";
					var self = this;
					this.post({
						url: url,
						data: data,
						success: function (data) {
							self.dobuy(data)
						},
						error: function (code, err) {
							if (code == 10002) { //登录失效
								hhstore.del("uid")
								hhstore.del("phone")
								hhstore.del("token")
								self.$router.push('/register')
							} else if (code == 91607) { //微信未授权
								let authBack = "/pay?gid=" + self.goods_id
								if (self.account.trim().length) {
									authBack = authBack + '&account=' + self.account.trim()
								}
								hhstore.set('auth_back', authBack)
								self.$router.push('/auth')
							} else {
								Toast.fail(err);
							}
						}
					});
				}
			} else if (inDingDing) {
				this.sqb(data)
			} else {
				if (cardType == 'yhikmd') {
					this.sqb(data)
				} else {
					var url = "wx/xiadan_h5";
					var self = this;
					this.post({
						url: url,
						data: data,
						success: (data) => {

							window.location.href = data.pay_info.h5_url;
							setTimeout(() => {
								Dialog.confirm({
									title: '提示',
									message: '已经支付完成？',
									cancelButtonText: '未完成',
									confirmButtonText: '已完成'

								})
									.then(() => {
										Dialog.close()
										this.$router.push("/order")
									})
									.catch(() => {
										// on cancel
									});
							}, 1000);


						},
						error: function (code, err) {
							Toast.fail(err)
						}
					});
				}
			}
		},
		sqb(data) {
			var url = "wx/xiadan_sqb";
			var self = this;
			this.post({
				url: url,
				data: data,
				success: function (data) {
					window.location.href = data.pay_info.h5_url;
				},
				error: function (code, err) {
					Toast.fail(err)
				}
			});
		},
		dobuy(data) {
			var self = this;
			WeixinJSBridge.invoke('getBrandWCPayRequest', data,
				function (res) {
					if (res.err_msg == "get_brand_wcpay_request:ok") {
						// 使用以上方式判断前端返回,微信团队郑重提示：
						//res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
						Toast.success("购买成功");
						setTimeout(function () {
							self.$router.push('/order')
						}, 3000);

					} else {
						Toast.fail("购买失败");
					}
				}
			);
		},
		dobuymini(data) {

			let payInfo = data.pay_info
			payInfo.redirectUrl = window.location.origin + `/order`;
			wx.miniProgram.navigateTo({
				url: '/pages/payment/payment?key=' + encodeURIComponent(JSON.stringify(payInfo))
			}); // 跳转到小程序支付页面

		}
	}
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
body {
	text-align: center;

}

.bg {
	background-color: #fff;
	height: 100vh;
}

.btndiv {
	position: absolute;
	bottom: 0px;
	left: 0px;
}

.btndivleft {
	width: 50vw;
	height: 4rem;
	background-color: #ffffff;
	border-width: 0px;
	color: #333333;
	font-size: 1rem;
}

.btndivright {
	width: 50vw;
	height: 4rem;
	background-color: #4F4F4F;
	border-width: 0px;
	color: #B2ACA3;
	font-size: 1rem;
}

.top {
	height: 4.375rem;
	background-image: linear-gradient(to right, #F9F4E0, #F3EACC);
	line-height: 4.375rem;
	font-size: 1rem;
	font-weight: 800;
	color: #000000;
}

.top img {
	margin-left: 1.25rem;
	margin-top: 0.625rem;
	width: 3.125rem;
	height: 3.125rem;
	float: left;
}

.top span {
	margin-left: 0.3rem;
}

.haoma {
	clear: both;
	font-size: 0.76rem;
	margin-top: 1.6rem;
	margin-left: 0.93rem;
}

.ipt {
	font-size: 1.32rem;
	margin-top: 0.625rem;
	margin-left: 0.93rem;
	margin-bottom: 0.9375rem;
}


.xuzhi {
	font-size: 0.83rem;
	margin-left: 0.93rem;
	background-color: #FCF7E9;
	color: #C7AC7C;
	width: 85vw;
	margin-top: 1rem;
	padding-right: 0.93rem;
	padding-top: 0.1rem;
	padding-bottom: 1rem;
	padding-left: 0.93rem;
}

.fenge {
	height: 0.53rem;
	background-color: #F5F5F5;
	margin-top: 0.8rem;
}

.miane {
	margin-left: 0.93rem;
	margin-top: 0.6rem;
}

.mianeitem {
	float: left;
	/*height: 6.66rem;
    width: 6.66rem;;*/
	padding-left: 1rem;
	padding-right: 1rem;
	padding-top: 1rem;
	padding-bottom: 1rem;
	text-align: center;
	margin-left: 0.5rem;
	margin-bottom: 0.5rem;
	border-radius: 5px;
	border-width: 1px;
	border-color: #cccccc;
	border-style: solid;
	font-size: 0.66rem;
}

.mianeselect {
	border-color: #E7D1B5;
	box-shadow: #FCF7E9 0px 0px 3px 3px;
	color: #CF7055;
	background-color: #FDFCF7;
}

.mianeiteml1 {
	font-size: 0.875rem;

	/*margin-top: 1.36rem;*/
}

.mianeiteml2 {
	font-size: 0.86rem;
	margin-top: 0.33rem;
	color: #2c3e50;
}

.mianeiteml3 {
	margin-top: 0.33rem;
	text-decoration: line-through;
	color: #c1c1c1;
}

.clearfix:before,
.clearfix:after {
	content: "";
	display: table;
}

.clearfix:after {
	clear: both;
}

.clearfix {
	zoom: 1
}

pre {
	white-space: pre-wrap;
	/*css-3*/
	white-space: -moz-pre-wrap;
	/*Mozilla,since1999*/
	white-space: -pre-wrap;
	/*Opera4-6*/
	white-space: -o-pre-wrap;
	/*Opera7*/
	word-wrap: break-word;
	/*InternetExplorer5.5+*/
}

.content {
	overflow: scroll;
	height: calc(100vh - 4rem);
	height: -webkit-calc(100vh - 4rem);
	height: -moz-calc(100vh - 4rem);
}

.dialog-title {
	color: white;
	height: 3.375rem;
	line-height: 3.375rem;
	text-align: center;
	font-size: 0.875rem;
	background-image: linear-gradient(to right, #F1A259, #ED6B80);
}

.dialog-text {
	margin: 0rem 0.8125rem 0.625rem 0.8125rem;
	font-size: 0.8125rem;
}

.dialog-text2 {
	font-size: 1.125rem;
	color: red;
	font-weight: bold;
}

.dialog-text3 {
	height: 2.75rem;
	background-color: #F6F6F6;
	border-radius: 1.375rem;
	margin-left: 0.8125rem;
	margin-right: 0.8125rem;
	margin-top: 1.25rem;
	margin-bottom: 1.25rem;
	text-align: center;
	line-height: 2.75rem;
	font-size: 1.375rem;
}
</style>
<style>
.ql-size-small {
	font-size: 0.75em;
}

.ql-size-large {
	font-size: 1.5em;
}

.ql-size-huge {
	font-size: 2.5em;
}

.ipt input {
	border-width: 0px;
	border-bottom-width: 1px;
	background-color: rgba(0, 0, 0, 0);
	height: 2.5rem;
	border-bottom-color: #c1c1c1;
	color: #333;
}
</style>
