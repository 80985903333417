<template>
    <div>
        <div class="btndiv"><button v-on:click="onbuy()">点击购买：¥0.01元 QQ公仔</button></div>
    </div>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import validate from '../utils/Validate'
import hhstore from '../utils/HHStore'
import urlParams from '../utils/UrlParams'
import { Toast } from 'vant';

/* 用来微信授权用 ，授权完成后，根据  localStorage.auth_back 地址，跳回原网址 */

export default {
    name: 'register',
    data: function() {
        return {

        }
    },
    props: {

    },
    beforeMount() {

    },
    mounted() {
        if (!hhstore.get("token")) {
            this.$router.push('/register')
            return
        }
    },
    beforeDestroy() {

    },
    computed: {
        ...mapState([])
    },
    methods: {
        ...mapActions(['post']),
        onbuy: function() {

            const UA = navigator.userAgent.toLowerCase()
            const inWechat = UA.match(/.*?(micromessenger\/([0-9.]+))\s*/)
            if (inWechat) {
                var url = "wx/xiadan";
                var self = this;
                this.post({
                    url: url,
                    data: {
                        "goods_id": 1,
                        "uid": hhstore.get("uid"),
                        "token": hhstore.get("token")
                    },
                    success: function(data) {
                        self.dobuy(data)
                    },
                    error: function(code, err) {
                        if(code == 10002){ //登录失效
                            hhstore.del("uid")
                            hhstore.del("phone")
                            hhstore.del("token")
                            self.$router.push('/register')
                        }
                        if(code == 91607){ //微信未授权
                            hhstore.set('auth_back',"/pay")
                            self.$router.push('/auth')
                        }
                    }
                });
            }else{
                var url = "wx/xiadan_h5";
                var self = this;
                this.post({
                    url: url,
                    data: {
                        "goods_id": 1,
                        "uid": hhstore.get("uid"),
                        "token": hhstore.get("token")
                    },
                    success: function(data) {
                        window.location.href = data.pay_info.h5_url;
                    },
                    error: function(code, err) {
                        if(code == 10002){ //登录失效
                            hhstore.del("uid")
                            hhstore.del("phone")
                            hhstore.del("token")
                            self.$router.push('/register')
                        }
                        if(code == 91607){ //微信未授权
                            hhstore.set('auth_back',"/pay")
                            self.$router.push('/auth')
                        }
                    }
                });
            }
            
        },
        dobuy(data) {
            var self = this;
            WeixinJSBridge.invoke('getBrandWCPayRequest', data,
                function(res) {
                    if (res.err_msg == "get_brand_wcpay_request:ok") {
                        // 使用以上方式判断前端返回,微信团队郑重提示：
                        //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
                        Toast.success("购买成功");
                    }else{
                        Toast.fail("购买失败");
                    }
                }
            );
        }
    }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
body{
    text-align: center;
}
.btndiv{
    margin:0 auto;
    margin-top: 45vh;
    width: 80vw;
} 
.btndiv button{
    width: 80vw;
    height: 3rem;
}
</style>