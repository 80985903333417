<template>
	<div class="content">
		<div class="container">
			<div data-v-62162824="" style="padding: 9px 16px 3px 9px; background: rgb(234, 215, 199);"><img data-v-62162824=""
					src="http://img.zhsyh.cn/uanma/top.639818f.png" alt="" style="width: 100%;"></div>
			<div data-v-62162824="" style="width: 100%; height: 6px; background: rgb(249, 217, 166);"></div>
			<div data-v-62162824="" style="padding: 20px 10%;">
				<div data-v-62162824="" style="width: calc(100% - 10px); margin-left: 5px;">


					<!-- 显示充值结果 -->
					<!-- 成功图片 /static/img/used.1a3ba7d.png -->
					<!-- 充值中图片 /static/img/rechange.cf6f8aa.png -->
					<div data-v-62162824="" class="pr" v-show="showResult"
						style="border: 1px solid rgb(255, 112, 36); padding: 5px 0px; border-radius: 5px; background: rgb(255, 255, 255);">

						<img v-show="quanma.order_status == '1'" data-v-62162824=""
							src="http://img.zhsyh.cn/uanma/rechange.cf6f8aa.png" alt="" class="pa"
							style="width: 40px; top: 2px; right: 2px;">
						<img v-show="quanma.order_status == '2'" data-v-62162824="" src="http://img.zhsyh.cn/uanma/used.1a3ba7d.png"
							alt="" class="pa" style="width: 40px; top: 2px; right: 2px;">
						<img v-show="quanma.order_status == '3'" data-v-62162824="" src="http://img.zhsyh.cn/uanma/czfail.png" alt=""
							class="pa" style="width: 40px; top: 2px; right: 2px;">

						<div data-v-62162824="" class="allcenter mb10"><img data-v-62162824="" :src="quanma.product_icon" alt=""
								style="width: 15px;margin-right: 3px;">
							<p data-v-62162824="" class="fz13 fb mb0 ml5" style="color: rgb(51, 51, 51);">{{ quanma.name }}</p>
						</div>
						<div data-v-62162824="" class="allcenter">
							<div data-v-62162824="" style="text-align: left;">
								<p data-v-62162824="" class="fz11 mb5" style="color: rgb(153, 153, 153);">兑换账号：{{ quanma.account }}</p>
								<p data-v-62162824="" class="fz11 mb0" style="color: rgb(153, 153, 153);">兑换时间：{{ quanma.used_time }}</p>
							</div>
						</div>
					</div>

					<div data-v-62162824="" class="allcenter" v-show="showResult && quanma.order_status == '3'" @click="onretry"
						style="margin-top: 10px; border-radius: 25px; width: 100%; height: 45px; background-image: linear-gradient(rgb(255, 152, 97), rgb(255, 115, 39)); box-shadow: rgb(219, 200, 175) 0px 15px 10px -15px;">
						<p data-v-62162824="" class="fz16 mb0" style="color: rgb(255, 251, 249);">重新兑换</p>
					</div>

					<!-- 显示输入账号 -->
					<div data-v-62162824="" class="vertical-center mb10" v-show="showInput"
						style="height: 47px; border: 1px solid rgb(255, 112, 36); padding: 10px 20px; box-sizing: border-box; border-radius: 6px; background: rgb(255, 255, 255);">
						<img data-v-62162824="" :src="quanma.product_icon" style="width: 25px; height: 25px;">
						<p data-v-62162824="" class="fz13 fb mb0 ml10" style="color: rgb(51, 51, 51);">{{ quanma.name }}</p>
					</div>
					<input data-v-62162824="" v-show="showInput" placeholder="请输入充值账号" type="text" v-model="account"
						style="height: 47px; border: 1px solid rgb(241, 239, 236);">
					<p data-v-62162824="" v-show="showInput" class="fz14" style="color: rgb(237, 23, 31); margin: 10px 0px;">*
						本次充值为快充，预计3分钟内到账</p>
					<div data-v-62162824="" class="allcenter" v-show="showInput" @click="onduihuan"
						style="margin-top: 10px; border-radius: 25px; width: 100%; height: 45px; background-image: linear-gradient(rgb(255, 152, 97), rgb(255, 115, 39)); box-shadow: rgb(219, 200, 175) 0px 15px 10px -15px;">
						<p data-v-62162824="" class="fz16 mb0" style="color: rgb(255, 251, 249);">去兑换</p>
					</div>
					<!--  -->


					<!-- 显示输入兑换码 -->
					<input data-v-62162824="" placeholder="请输入兑换码" type="text" v-show="showDuihuan" v-model="code"
						style="height: 47px; border: 1px solid rgb(241, 239, 236);">
					<p data-v-62162824="" v-show="showDuihuan" class="fz14" style="color: rgb(237, 23, 31); margin: 10px 0px;">*
						本次充值为快充，预计3分钟内到账</p>
					<div data-v-62162824="" class="allcenter" v-show="showDuihuan" @click="onchakan"
						style="margin-top: 10px; border-radius: 25px; width: 100%; height: 45px; background-image: linear-gradient(rgb(255, 152, 97), rgb(255, 115, 39)); box-shadow: rgb(219, 200, 175) 0px 15px 10px -15px;">
						<p data-v-62162824="" class="fz16 mb0" style="color: rgb(255, 251, 249);">查看兑换权益</p>
					</div>
					<!--  -->



				</div>
				<div data-v-62162824="" class="mt15"
					style="background: rgb(254, 250, 245); border-radius: 3px; padding: 5px 0px;">
					<div data-v-62162824=""
						style="width: calc(100% - 10px); margin: 0px 5px; border: 1px solid rgb(255, 112, 36); padding: 25px 10px; border-radius: 5px;">
						<div data-v-62162824="" class="allcenter"><img data-v-62162824=""
								src="http://img.zhsyh.cn/uanma/change.01cdc27.png" alt="" style="width: 150px;"></div>
						<div data-v-62162824="" style="margin-top: 25px;">
							<div data-v-62162824="" class="vertical-center">
								<div data-v-62162824="" class="pr" style="width: 43px; height: 16px;"><img data-v-62162824=""
										src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACsAAAAQCAMAAAC4PnacAAAAV1BMVEVHcEz/pHL/klf/iU3/i03/ikz/cib/i03/jUv/i03/hkf/dSr/i0z/cSX/onD/hUP/fTf/eTL/iUn/pHT/gT3/di3/n2z/jVD/cyn/nGf/lVz/mWL/kVZfQZ7AAAAADXRSTlMA4MhJN6PqWQ5/kcvtuNM7wQAAAF9JREFUKM+N0IkRgCAUxcAgCjjeirf91+mng7cdJIB3m8JFSKNmc1AfogrirRkbaF9RC80jsrbqE9XQn6JEWkW9LZs0uSzLorJs1ixl2SKyZd2uuWxZd2lCsrYwKIKHH4JNM61IK9v6AAAAAElFTkSuQmCC"
										alt="" style="width: 43px; height: 16px;">
									<div data-v-62162824="" class="allcenter pa" style="top: 0px; left: 0px; width: 43px; height: 16px;">
										<p data-v-62162824="" class="fz10 mb0" style="color: rgb(255, 250, 248);">SETP.1</p>
									</div>
								</div>
								<p data-v-62162824="" class="fz12 mb0 ml10" style="color: rgb(51, 51, 51);">输入卡密，查看可兑换的权益</p>
							</div>
						</div>
						<div data-v-62162824="" style="margin-top: 25px;">
							<div data-v-62162824="" class="vertical-center">
								<div data-v-62162824="" class="pr" style="width: 43px; height: 16px;"><img data-v-62162824=""
										src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACsAAAAQCAMAAAC4PnacAAAAV1BMVEVHcEz/pHL/klf/iU3/i03/ikz/cib/i03/jUv/i03/hkf/dSr/i0z/cSX/onD/hUP/fTf/eTL/iUn/pHT/gT3/di3/n2z/jVD/cyn/nGf/lVz/mWL/kVZfQZ7AAAAADXRSTlMA4MhJN6PqWQ5/kcvtuNM7wQAAAF9JREFUKM+N0IkRgCAUxcAgCjjeirf91+mng7cdJIB3m8JFSKNmc1AfogrirRkbaF9RC80jsrbqE9XQn6JEWkW9LZs0uSzLorJs1ixl2SKyZd2uuWxZd2lCsrYwKIKHH4JNM61IK9v6AAAAAElFTkSuQmCC"
										alt="" style="width: 43px; height: 16px;">
									<div data-v-62162824="" class="allcenter pa" style="top: 0px; left: 0px; width: 43px; height: 16px;">
										<p data-v-62162824="" class="fz10 mb0" style="color: rgb(255, 250, 248);">SETP.2</p>
									</div>
								</div>
								<p data-v-62162824="" class="fz12 mb0 ml10" style="color: rgb(51, 51, 51);">选择需要兑换的权益，点击“去兑换”</p>
							</div>
						</div>
						<div data-v-62162824="" style="margin-top: 25px;">
							<div data-v-62162824="" class="clear">
								<div data-v-62162824="" class="pr fl mr10" style="width: 43px; height: 16px;"><img data-v-62162824=""
										src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACsAAAAQCAMAAAC4PnacAAAAV1BMVEVHcEz/pHL/klf/iU3/i03/ikz/cib/i03/jUv/i03/hkf/dSr/i0z/cSX/onD/hUP/fTf/eTL/iUn/pHT/gT3/di3/n2z/jVD/cyn/nGf/lVz/mWL/kVZfQZ7AAAAADXRSTlMA4MhJN6PqWQ5/kcvtuNM7wQAAAF9JREFUKM+N0IkRgCAUxcAgCjjeirf91+mng7cdJIB3m8JFSKNmc1AfogrirRkbaF9RC80jsrbqE9XQn6JEWkW9LZs0uSzLorJs1ixl2SKyZd2uuWxZd2lCsrYwKIKHH4JNM61IK9v6AAAAAElFTkSuQmCC"
										alt="" style="width: 43px; height: 16px;">
									<div data-v-62162824="" class="allcenter pa" style="top: 0px; left: 0px; width: 43px; height: 16px;">
										<p data-v-62162824="" class="fz10 mb0" style="color: rgb(255, 250, 248);">SETP.3</p>
									</div>
								</div>
								<p data-v-62162824="" class="fz12 mb0" style="color: rgb(51, 51, 51);">根据提示，确认输入信息无误，点击“确定”</p>
							</div>
						</div>
						<div data-v-62162824="" style="margin-top: 25px;">
							<div data-v-62162824="" class="vertical-center">
								<div data-v-62162824="" class="pr" style="width: 43px; height: 16px;"><img data-v-62162824=""
										src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACsAAAAQCAMAAAC4PnacAAAAV1BMVEVHcEz/pHL/klf/iU3/i03/ikz/cib/i03/jUv/i03/hkf/dSr/i0z/cSX/onD/hUP/fTf/eTL/iUn/pHT/gT3/di3/n2z/jVD/cyn/nGf/lVz/mWL/kVZfQZ7AAAAADXRSTlMA4MhJN6PqWQ5/kcvtuNM7wQAAAF9JREFUKM+N0IkRgCAUxcAgCjjeirf91+mng7cdJIB3m8JFSKNmc1AfogrirRkbaF9RC80jsrbqE9XQn6JEWkW9LZs0uSzLorJs1ixl2SKyZd2uuWxZd2lCsrYwKIKHH4JNM61IK9v6AAAAAElFTkSuQmCC"
										alt="" style="width: 43px; height: 16px;">
									<div data-v-62162824="" class="allcenter pa" style="top: 0px; left: 0px; width: 43px; height: 16px;">
										<p data-v-62162824="" class="fz10 mb0" style="color: rgb(255, 250, 248);">SETP.4</p>
									</div>
								</div>
								<p data-v-62162824="" class="fz12 mb0 ml10" style="color: rgb(51, 51, 51);">兑换成功</p>
							</div>
						</div>
					</div>
				</div>
				<div data-v-62162824="" class="mt15" style="background: rgb(255, 83, 56); border-radius: 3px; padding: 5px 0px;">
					<div data-v-62162824=""
						style="width: calc(100% - 10px); margin: 0px 5px; border: 1px solid rgb(255, 255, 255); padding: 10px; border-radius: 5px;">
						<p data-v-62162824="" class="fz12" style="color: rgb(255, 255, 255); line-height: 20px;">
							温馨提示：若兑换失败或无法兑换，请联系您购买的渠道（店铺）处理</p>
					</div>
				</div>
			</div>

			<van-overlay :show="showDialog" @click="showDialog = false">
				<div data-v-62162824="" class="allcenter" style="height: 100%; width: 100%;">
					<div data-v-62162824="" class="center"
						style="width: 70%;max-width: 290px;  border-radius: 12px; background: rgb(255, 255, 255); box-sizing: border-box;">
						<div data-v-62162824="" class="pr"><img data-v-62162824="" src="http://img.zhsyh.cn/uanma/top1.3d5d761.png"
								alt="" style="width: 100%;">
							<div data-v-62162824="" class="pr" style="width: 100%; height: 20px;"><img data-v-62162824=""
									src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwAgMAAAAqbBEUAAAACVBMVEXyNDRHcEzyNDRwW0N4AAAAA3RSTlMZAAt+U4WpAAAA7klEQVQoz3VSOQ7DIBAckCyFB9Cnockr/AQXbBSlcpln8An3bizlmdkDHJCSlThm7wvE9AQOecHnDqaN6BCwCghEBVUgIs9gMTDdBRQgPQC/O6jWpqoTSJgqRoL4ckSzsCBPIGOB0AB/xdQZ2JDRbBDBzr7eNH6LY1m+9MaMk9wICi72vcKj+FZc8XKLdyStK1FGWBGVl7giLm4jySVyi2Lmin8AVstVTWzCUh1MneshaMHN0nlzOv+zNgO7x7K7hkxjq4YmDu0VBZPIFM6RBOqGlcjGSKTj7gasG7JaZkFBXYpou7PDAg+LpOBcsQ8apFbdq711ugAAAABJRU5ErkJggg=="
									alt="" class="pa" style="top: -35px; left: 24%; width: 25px;"> <img data-v-62162824=""
									src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACMAAAAmAgMAAAAzesp5AAAACVBMVEVHcEz6czz6czw1w05eAAAAA3RSTlMAGQ6GzkDBAAAAsElEQVQY01WQMQ7CQAwEnZNOIvQ09NfkFYgXXJGNAlVEySvyiTyBgl+y3ksscdWcvV6tbcY3Davp9cAcNDXKOIpOOLrA+k9ddIPSRhhjVC5ewu1QidREJV1Eo2T3TRESsGT5caB2bx/ppTmTMsaTh1hIswyqFUyrj1X+HtR92WF9cAenxezai6grEPnwJ8i9ihs8Cyk7KaBHfrWoWgPulbZYJMVyDdsBU5ySketOlsx+4udEQtuxr1AAAAAASUVORK5CYII="
									alt="" class="pa" style="top: -25px; right: -5px; width: 25px;"> <img data-v-62162824=""
									src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAmAgMAAACqghPMAAAACVBMVEUSlttHcEwSlttSiwW+AAAAA3RSTlMZAAjnWtQTAAAAlklEQVQY01WPsQ3FIAxEL0gpGCAjZA9GoOAQZeo/BUuwb+5wml9YT6Dnsw1yLJIg6x0sOTjTZgeW2YDbrEA2H+RkFpxlicClBFhvmRhQRCIqDkUuFHfVGxOnR0C6upPeSyMSHoVpBMYR7KL/Obk9Ptfu41B/X9pLQuz50YJpwdyCGAmua7N5A3txB/eGf8yfF+xxN/lTvTDAQyDRr0bKAAAAAElFTkSuQmCC"
									alt="" class="pa" style="top: 10px; left: 5%; width: 18px;"> <img data-v-62162824=""
									src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACEAAAAmBAMAAAC4z+/kAAAAD1BMVEWRzHZHcEyRzHaRzHaRzHbZTdKwAAAABXRSTlMyAAwlGZwIbBQAAADmSURBVCjPXdJRmoMgDATgqXgAQQ8AdQ+QtnsA2PsfakkmYtUX618mCR8g2lPi8Syw1xbKTTLmm1QMGmuAt/1aK0UXkUQoe5FOLcaETJHwJOWJdVIvvHUJpTaKfpGS91qghXd1F+3k5HVWEzT9x7qndz7JJlzwQ9EZbJ6MhzilX6sjCB7Dy3r1+eC1wb3r1x9hpuh8U/aQic3XhCETdtHsRNm8yzNujbJ4m48tUTlGAR4uA1AoaUDw01mvoS75GupSD5jjPfUap7xfQue+ON5xE+pXyE9HuO0vSTJC49bpvbhITOfN/Af/kSNFBj0RkQAAAABJRU5ErkJggg=="
									alt="" class="pa" style="top: 20px; right: 10%; width: 18px;">
								<div data-v-62162824="" class="pa" style="width: 100%; top: 0px; left: 0px;">
									<p data-v-62162824="" class="fz14 fb center" style="color: rgb(17, 17, 17);">请确保充值帐号无误</p>
									<p data-v-62162824="" class="fz14 fb center" style="color: rgb(17, 17, 17);">提交后无法退换</p>
								</div>
							</div>
							<div data-v-62162824="" style="width: 100%; height: 25px;"></div>
							<p data-v-62162824="" class="fz12 center" style="color: rgb(153, 153, 153); margin: 10px 0px 5px;">充值帐号</p>
							<p data-v-62162824="" class="fz20 fb center" style="color: rgb(240, 45, 46); margin-bottom: 15px;">
								{{ this.account }}
							</p>
							<div data-v-62162824="" class="allcenter" style="width: 90%; margin-left: 5%; margin-bottom: 15px;">
								<div data-v-62162824="" class="allcenter"
									style="width: 48%; height: 30px; margin-right: 4%; border-radius: 5px; background: rgb(238, 238, 238);">
									<p data-v-62162824="" class="fz12" style="color: rgb(188, 188, 188);">取消</p>
								</div>
								<div data-v-62162824="" class="allcenter" @click="onqueren"
									style="width: 48%; height: 30px; background-image: linear-gradient(rgb(255, 152, 97), rgb(255, 115, 39)); border-radius: 5px;">
									<p data-v-62162824="" class="fz12" style="color: rgb(255, 255, 255);">确定</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</van-overlay>
		</div>
	</div>
</template>

<script>
import Vue from 'vue';
import aes from '../utils/Aes'
import urlParams from '../utils/UrlParams'

import { Overlay, Toast } from 'vant';
Vue.use(Overlay);

import {
	mapActions,
	mapState,
	store
} from 'vuex'
import hhstore from '../utils/HHStore'
import help from '../utils/Help'

export default {
	data() {
		return {
			showDialog: false,
			showResult: false,
			showInput: false,
			showDuihuan: true,
			code: '',
			account: '',
			quanma: {}
		}
	},
	mounted() {

		var outId = urlParams.get('outId');
		if (outId) {
			this.code = outId
			this.onchakan();
		}

	},

	onShow() { },
	methods: {
		...mapActions(['post', 'syncpost']),
		onchakan() {
			if (!this.code || this.code.trim().length < 1) {
				Toast.fail('请输入兑换码');
				return
			}

			Toast.loading({
				message: '请稍候...',
				forbidClick: true,
				duration: 0
			});

			let params = { 'code': aes.encryptAES(this.code, aes.getkey(), aes.getiv()) }
			this.post({
				url: 'chongzhi/quanma_query',
				data: params,
				success: (data) => {
					Toast.clear()

					this.quanma = data
					switch (data.order_status) {
						case null:
							this.showResult = false
							this.showInput = true
							this.showDuihuan = false
							break;
						case '1':
						case '2':
						case '3':
							this.showResult = true
							this.showInput = false
							this.showDuihuan = false
							break;
						default:
							break;
					}
				},
				error: (code, err) => {
					Toast.fail(err);
				}
			});
		},
		onqueren() {
			this.showDialog = false
			Toast.loading({
				message: '请稍候...',
				forbidClick: true,
				duration: 0
			});
			let params = {
				'id': aes.encryptAES(this.quanma.id, aes.getkey(), aes.getiv()),
				account: this.account
			}
			this.post({
				url: 'chongzhi/quanma_use',
				data: params,
				success: (data) => {
					Toast.clear()
					this.quanma = data
					this.showResult = true
					this.showInput = false
					this.showDuihuan = false
				},
				error: (code, err) => {
					Toast.clear()
					Toast.fail(err);
				}
			});
		},
		onduihuan() {
			if (!this.account || this.account.trim().length < 1) {
				Toast.fail('请输入兑换账号');
				return
			}
			this.showDialog = true


		},
		onretry() {
			let params = {
				'id': aes.encryptAES(this.quanma.id, aes.getkey(), aes.getiv())
			}
			this.post({
				url: 'chongzhi/quanma_reset',
				data: params,
				success: (data) => {
					this.quanma = data

					this.showResult = false
					this.showInput = true
					this.showDuihuan = false
				},
				error: (code, err) => {
					Toast.fail(err);
				}
			});
		}
	}
}
</script>

<style scoped>
/* .quanguo,
.van-overlay,
.van-popup,
.van-tabbar--fixed,
body {
	margin-left: calc((100% - 414px)/2) !important;
	max-width: 414px;
} */

.content {
	width: 100%;
	height: 100%;
	/* margin-left: calc((100% - 414px)/2) !important;
	max-width: 414px; */
	display: flex;
	justify-content: center;
	margin: 0;
	font-size: 14px;
	color: #777;
	-webkit-font-smoothing: antialiased;

	font-family: -apple-system, BlinkMacSystemFont, Helvetica Neue, Helvetica, Segoe UI, Arial, Roboto, PingFang SC, miui, Hiragino Sans GB, Microsoft Yahei, sans-serif;
}

.container {
	max-width: 414px;
	width: 100%;
}

.pa {
	position: absolute;
}

.pr {
	position: relative;
}

.allcenter {
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
}

.allcenter,
.justify-align,
.vertical-center {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}

.fz16 {
	font-size: 16px;
}

.fz14 {
	font-size: 14px;
}

.fz10 {
	font-size: 10px;
}

.fz12 {
	font-size: 12px;
}

.ml10 {
	margin-left: 10px;
}

.mr10 {
	margin-right: 10px;
}

.mt15 {
	margin-top: 15px;
}

.mb0 {
	margin-bottom: 0;
}

.mb10 {
	margin-bottom: 10px;
}

.fl {
	float: left;
}

p {
	margin: 0;
	padding: 0;
}

div,
img {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}

.vertical-center {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}

input {
	line-height: 21px;
	width: 100%;
	height: 40px;
	margin-bottom: 15px;
	padding: 10px 15px;
	-webkit-user-select: text;
	border: 1px solid rgba(0, 0, 0, .2);
	border-radius: 3px;
	outline: 0;
	font-size: 15px;
	background-color: #fff;
	-webkit-appearance: none;
	border-radius: 6px;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}

.center {
	text-align: center;
}


.fb {
	font-weight: 700;
}

.fz20 {
	font-size: 20px;
}

/*
p {
	display: block;
	margin-block-start: 1em;
	margin-block-end: 1em;
	margin-inline-start: 0px;
	margin-inline-end: 0px;
} */
</style>
