<template>
	<div class="tips-container">
		<div class="tips-text" v-if="value">{{value}}</div>
		<img class="tips-arrow" v-if="value" src="../assets/arrow.png"/>
	</div>
</template>

<script>

	
	export default {
		props: {
			value: {
				type: String,
				default: ''
			},
		},
	}
</script>

<style scoped>
	.tips-container {
		position: absolute;
		top: -1.1875rem;
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: flex-start;
	}
	
	.tips-text{
		height: 1rem;
		line-height: 1rem;
		color: white;
		font-size: 0.5625rem;
		padding: 0px 5px;
		border-radius: 0.5rem;
		background-color: #E25536;
	}
	
	.tips-arrow{
		height: 0.1875rem;
		align-self: center;
	}
</style>
