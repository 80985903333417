<template>
    <div class="bg">
        <div class="content">
            <div class="list" v-for="(item, index) in list">
                <div>订单号：{{ item.out_trade_no }}</div>
                <div>标题：{{ item.title }}</div>
                <div>帐号：{{ item.phone }}</div>
                <div>金额：{{ item.amount }}</div>
                <div>支付时间：{{ item.pay_time }}</div>
                <div>状态：{{ coverStatus(item.status) }}</div>
                <hr align=center size=1 />
            </div>
        </div>
        <div class="btndiv">
            <button class="btndivright" v-on:click="goback()">返回</button>
        </div>
    </div>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import validate from '../utils/Validate'
import hhstore from '../utils/HHStore'
import urlParams from '../utils/UrlParams'
import { Toast } from 'vant';

/* 用来微信授权用 ，授权完成后，根据  localStorage.auth_back 地址，跳回原网址 */

export default {
    name: 'Pay',
    data: function () {
        return {
            list: null
        }
    },
    props: {

    },
    beforeMount() {

    },
    mounted() {
        if (!hhstore.get("token")) {
            this.$router.push('/register')
            return
        }
        this.getList()
    },
    beforeDestroy() {

    },
    computed: {
        ...mapState([])
    },
    methods: {
        ...mapActions(['post']),
        getList() {
            var uid = hhstore.get("uid");
            var token = hhstore.get("token");
            var self = this
            this.post({
                url: "goods/order_list",
                data: {
                    "uid": uid,
                    "token": token
                },
                success: function (data) {
                    self.list = data
                },
                error: function (code, err) {
                    if (code == 10002) { //登录失效
                        hhstore.del("uid")
                        hhstore.del("phone")
                        hhstore.del("token")
                    }
                    Toast.fail(err);
                }
            });
        },
        coverStatus(status) {
            switch (status) {
                case "1":
                    return "待支付";
                case "2":
                    return "已支付";
                case "3":
                    return "充值中";
                case "4":
                    return "充值完成";
                case "5":
                    return "申请退款中";
                case "6":
                    return "退款成功";
                case "7":
                    return "退款被驳回";
                case "8":
                    return "订单关闭";
                case "9":
                    return "充值中";
                default:
                    return "";
            }
        },
        goback() {
            if (hhstore.get('card_type') == 'lmqvhe')
                this.$router.push('/chongzhikw')
            else
                this.$router.push('/')
        }
    }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.btndiv {
    position: absolute;
    bottom: 0px;
    left: 0px;
}

.content {
    overflow: scroll;
    height: calc(100vh - 4rem);
    height: -webkit-calc(100vh - 4rem);
    height: -moz-calc(100vh - 4rem);
}

.btndivright {
    width: 100vw;
    height: 4rem;
    background-color: #4F4F4F;
    border-width: 0px;
    color: #B2ACA3;
    font-size: 1rem;
}
</style>