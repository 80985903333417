<script>
import urlParams from './UrlParams'
import {
	mapActions,
	mapState
} from 'vuex'
import hhstore from './HHStore'
import axios from 'axios';
import 'vant/lib/index.css'

export default {
	auth(wxappid) {

		const UA = navigator.userAgent.toLowerCase()
		const inWechat = UA.match(/.*?(micromessenger\/([0-9.]+))\s*/)
		if (!inWechat) return;

		if (!urlParams.get('code')) {
			window.location.href = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" + wxappid +
				"&redirect_uri=" + encodeURIComponent(window.location.origin + window.location.pathname + window
					.location.search) + "&response_type=code&scope=snsapi_base#wechat_redirec";
		} else {
			var url = process.env.VUE_APP_perUrl + "wx/code2openid";
			var self = this;
			var data = {
				"code": urlParams.get('code'),
				"uid": hhstore.get("uid"),
				"token": hhstore.get("token")
			};

			// 塞入did
			var did = hhstore.get("__did");
			if (!did) {
				did = uuid.uuid();
				hhstore.set("__did", did)
			}
			data.did = did;

			// 塞入card_type
			var cardType = hhstore.get('card_type')
			if (cardType) {
				data.card_type = cardType;
			}

			axios({
				method: 'POST',
				data: data,
				url: url,
				headers: {
					'content-type': 'application/json'
				},
			})
				.then(function (res) {
					console.info(res)
				})
				.catch(function (error) {
					console.info(error)
				})
		}
	}
}
</script>
