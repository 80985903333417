<template>
	<div class="bg">

	</div>
</template>
<script>
	import {
		mapActions,
		mapState
	} from 'vuex'
	import {
		Toast
	} from 'vant';
	import urlParams from '../utils/UrlParams'
	export default {
		name: 'Pay',
		components: {

		},
		data: function() {
			return {

			}
		},
		props: {

		},
		beforeMount() {

		},
		mounted() {
			this.auth('wx06917261819a5960');
		},
		beforeDestroy() {

		},
		computed: {
			...mapState([])
		},
		methods: {
			...mapActions(['post']),
			auth(wxappid) {
				var self = this;
				
				const UA = navigator.userAgent.toLowerCase()
				const inWechat = UA.match(/.*?(micromessenger\/([0-9.]+))\s*/)
				if (!inWechat) return;

				if (!urlParams.get('code')) {
					window.location.href = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" + wxappid +
						"&redirect_uri=" + encodeURIComponent(window.location.origin + window.location.pathname + window
							.location.search) + "&response_type=code&scope=snsapi_base#wechat_redirec";
				} else {
					var con = urlParams.get('con')
					this.post({
						url: con + "/code2openid",
						data: {
							"code": urlParams.get('code'),
							"order_sn": urlParams.get('orderSn'),
						},
						success: function(data) {
							self.dobuy(data)

						},
						error: function(code, err) {
							Toast.fail(code + ":" + err);
						}
					});
				}
			},
			dobuy(data) {
				var self = this;
				WeixinJSBridge.invoke('getBrandWCPayRequest', data.pay_info,
					function(res) {
						if (res.err_msg == "get_brand_wcpay_request:ok") {
							// 使用以上方式判断前端返回,微信团队郑重提示：
							//res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
							Toast.success("购买成功");
							window.location.href = data.return_url
						} else {
							Toast.fail("购买失败");
						}
					}
				);
			},

		}
	}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
