<template>
	<div class="content">

		<div class="hycontent">
			<button v-on:click="onbuy()" class="hybtn"></button>
			<img :src="hybg" class="hybg" />
		</div>
	</div>
</template>

<script>
	/* 权益王兔年的2.9元会员活动 */
	import {
		mapActions,
		mapState,
		store
	} from 'vuex'

	import urlParams from '../utils/UrlParams'
	import hhstore from '../utils/HHStore'
	import help from '../utils/Help'
	import validate from '../utils/Validate'
	import {
		Toast,
		Dialog
	} from 'vant';
	export default {
		data() {
			return {
				hybg: "http://img.123zhs.com/h5/hy/hy5.jpg?x-oss-process=image/resize,w_752/quality,q_80",
				cardType: null,
			}
		},
		async mounted() {
			if (!hhstore.get("token")) {
				this.$router.push('/list2')
			}

			this.cardType = hhstore.get('card_type')
			if (this.cardType != 'qyw')
				this.hybg = 'http://img.123zhs.com/h5/hy/hy6.jpeg?x-oss-process=image/resize,w_752/quality,q_80'
		},
		onShow() {},
		methods: {
			...mapActions(['post', 'syncpost']),

			onbuy() {
				var uid = hhstore.get("uid");
				var token = hhstore.get("token");
				const data = {
					"uid": uid,
					"token": token
				}
				var url = "wx/xiadan_qyw_vip";
				var self = this;
				this.post({
					url: url,
					data: data,
					success: function(data) {
						self.dobuy(data)
					},
					error: function(code, err) {
						Toast.fail(err);
					}
				});
			},
			dobuy(data) {
				WeixinJSBridge.invoke('getBrandWCPayRequest', data,
					(res) => {
						if (res.err_msg == "get_brand_wcpay_request:ok") {
							// 使用以上方式判断前端返回,微信团队郑重提示：
							//res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
							this.complete()
						} else {
							Toast.fail("购买失败");
						}
					}
				);
			},
			complete() {
				Toast.loading({
					message: '确认支付结果...',
					forbidClick: true,
					duration: 0
				});

				var uid = hhstore.get("uid");
				var token = hhstore.get("token");
				const data = {
					"account": hhstore.get("phone"),
					"token": token
				}
				var count = 0
				var self = this
				var intervalHandel = setInterval((function handler() {
					console.info(count)
					var url = "wx/check_pay_vip";
					if (count > 2) {
						clearInterval(intervalHandel)
						Toast.clear()
						return
					}

					count++

					self.post({
						url: url,
						data: data,
						success: (data) => {
							Toast.clear()
							hhstore.set("uid", data.uid)
							hhstore.set("token", data.token)
							clearInterval(intervalHandel)
							Toast.success('购买成功')
							self.$router.push('/list2')
						},
						error: (code, err) => {

						}
					});
					return handler
				})(), 3000)
			},

		}
	}
</script>

<style scoped>
	.hybg {
		width: 100%;
		display: block;
	}

	.hycontent {
		position: relative;
	}

	.phoneinput {
		position: absolute;
		top: 32.3rem;
		left: 8rem;
		height: 2rem;
		width: 12rem;
		background-color: rgba(0, 0, 0, 0);
		border: none;
		-webkit-appearance: none;
		/*去除阴影边框*/
		outline: none;
		-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
		/*点击高亮的颜色*/
		color: #ffffff;

	}

	.nickinput {

		position: absolute;
		top: 27.3rem;
		left: 8rem;
		height: 2rem;
		width: 12rem;
		background-color: rgba(0, 0, 0, 0);
		border: none;
		-webkit-appearance: none;
		/*去除阴影边框*/
		outline: none;
		-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
		/*点击高亮的颜色*/
		color: #ffffff;
	}

	.hybtn {
		position: absolute;
		border: none;
		-webkit-appearance: none;
		/*去除阴影边框*/
		outline: none;
		-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
		/*点击高亮的颜色*/
		background-color: rgba(100, 100, 100, 0);

		width: 100vw;
		height: 16rem;
		bottom: 0rem;
		right: 0rem;
	}
</style>
