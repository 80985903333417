<template>
	<div class="list">
		<div class="list_1">
			<!-- <van-notice-bar mode="link"
              @click="barclick"
              left-icon="volume-o"
              :text="noticeText"
              v-show="showNotice"
            /> -->

			<div class="welcomediv">
				<a class='astyle' href="#">
					<img
						src="http://img.123zhs.com/assets/products/hyzx_ylx.png?x-oss-process=image/resize,w_1500/quality,q_80">
				</a>
				<div class="dueDate" v-show="showdueDate" v-on:click="showdlg = true">{{dueDate}}到期，延期 >></div>
			</div>

			<!-- 滴滴打车,小程序获取失败的时候，显示h5 -->
			<a class='astyle' v-show="showmtwmh5" :href="didih5">
				<img
					src="http://img.123zhs.com/assets/products/didi.png?x-oss-process=image/resize,w_1500/quality,q_80"></a>

			<!-- 美图外卖,小程序获取失败的时候，显示h5 -->
			<a class='astyle' v-show="showmtwmh5"
				href="https://click.meituan.com/t?t=1&c=1&p=OWMpZ-uzIFOVe6JyOONs3VzolOCHG2b7aH-XB09jFlbbAeBCxOxg6qjsKAN88woEbRkise1Y1sOp4yxoApT-HGK1PnLCNmWzIFq5logLKXSFsKhXNVUwRvhl4R5nuHqggDs87anJFBgjqqS02ibF_1KE5MF3mcPsY5nUDrKJphPXD9RJx6P1QPEtm4zZM40UHt3uaJkEiOljtZuZx2wWkayHKSPtuL3tbOFyn5aPgCqdJb45_PpUInDgFkR19xdPxY5tUe4PX6WY9gu3FS8gxczTp_02-_67PAAeNRtanTVY9Q08Wet1ShTxbVfhiYQ75XvGV2dyTGryzE86i9Cu2xU_KR22m_EGVYNnsbI-ZOraGXR_nPqm5VYCrlt4UQ4vTZlwgp45qf8oGBE9LZv-LxNgcVjCyfvBL97Y-Jdo0v0IWrVzPLPL-kdYkZ2XxOwjMZS90mVCA14FeV7eiPdmMRcQEazbCgohWs0DfDeySWy5M_TxANHqARQtwLguqA6yrNRNWHKTXqXUoHrzovwGuQjRgAeKFjCt-la8o417X3OpMiAT0vJpqewCa3emp3xS">
				<img
					src="http://img.123zhs.com/assets/products/mtwm.png?x-oss-process=image/resize,w_1500/quality,q_80"></a>
			<!-- 额了么外卖,小程序获取失败的时候，显示h5 -->
			<a class='astyle' v-show="showmtwmh5"
				href="https://s.click.ele.me/t?union_lens=lensId%3AOPT%401620355070%402107df51_07b5_17944b03937_0485%4001%3BeventPageId%3A20150318020002597&&e=-s02BZ4sIBzegozeOXAeZMYdBoLelqUh9umACmzcIuctOZbbqL3LqUPouYAU58TDVlPBO3ijzA7kUMzcgMNtNluMD0SFmKW6fswAsooMNhuTuRchsaWoS06AeWxZDMS7KGM7yA1VZeyVqV1CtbXHwT9943MXPgQqrOVHHCFSoHJbo3dK63mPUuqLLHVVOkYourQIzc8NtmbeeBwLOQhVARN6QHzDhplmaPkQOhAtNw6tzfjjtKlXK0sdSuBEykcZ2iCw7FBfCaTK5Nq29PnpWViv9KLKLQtZxD7JVZclHP49GK51CN9nmGs0jYjDwYP94tFvIB63WY4oI5lChSlPHTTwkkoTeva08Nv7k82dNaJBeTffBNkHzlPtxE3nIPUNBYoEiQOVn4HIocAa9smt53RiiFJ9QRbBduWVH5x6b11NdMKyYi&">
				<img
					src="http://img.123zhs.com/assets/products/elm.png?x-oss-process=image/resize,w_1500/quality,q_80"></a>

			<!-- 滴滴打车 -->
			<wx-open-launch-weapp v-show="!showmtwmh5" class='wxbtn' :username="didisource" :path="didipath"
				ref="wxLaunchBtn" id="launch-btn">
				<script type="text/wxtag-template">
					<style>
                        .bstyle {
                            background-size: contain|cover;
                            width: 100%;
                            height: auto;
                        }
                    </style>

                    <img src="http://img.123zhs.com/assets/products/didi.png?x-oss-process=image/resize,w_1500/quality,q_80" class='bstyle'>
                </script>
			</wx-open-launch-weapp>
			<!-- 美图外卖 -->
			<wx-open-launch-weapp v-show="!showmtwmh5" class='wxbtn' username="gh_870576f3c6f9"
				path="/waimai/pages/h5/h5?f_token=1&weburl=https%3A%2F%2Fdpurl.cn%2FMKwUTG6z" ref="wxLaunchBtn"
				id="launch-btn">
				<script type="text/wxtag-template">
					<style>
                        .bstyle {
                            background-size: contain|cover;
                            width: 100%;
                            height: auto;
                        }
                    </style>

                    <img src="http://img.123zhs.com/assets/products/mtwm.png?x-oss-process=image/resize,w_1500/quality,q_80" class='bstyle'>
                </script>
			</wx-open-launch-weapp>
			<!-- 饿了么外卖 -->
			<wx-open-launch-weapp v-show="!showmtwmh5" class='wxbtn' username="gh_6506303a12bb"
				path="taoke/pages/shopping-guide/index?scene=UikGQau">
				<script type="text/wxtag-template">
					<style>
                        .bstyle {
                            background-size: contain|cover;
                            width: 100%;
                            height: auto;
                        }
                    </style>

                    <img src="http://img.123zhs.com/assets/products/elm.png?x-oss-process=image/resize,w_1500/quality,q_80" class='bstyle'>
                </script>
			</wx-open-launch-weapp>

			<!-- 电影票 -->
			<a class='astyle' href="https://ptlxhy.cn/#/independentFilm?distributorCode=1361&flag=independent">
				<img
					src="http://img.123zhs.com/assets/products/movie.png?x-oss-process=image/resize,w_1500/quality,q_80"></a>

			<!-- 肯德基 -->
			<a class='astyle' v-show="cardType=='kfc'" v-on:click="kfcclick">
				<img
					src="http://img.123zhs.com/assets/products/kfc.png?x-oss-process=image/resize,w_1500/quality,q_80"></a>
			<!-- 数据线 -->
			<a class='astyle' v-show="cardType=='kfc'" href="https://songzhaopian.com/?channel=qywsjx">
				<img
					src="http://img.123zhs.com/assets/products/sjx.png?x-oss-process=image/resize,w_1500/quality,q_80"></a>
			<!-- 加油 -->
			<a class='astyle' v-show="showjy&&cardType=='kfc'" :href="jylink">
				<img
					src="http://img.123zhs.com/assets/products/jy.png?x-oss-process=image/resize,w_1500/quality,q_80"></a>
			<!-- 特权充值 -->
			<a class='astyle' v-show="cardType=='kfc'"
				href="https://H5.10010.wiki/Shopping/User/AppLogin?enterpriseId=20211614869453&m=2">
				<img
					src="http://img.123zhs.com/assets/products/zxtq.png?x-oss-process=image/resize,w_1500/quality,q_80"></a>

			<!-- 必胜客 -->
			<!-- http://zd.taillessbear.com/zd/bsk.php?channelid=102106 -->
			<a class='astyle' v-show="cardType=='dianying'"
				href="https://ptlxhy.cn/#/independentPizza?distributorCode=1361">
				<img
					src="http://img.123zhs.com/assets/products/bsk.png?x-oss-process=image/resize,w_1500/quality,q_80"></a>
			<!-- 免费领取马克杯 -->
			<a class='astyle' v-show="cardType=='dianying'" href="https://songzhaopian.com/?channel=qywmkb">
				<img
					src="http://img.123zhs.com/assets/products/mkb.png?x-oss-process=image/resize,w_1500/quality,q_80"></a>


			<!-- 奈雪 在线点餐 -->
			<a class='astyle' v-show="cardType=='waimai'"
				href="https://ot.jfshou.cn/api/nayuki/entrance?share_code=647J8fs9s&sign=0CF915C862E4FFF6FCB075FFBF84900E&timestamp=1625465871545">
				<img
					src="http://img.123zhs.com/assets/products/nx_online.png?x-oss-process=image/resize,w_1500/quality,q_80"></a>
			<!-- 奈雪 -->
			<!-- <a class='astyle' v-show="cardType=='waimai'" href="https://tq.jfshou.cn/seller/entrance?brandId=94&singleSign=true&distributionCode=yF4FMa4">
                <img src="http://img.123zhs.com/assets/products/nx.jpeg?x-oss-process=image/resize,w_1500/quality,q_80" ></a> -->

			<!-- 星巴克中杯券 -->
			<a class='astyle' v-show="cardType=='waimai'"
				href="http://zhs.ziwoyou.net/newb2c/productdetail/62037484?treeId=12&orderCustId=2170095&parentCustId=2162557&userId=&">
				<img
					src="http://img.123zhs.com/assets/products/xbkdhq.png?x-oss-process=image/resize,w_1500/quality,q_80"></a>
			<!-- 星巴克外卖 -->
			<a class='astyle'
				href="https://ptlxhy.cn/#/xbk/choose_address?distributorCode=1361&flag=independent">
				<img
					src="http://img.123zhs.com/h5/xbkwm.jpeg?x-oss-process=image/resize,w_1500/quality,q_80"></a>
			<!-- 星巴克在线点餐 -->
			<!-- https://ot.jfshou.cn/sbkplus/auth/index?share_code=647J8fs9s&sign=7AEC28884DBB12269DCC6B207D3AAC14&timestamp=1625300961494 -->
			<a class='astyle' v-show="cardType=='waimai'"
				href="https://ptlxhy.cn/#/independentXbk?distributorCode=1361&flag=independent">
				<img
					src="http://img.123zhs.com/assets/products/xbk.png?x-oss-process=image/resize,w_1500/quality,q_80"></a>


			<!-- 加油 -->
			<a class='astyle' v-show="showjy&&cardType=='waimai'" :href="jylink">
				<img
					src="http://img.123zhs.com/assets/products/jy.png?x-oss-process=image/resize,w_1500/quality,q_80"></a>
			<!-- 爱奇艺 -->
			<a class='astyle' v-show="cardType=='waimai'"
				href="https://h5.10010.wiki/Shopping/User/AppLogin?enterpriseId=20211614869453&type=2">
				<img
					src="http://img.123zhs.com/assets/products/aqy2.png?x-oss-process=image/resize,w_1500/quality,q_80"></a>
			<!-- 腾讯 -->
			<a class='astyle' v-show="cardType=='waimai'"
				href="https://h5.10010.wiki/Shopping/User/AppLogin?enterpriseId=20211614869453&type=3">
				<img
					src="http://img.123zhs.com/assets/products/tx.png?x-oss-process=image/resize,w_1500/quality,q_80"></a>
			<!-- 特权充值 -->
			<a class='astyle' v-show="cardType=='waimai'"
				href="https://H5.10010.wiki/Shopping/User/AppLogin?enterpriseId=20211614869453&m=2">
				<img
					src="http://img.123zhs.com/assets/products/zxtq.png?x-oss-process=image/resize,w_1500/quality,q_80"></a>
			<!-- 瑞幸咖啡 -->
			<!-- <a class='astyle' v-show="cardType=='waimai'" href="https://c.qiangrenwl.top/c/f10a5d96b2cc21e654dbe55c5962c70d">
                <img src="http://img.123zhs.com/assets/products/rx.png?x-oss-process=image/resize,w_1500/quality,q_80" ></a> -->
			<!-- 盘子女人坊 -->
			<a class='astyle' v-show="cardType=='waimai'" href="https://h5.gdt.qq.com/xjviewer/nemo/1757253">
				<img
					src="http://img.123zhs.com/assets/products/pznrf.png?x-oss-process=image/resize,w_1500/quality,q_80"></a>
			<!-- 汪正影业 -->
			<a class='astyle' v-show="cardType=='waimai'" href="http://yashilii.mikecrm.com/c9LP1kZ">
				<img
					src="http://img.123zhs.com/assets/products/wzyy.png?x-oss-process=image/resize,w_1500/quality,q_80"></a>

			<!-- 星巴克在线点餐 -->
			<!-- https://ptlxhy.cn/#/independentXbk?distributorCode=1361&flag=independent -->
			<a class='astyle' v-show="cardType=='xbk'"
				href="https://ot.jfshou.cn/sbkplus/auth/index?share_code=6492aNymP&sign=E33FF20CE5643CC1E27993E7791278CB&timestamp=1625465942755">
				<img
					src="http://img.123zhs.com/assets/products/xbk.png?x-oss-process=image/resize,w_1500/quality,q_80"></a>
			<!-- 星巴克中杯券 -->
			<a class='astyle' v-show="cardType=='xbk'"
				href="http://zhs.ziwoyou.net/newb2c/productdetail/31855413?treeId=12&orderCustId=2170095&parentCustId=2162557&userId=&">
				<img
					src="http://img.123zhs.com/assets/products/xbkdhq.png?x-oss-process=image/resize,w_1500/quality,q_80"></a>
			<!-- 免费领取手机壳 -->
			<a class='astyle' v-show="cardType=='xbk'" href="https://songzhaopian.com/?channel=qywsjk">
				<img
					src="http://img.123zhs.com/assets/products/sjrk.png?x-oss-process=image/resize,w_1500/quality,q_80"></a>


			<!-- 特权充值 -->
			<a class='astyle' v-show="cardType=='rx'"
				href="https://H5.10010.wiki/Shopping/User/AppLogin?enterpriseId=20211614869453&m=2">
				<img
					src="http://img.123zhs.com/assets/products/zxtq.png?x-oss-process=image/resize,w_1500/quality,q_80"></a>
			<!-- 奈雪 在线点餐 -->
			<a class='astyle' v-show="cardType=='rx'"
				href="https://ot.jfshou.cn/api/nayuki/entrance?share_code=647J8fs9s&sign=0CF915C862E4FFF6FCB075FFBF84900E&timestamp=1625465871545">
				<img
					src="http://img.123zhs.com/assets/products/nx_online.png?x-oss-process=image/resize,w_1500/quality,q_80"></a>
			<!-- 奈雪 -->
			<!-- <a class='astyle' v-show="cardType=='rx'" href="https://tq.jfshou.cn/seller/entrance?brandId=94&singleSign=true&distributionCode=yF4FMa4">
                <img src="http://img.123zhs.com/assets/products/nx.jpeg?x-oss-process=image/resize,w_1500/quality,q_80" ></a> -->
			<!-- 免费领取马克杯 -->
			<a class='astyle' v-show="cardType=='rx'" href="https://songzhaopian.com/?channel=qywmkb">
				<img
					src="http://img.123zhs.com/assets/products/mkb.png?x-oss-process=image/resize,w_1500/quality,q_80"></a>
			<!-- 喜茶 -->
			<a class='astyle' v-show="cardType=='rx'"
				href="https://ot.jfshou.cn/privilege-api/heytea/auth/index?shareCode=6492aNymP&sign=816E2CA7DB0A0CDE32C750C49022D853&timestamp=1631063306026">
				<img
					src="http://img.123zhs.com/assets/products/xc.png?x-oss-process=image/resize,w_1500/quality,q_80"></a>
			<!-- 瑞幸年卡 -->
			<a class='astyle' v-show="cardType=='rx'"
				href="https://tq.jfshou.cn/seller/entrance?brandId=22&singleSign=true&distributionCode=yF4FMa4">
				<img
					src="http://img.123zhs.com/assets/products/rxkf.png?x-oss-process=image/resize,w_1500/quality,q_80"></a>
			<!-- 瑞幸咖啡 -->
			<a class='astyle' v-show="cardType=='rx'"
				href="https://ot.jfshou.cn/privilege-api/luckin/auth/index?shareCode=6492aNymP&sign=4EDFABA4F3A2329CDFE6A836B010D4E7&timestamp=1628479577151">
				<img
					src="http://img.123zhs.com/assets/products/rx.png?x-oss-process=image/resize,w_1500/quality,q_80"></a>



			<!-- 肯德基 -->
			<!-- <a class='astyle' v-show="cardType=='mdl'" v-on:click="kfcclick">
                <img src="http://img.123zhs.com/assets/products/kfc.png?x-oss-process=image/resize,w_1500/quality,q_80" ></a> -->
			<!-- 麦当劳 -->
			<a class='astyle' v-show="cardType=='mdl'" href="#" v-on:click="mdlclick">
				<img
					src="http://img.123zhs.com/assets/products/mdl.png?x-oss-process=image/resize,w_1500/quality,q_80"></a>
			<!-- 免费领取手机壳 -->
			<a class='astyle' v-show="cardType=='mdl'" href="https://songzhaopian.com/?channel=qywsjk">
				<img
					src="http://img.123zhs.com/assets/products/sjrk.png?x-oss-process=image/resize,w_1500/quality,q_80"></a>
			<!-- 特权充值 -->
			<a class='astyle' v-show="cardType=='mdl'"
				href="https://H5.10010.wiki/Shopping/User/AppLogin?enterpriseId=20211614869453&m=2">
				<img
					src="http://img.123zhs.com/assets/products/zxtq.png?x-oss-process=image/resize,w_1500/quality,q_80"></a>


			<!-- 樊登读书 -->
			<a class='astyle' href="http://zhs.ziwoyou.net/newb2c/productdetail/64952420?treeId=12&orderCustId=2170095&parentCustId=2162557&userId=&">
			    <img src="http://img.123zhs.com/h5/fandeng.jpeg?x-oss-process=image/resize,w_1500/quality,q_80"></a>

			<!-- 汉堡王 -->
			<a class='astyle' v-show="!(cardType=='mdl' || cardType=='kfc')"
				href="https://ot.jfshou.cn/privilege-api/burgerKing/auth/index?shareCode=6492aNymP&sign=24FF5FFCAF4A63806C514DF0A779D879&timestamp=1636511411324">
				<img
					src="http://img.123zhs.com/assets/products/hbw.jpeg?x-oss-process=image/resize,w_1500/quality,q_80"></a>
		</div>

		<van-dialog title="输入新权益卡，会员延期2年" v-model="showdlg" show-cancel-button confirmButtonText="确定"
			:before-close="dialogclose">
			<van-form>
				<van-field v-model="card_number" name="卡号" label="卡号" placeholder="10位的卡号" style="font-size: 16px;" />
				<van-field v-model="card_secret" name="激活码" label="激活码" placeholder="6位的激活码" style="font-size: 16px;" />
			</van-form>
		</van-dialog>

	</div>
	</div>
</template>
<script>
	import {
		mapActions,
		mapState
	} from 'vuex'
	import help from '../utils/Help'
	import hhstore from '../utils/HHStore'
	import urlParams from '../utils/UrlParams'
	import wxutils from '../utils/Wx'
	import {
		Toast
	} from 'vant';
	import Vue from 'vue';
	import {
		NoticeBar
	} from 'vant';
	import {
		Dialog
	} from 'vant';
	import {
		Form
	} from 'vant';
	import {
		Field
	} from 'vant';
	import aes from '../utils/Aes'
	var md5 = require('md5')

	Vue.use(Form);
	Vue.use(Field);
	Vue.use(NoticeBar);

	export default {
		name: 'list',
		components: {
			[Dialog.Component.name]: Dialog.Component,
		},
		data: function() {
			return {
				card_number: '',
				card_secret: '',
				dueDate: "",
				showdueDate: false,
				showmtwmh5: false,
				wxconfigError: false, //微信配置出错标识
				info: null,
				showjy: false,
				jylink: "",
				cardType: null,
				noticeText: "",
				didisource: "",
				didipath: "",
				didih5: "",
				showNotice: false,
				showdlg: false,
				kfcurl: "http://kfc.quhaodian.cn/diancan/new.html?v=1.1&platmnt=yiluxue8&notify_url=http://api.zhsyh.cn/callback/kfc_ylx",
				mdlurl: "https://ot.jfshou.cn/api/mcdonald/entrance?share_code=6492aNymP&sign=43F779929D7AEC94CCEC1027F2CD7C5A&timestamp=1625386586546",
			}
		},
		props: {

		},
		beforeMount() {

		},
		mounted() {

			// 获取卡类型，存本地，每个网络请求都带上这个参数
			this.cardType = urlParams.get('card_type')
			if (this.cardType) {
				hhstore.set('card_type', this.cardType)
			} else {
				this.cardType = hhstore.get('card_type')
			}

			if (!hhstore.get("token")) {
				// 微信登录失败，返回注册页面
				hhstore.set('login_back', "/home")
				this.$router.push('/register')
				return;
			}

			var self = this;
			var uid = hhstore.get("uid");
			var token = hhstore.get("token");
			this.post({
				url: "user/info",
				data: {
					"uid": uid,
					"token": token
				},
				success: function(data) {
					console.info(data)
					self.showdueDate = true;
					self.dueDate = data.expiration_time.split(" ")[0];
					self.kfcurl = self.kfcurl + "&buyer_phone=" + data.phone
					self.configmdl(data.phone); //拼接麦当劳链接
					self.info = data;
					hhstore.set("phone", data.phone)
					// 微信未授权
					if (!data.wxsq) {
						wxutils.auth("wx0ac531187e5fbb37");
					}

					if (data.type == 1) {
						var count = 0;
						for (var i = data.exp_cards.length - 1; i >= 0; i--) {
							if (!data.exp_cards[i].validate_time) {
								count++;
							}
						}
						if (count) {
							self.noticeText = "你有" + count + "份亲友卡待领取";
							self.showNotice = self.noticeText.length;
						}
					} else {
						self.noticeText = "只需￥4.9，即可升级成为正式会员";
						self.showNotice = self.noticeText.length;
					}

				},
				error: function(code, err) {
					Toast.fail(err);
					if (code == 92834 || code == 10002 || code == 10003) {
						hhstore.del("uid")
						hhstore.del("phone")
						hhstore.del("token")
						hhstore.set('login_back', "/home" + window.location.search)
						self.$router.push('/register')
					}
				}
			});

			this.post({
				url: "xjjy/geturl",
				data: {
					"uid": hhstore.get("uid"),
					"token": hhstore.get("token")
				},
				success: function(data) {
					self.showjy = true
					self.jylink = data
				},
				error: function(error) {
					console.info(error)
				}
			});

			this.post({
				url: "xjjy/generate_link_url",
				data: {
					"pid": "6857647814016838908",
				},
				success: function(data) {
					self.didisource = data.app_source
					self.didipath = data.link
				},
				error: function(error) {
					console.info(error)
				}
			});

			// h5的
			this.post({
				url: "xjjy/generate_link_url",
				data: {
					"pid": "6857647814016838908",
					"type": "h5",
				},
				success: function(data) {
					self.didih5 = data.link
				},
				error: function(error) {
					console.info(error)
				}
			});

			this.post({
				url: "wx/getSignPackage",
				data: {
					"url": window.location.href,
				},
				success: function(data) {
					self.wxconfig(data)
				},
				error: function(error) {
					self.showmtwmh5 = true
				}
			});

			// 小程序按钮3秒后还未显示，那么就显示h5按钮
			var wxInterval = setInterval(function() {
				clearInterval(wxInterval);
				if (self.$refs.wxLaunchBtn.offsetHeight < 1) {
					self.wxconfigError = true;
					self.showmtwmh5 = true;
				}
			}, 3000)

		},
		computed: {
			...mapState([])
		},
		methods: {
			...mapActions(['post']),
			// 微信配置
			wxconfig: function(data) {

				self = this;
				wx.config({
					debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印
					appId: data.appId, // 必填，公众号的唯一标识
					timestamp: data.timestamp.toString(), // 必填，生成签名的时间戳
					nonceStr: data.nonceStr, // 必填，生成签名的随机串
					signature: data.signature, // 必填，签名
					jsApiList: ['getLocation'], // 必填，需要使用的JS接口列表
					openTagList: ['wx-open-launch-weapp'] // 可选，需要使用的开放标签列表，例如['wx-open-launch-app']
				});

				wx.ready(function() {
					// config信息验证后会执行ready方法，所有接口调用都必须在config接口获得结果之后，config是一个客户端的异步操作，所以如果需要在页面加载时就调用相关接口，则须把相关接口放在ready函数中调用来确保正确执行。对于用户触发时才调用的接口，则可以直接调用，不需要放在ready函数中
					console.info("ready")
					if (!self.wxconfigError) {
						self.showmtwmh5 = false
					}
				});

				wx.error(function(res) {
					// config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名
					console.info(res)
					self.wxconfigError = true
					self.showmtwmh5 = true
				});
			},
			kfcclick() {
				help.click_log('肯德基', this.kfcurl);
				window.location.href = this.kfcurl
			},

			barclick(Event) {
				if (this.info.type == 1) {
					this.$router.push('/expcards')
				} else {
					//升级逻辑
					Dialog.confirm({
							title: '升级会员',
							message: '4.9元即可升级成为正式会员，正式会员享有2年有效期，并赠送3张亲友卡！',
							confirmButtonText: '立即购买'
						})
						.then(() => {
							this.onbuy()
						})
						.catch(() => {
							// on cancel
						});
				}
			},
			onbuy: function() {
				var url = "wx/xiadan";
				var self = this;
				this.post({
					url: url,
					data: {
						"goods_id": 3,
						"uid": hhstore.get("uid"),
						"token": hhstore.get("token")
					},
					success: function(data) {
						self.dobuy(data)
					},
					error: function(code, err) {
						if (code == 10002) { //登录失效
							hhstore.del("uid")
							hhstore.del("phone")
							hhstore.del("token")
							self.$router.push('/register')
						}
						if (code == 91607) { //微信未授权
							hhstore.set('auth_back', "/home")
							self.$router.push('/auth')
						}
					}
				});
			},
			dobuy(data) {
				var self = this;
				WeixinJSBridge.invoke('getBrandWCPayRequest', data,
					function(res) {
						if (res.err_msg == "get_brand_wcpay_request:ok") {
							// 使用以上方式判断前端返回,微信团队郑重提示：
							//res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
							Toast.success("购买成功");

							// 1秒后刷新页面
							setTimeout(function() {
								self.$router.go(0);
							}, 1000);
						} else {
							Toast.fail("购买失败");
						}
					}
				);
			},
			mdlclick() {
				help.click_log('麦当劳', this.mdlurl);
				window.location.href = this.mdlurl
				return
				let timestamp = Math.round(new Date().getTime() / 1000).toString();
				let appkey = "1002181102";
				let appSecret = "dee35c44b6820a9a478a284e6c268604";
				let phone = hhstore.get("phone") ? hhstore.get("phone") : timestamp;

				let beforeaes = JSON.stringify({
					openId: phone,
					nickname: phone
				})
				console.info("aes前：" + beforeaes)

				let encryptedData = aes.encrypt(beforeaes, appkey, appSecret)
				console.info("aes后：" + encryptedData)

				let params = "appkey=" + appkey + "|encryptedData=" + encodeURIComponent(encryptedData) + "|timestamp=" +
					timestamp;
				console.info("第一次md5前：" + params);

				let paramsmd5 = md5(params);
				console.info("第一次md5后：" + paramsmd5);

				let beforesign = paramsmd5 + "&" + appSecret;
				console.info("签名前：" + beforesign);

				let sign = md5(beforesign)
				console.info("签名后：" + sign);

				let urlparams = "appkey=" + appkey + "&encryptedData=" + encodeURIComponent(encryptedData) +
					"&timestamp=" + timestamp + "&sign=" + sign;
				console.info(urlparams);

				let url = "https://h5.youhuiduovip.cn/pages/modules/mcd/index/index?" + urlparams;
				console.info(url);

				window.location.href = url;
			},
			configmdl(phone) {
				let timestamp = new Date().getTime();
				let shareCode = "6492aNymP";
				let s = "408957BF28AD4A84B359D45D0DDCE8EE";

				let beforesign = "shareCode=" + shareCode + "&timestamp=" + timestamp + "&userId=" + phone +
					"&secretKey=" + s;
				console.info(beforesign);

				let sign = md5(beforesign).toUpperCase();
				console.info(sign);

				let urlparams = "share_code=" + shareCode + "&timestamp=" + timestamp + "&user_id=" + phone + "&sign=" +
					sign;
				console.info(urlparams);

				this.mdlurl = "https://ot.jfshou.cn/api/mcdonald/entrance?" + urlparams;
				console.info(this.mdlurl);
			},
			dialogclose(action, done) {

				if (action == "cancel") done();

				if (action == "confirm") {
					var self = this;
					this.post({
						url: "user/upgrade",
						data: {
							"uid": hhstore.get("uid"),
							"token": hhstore.get("token"),
							"card_number": self.card_number.trim(),
							"card_secret": self.card_secret.trim(),
						},
						success: function(data) {
							Toast.success("延期成功");
							self.dueDate = data.expiration_time.split(" ")[0];
							done()
						},
						error: function(code, msg) {
							Toast.fail(msg);
							done(false)
						}
					});
				};

			},
		}
	}
</script>
<style scoped>
	.welcomediv {
		position: relative;
	}

	.dueDate {
		position: absolute;
		right: 0.5rem;
		bottom: 10px;
		color: #fff;
		font-size: 1rem;
		border-radius: 5px;
		border-color: #000;
		background-color: #C9B186;
		padding: 0px 5px;
	}


	.astyle {
		display: block;
	}

	.astyle>img {
		background-size: contain|cover;
		width: 100%;
		height: auto;
	}

	.wxbtn {
		display: block;
	}

	.topdiv {
		background-color: #c9b186;
		height: 25px;
	}
</style>
