<template>
	<div>
	</div>
</template>
<script>
	export default {
		name: 'list',
		data: function() {
			return {}
		},

		async mounted() {
			window.location.href = window.location.origin + '/list2' + window.location.search
		},
		methods: {

		}
	}
</script>
<style scoped>

</style>
