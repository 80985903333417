<template>
    <div class="formdiv">
        <div>手机号</div>
        <div>
            <input type="" name="" ref="phoneinput">
        </div>
        <div>卡号，一行一个卡号</div>
        <div>
            <textarea rows="3" cols="20" ref="cardinput"></textarea>
        </div>
        <div>卡密，一行一个卡密</div>
        <div>
            <textarea rows="3" cols="20" ref="secretinput"></textarea>
        </div>
        <div>
            <button v-on:click="btnclick">提交</button>
        </div>
    </div>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import { Toast } from 'vant';

export default {
    name: 'faka',
    data: function() {
        return {
            showdiv: false,
        }
    },
    props: {

    },
    beforeMount() {

    },
    mounted() {

    },
    computed: {
        ...mapState([])
    },
    methods: {
        ...mapActions(['post']),
        btnclick: function() {

            var url = "oldCard/faka";
            var self = this;
            this.post({
                url: url,
                data: {
                    "phone": this.$refs.phoneinput.value,
                    "card_number": this.$refs.cardinput.value,
                    "card_secret": this.$refs.secretinput.value,
                },
                success: function() {
                    Toast.success("发送成功");
                },
                error: function(code, err) {
                    Toast.fail(err);
                }
            });
        }
    }
}
</script>
<style scoped>
.formdiv {
    width: 60vw;
    margin: auto;
}

textarea {
    width: 100%;
    height: 8rem;
}

input {
    width: 50%;
}

button {
    width: 100%;
    height: 3rem;
    margin-top: 1rem;
}
</style>