<template>
	<div class="formdiv">
		<div>短信历史记录查询：</div>
		<div class="form">

			<div class="yzm">
				<input class="shouji" type="" name="" v-model="phone" placeholder="请输入手机号">
				<div v-if="!ic" class="msg_btn" v-on:click="getcode" :class="buttonclass">{{buttonText}}</div>
			</div>

			<div class="yzm">
				<input v-if="!ic" class="msg" name="phonecode" type="number"
					oninput="if(value.length>4)value=value.slice(0,4)" placeholder="请输入验证码" v-model="code">
				<button class="chaxun" @click="btnclick">查询</button>
			</div>
		</div>

		<div v-show="showContent" class="smscontent">
			<div v-if="datas.length==0">查无记录</div>
			<div class="item" v-for="(data, index) in datas" :key="index">
				<div class="time">发送时间：{{data.time1}}</div>
				<div class="content">{{data.msg}}</div>
			</div>

		</div>
	</div>
</template>
<script>
	import validate from '../utils/Validate'
	import {
		mapActions,
		mapState
	} from 'vuex'
	import urlParams from '../utils/UrlParams'

	import {
		Toast
	} from 'vant';

	export default {
		name: 'list',
		data: function() {
			return {
				showContent: false,
				datas: [],
				buttonclass: "msg_btn",
				phone: '',
				code: '',
				ic: null,
				buttonText: "获取验证码",
			}
		},
		props: {

		},
		beforeMount() {

		},
		mounted() {
			this.ic = urlParams.get('ic')
		},
		computed: {
			...mapState([])
		},
		methods: {
			...mapActions(['post']),
			getcode() {
				if (this.buttonText != "获取验证码") {
					return;
				}

				if (!validate.phone(this.phone.trim())) {
					Toast.fail("手机号格式不正确");
					return;
				}


				var url = "sms/loginCode/" + this.phone.trim() + "/KDI3EN2DLS";
				if (process.env.NODE_ENV === "production")
					url = "sms/loginCode/" + this.phone.trim();

				this.post({
					url: url,
					data: {},
					success: () => {
						Toast.success("发送成功");
						var count = 60;
						this.buttonText = count + "s";
						this.buttonclass = "msg_btn_disable";
						this.intervalId = setInterval(() => {
							count--;
							if (count <= 0) {
								clearInterval(this.intervalId);
								this.buttonText = "获取验证码";
								this.buttonclass = "msg_btn";
							} else {
								this.buttonText = count + "s";
								this.buttonclass = "msg_btn_disable";
							}
						}, 1000);
					},
					error: function(code, err) {
						Toast.fail(err);
					}
				});
			},
			btnclick: function() {
				if (!validate.phone(this.phone.trim())) {
					Toast.fail("手机号格式不正确");
					return;
				}
				var url = "sms/clsms";

				this.post({
					url: url,
					data: {
						"phone": this.phone.trim(),
						"code": this.code.trim(),
						"ic": this.ic
					},
					success: (data) => {
						this.datas = data;
						this.showContent = true;
					},
					error: (code, err) => {
						Toast.fail(err);
					}
				});

			}
		}
	}
</script>
<style scoped>
	.formdiv {
		width: 90vw;
		margin: auto;
		margin-top: 1.5rem;
	}

	.item {
		border-bottom: #000000;
		border-width: 0.0625rem;
		border-style: solid;
		margin-top: 0.625rem;
		margin-bottom: 0.625rem;
	}

	.time {
		color: #999999;
		font-size: 0.875rem;
	}


	.smscontent {
		overflow-y: scroll;
	}

	.msg_btn {
		width: 20vw;
		height: 1.875rem;
		line-height: 1.875rem;
		color: #333;
		font-size: 0.9rem;
		text-align: center;
	}

	.msg_btn_disable {
		width: 20vw;
		height: 1.875rem;
		line-height: 1.875rem;
		font-size: 0.9rem;
		text-align: center;
		color: #888;
	}

	.msg {
		width: 50vw;
	}

	.yzm {
		display: flex;
		margin-top: 0.3125rem;
	}

	.chaxun {
		margin-left: 0.3125rem;
		width: 4.375rem;
	}

	.shouji {
		width: 50vw;
	}
</style>
