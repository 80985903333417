<!-- 券码页面 -->
<template>
	<div class="bg">
		<div class="content">
			<div class="top">
				<div><img :src="info.img" /></div>
				<div class="top-title">{{ info.title }}</div>

				<div class="top-price">
					{{ formatRMB(info.price / 100) }}
					<span class="top-yuanjia">市场价{{
						formatRMB(info.original_price / 100)
					}}</span>
				</div>

			</div>

			<div class="fenge"></div>
			<div>
				<div class="desc-title">产品描述</div>
				<div class="top-xuzhi" v-html="info.xuzhi_html"></div>
			</div>
			<div style="height: 20px;"></div>
		</div>

		<div class="btndiv">
			<button class="btndivleft" v-on:click="onorder()">我的订单</button>
			<button class="btndivright" v-on:click="onbuy()">立即抢购</button>
		</div>

		<LoginPopup :showLogin.sync="showLogin"></LoginPopup>
		<div class="swipe-top">
			<p @click="onback" data-v-80f078c8="" class="swipe-top-left"><i data-v-80f078c8=""
					class="van-icon van-icon-arrow-left" style="color: rgb(255, 255, 255); font-size: 22px;"><!----></i></p>
		</div>
	</div>
</template>
<script>
import {
	mapActions,
	mapState
} from 'vuex'
import validate from '../utils/Validate'
import LoginPopup from '../components/LoginPopup.vue'

import hhstore from '../utils/HHStore'
import urlParams from '../utils/UrlParams'
import {
	Toast,
	Dialog
} from 'vant';
import { setTimeout } from 'timers';
import help from '../utils/Help'

/* 用来微信授权用 ，授权完成后，根据  localStorage.auth_back 地址，跳回原网址 */

export default {
	name: 'Pay',
	components: {
		LoginPopup,
		[Dialog.Component.name]: Dialog.Component,
	},
	data: function () {
		return {

			showLogin: false,

			goods_id: 5,
			showDialog: false,
			info: {},
			kvs: {},


			img: "",

		}
	},
	props: {

	},
	beforeMount() {

	},
	mounted() {
		// 获取卡类型，存本地，每个网络请求都带上这个参数
		const cardType = urlParams.get('card_type')
		if (cardType) {
			hhstore.set('card_type', cardType)
		}

		// if (!hhstore.get("token")) {

		// 	return
		// }

		this.goods_id = urlParams.get('gid')

		this.getDetail()
	},
	beforeDestroy() {

	},
	computed: {
		...mapState([])
	},
	methods: {
		...mapActions(['post']),

		formatRMB(amount) {
			return help.formatRMB(amount)
		},
		getDetail() {

			this.post({
				url: "starbucks/qm_goods_info",
				data: {
					goods_id: this.goods_id,
					"uid": hhstore.get("uid"),
					"token": hhstore.get("token"),
				},
				success: (data) => {
					this.info = data
				},
				error: (code, err) => {
					Toast.fail(err);
				}
			});
		},


		onorder() {
			this.$router.push('/qmorder')
		},
		onbuy: function () {
			if (!hhstore.get("token")) {
				this.showLogin = true
				return
			}
			this.confirmonbuy()
		},
		confirmonbuy() {

			const data = {
				"goods_id": this.goods_id,

				"uid": hhstore.get("uid"),
				"token": hhstore.get("token"),

			};

			const UA = navigator.userAgent.toLowerCase()
			const inWechat = UA.match(/.*?(micromessenger\/([0-9.]+))\s*/)
			const inDingDing = UA.match(/.*?(dingtalk\/([0-9.]+))\s*/)

			if (inWechat) {
			} else if (inDingDing) {
			} else {

				var url = "wx/qm_xiadan_h5";
				this.post({
					url: url,
					data: data,
					success: (data) => {
						window.location.href = data.pay_info.h5_url;
						setTimeout(() => {
							Dialog.confirm({
								title: '提示',
								message: '已经支付完成？',
								cancelButtonText: '未完成',
								confirmButtonText: '已完成'

							})
								.then(() => {
									Dialog.close()
									this.$router.push("/qmorder")
								})
								.catch(() => {
									// on cancel
								});
						}, 1000);


					},
					error: (code, err) => {
						if (code == 10002) {
							this.showLogin = true
						} else {
							Toast.fail(err)
						}
					}
				});

			}
		},
		onback() {
			this.$router.go(-1)
		},
		dobuy(data) {
			var self = this;
			WeixinJSBridge.invoke('getBrandWCPayRequest', data,
				function (res) {
					if (res.err_msg == "get_brand_wcpay_request:ok") {
						// 使用以上方式判断前端返回,微信团队郑重提示：
						//res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
						Toast.success("购买成功");
						setTimeout(function () {
							self.$router.push('/qmorder')
						}, 3000);

					} else {
						Toast.fail("购买失败");
					}
				}
			);
		},
		dobuymini(data) {

			let payInfo = data.pay_info
			payInfo.redirectUrl = window.location.origin + `/qmorder`;
			wx.miniProgram.navigateTo({
				url: '/pages/payment/payment?key=' + encodeURIComponent(JSON.stringify(payInfo))
			}); // 跳转到小程序支付页面

		}
	}
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
body {
	text-align: center;

}

.bg {
	background-color: #fff;
	height: 100vh;
}

.btndiv {
	position: absolute;
	bottom: 0px;
	left: 0px;
}

.btndivleft {
	width: 50vw;
	height: 4rem;
	background-color: #ffffff;
	border-width: 0px;
	color: #333333;
	font-size: 1rem;
}

.btndivright {
	width: 50vw;
	height: 4rem;
	background-color: #4F4F4F;
	border-width: 0px;
	color: #B2ACA3;
	font-size: 1rem;
}

.top {
	display: flex;
	flex-direction: column;
}

.top img {

	width: 100vw;
	height: 56vw;
	float: left;
}

.top span {
	margin-left: 0.3rem;
}

.haoma {
	clear: both;
	font-size: 0.76rem;
	margin-top: 1.6rem;
	margin-left: 0.93rem;
}

.ipt {
	font-size: 1.32rem;
	margin-top: 0.625rem;
	margin-left: 0.93rem;
	margin-bottom: 0.9375rem;
}


.xuzhi {
	font-size: 0.83rem;
	margin-left: 0.93rem;
	background-color: #FCF7E9;
	color: #C7AC7C;
	width: 85vw;
	margin-top: 1rem;
	padding-right: 0.93rem;
	padding-top: 0.1rem;
	padding-bottom: 1rem;
	padding-left: 0.93rem;
}

.fenge {
	height: 0.53rem;
	background-color: #F5F5F5;
	margin-top: 0.8rem;
}

.miane {
	margin-left: 0.93rem;
	margin-top: 0.6rem;
}

.mianeitem {
	float: left;
	/*height: 6.66rem;
    width: 6.66rem;;*/
	padding-left: 1rem;
	padding-right: 1rem;
	padding-top: 1rem;
	padding-bottom: 1rem;
	text-align: center;
	margin-left: 0.5rem;
	margin-bottom: 0.5rem;
	border-radius: 5px;
	border-width: 1px;
	border-color: #cccccc;
	border-style: solid;
	font-size: 0.66rem;
}

.mianeselect {
	border-color: #E7D1B5;
	box-shadow: #FCF7E9 0px 0px 3px 3px;
	color: #CF7055;
	background-color: #FDFCF7;
}

.mianeiteml1 {
	font-size: 0.875rem;

	/*margin-top: 1.36rem;*/
}

.mianeiteml2 {
	font-size: 0.86rem;
	margin-top: 0.33rem;
	color: #2c3e50;
}

.mianeiteml3 {
	margin-top: 0.33rem;
	text-decoration: line-through;
	color: #c1c1c1;
}

.clearfix:before,
.clearfix:after {
	content: "";
	display: table;
}

.clearfix:after {
	clear: both;
}

.clearfix {
	zoom: 1
}

pre {
	white-space: pre-wrap;
	/*css-3*/
	white-space: -moz-pre-wrap;
	/*Mozilla,since1999*/
	white-space: -pre-wrap;
	/*Opera4-6*/
	white-space: -o-pre-wrap;
	/*Opera7*/
	word-wrap: break-word;
	/*InternetExplorer5.5+*/
}

.content {
	overflow: scroll;
	height: calc(100vh - 4rem);
	height: -webkit-calc(100vh - 4rem);
	height: -moz-calc(100vh - 4rem);
}

.dialog-title {
	color: white;
	height: 3.375rem;
	line-height: 3.375rem;
	text-align: center;
	font-size: 0.875rem;
	background-image: linear-gradient(to right, #F1A259, #ED6B80);
}

.dialog-text {
	margin: 0rem 0.8125rem 0.625rem 0.8125rem;
	font-size: 0.8125rem;
}

.dialog-text2 {
	font-size: 1.125rem;
	color: red;
	font-weight: bold;
}

.dialog-text3 {
	height: 2.75rem;
	background-color: #F6F6F6;
	border-radius: 1.375rem;
	margin-left: 0.8125rem;
	margin-right: 0.8125rem;
	margin-top: 1.25rem;
	margin-bottom: 1.25rem;
	text-align: center;
	line-height: 2.75rem;
	font-size: 1.375rem;
}
</style>
<style>
.ql-size-small {
	font-size: 0.75em;
}

.ql-size-large {
	font-size: 1.5em;
}

.ql-size-huge {
	font-size: 2.5em;
}

.ipt input {
	border-width: 0px;
	border-bottom-width: 1px;
	background-color: rgba(0, 0, 0, 0);
	height: 2.5rem;
	border-bottom-color: #c1c1c1;
	color: #333;
}

.top-title {
	font-weight: 700;
	font-size: 4.5333333333vmin;
	line-height: 6.1333333333vmin;
	padding: 0 4.2666666667vmin;
	box-sizing: border-box;
	/* margin-bottom: 3.2vmin; */
	color: #222;
	margin-top: 10px;
	margin-bottom: 3.2vmin;
}

.top-price {
	align-items: center;
	background: #f9f9f9;
	margin: 0 4.2666666667vmin;
	border-radius: 2.6vmin;
	color: #fb6046;
	font-size: 6.4vmin;
	font-family: DIN, Arial, sans-serif;
	padding: 15px;
}

.top-yuanjia {
	font-size: 3.4666666667vmin;
	text-decoration: line-through;
	color: #aaa;
}

.top-xuzhi {
	margin: 20px 4.2666666667vmin;
}

.desc-title {
	margin-left: 4.2666666667vmin;
	background-color: #fff;
	color: #222;
	font-size: 4vmin;
	line-height: 13.3333333333vmin;
	border-bottom: 1px solid #e5e5e5;
}

.swipe-top {
	position: absolute;
	top: 3.2vmin;
	left: 0;
	z-index: 9;
	display: flex;
	justify-content: space-between;
	box-sizing: border-box;
	width: 100%;
	padding: 0 3.4666666667vmin;
}

.swipe-top-left {

	width: 8vmin;
	height: 8vmin;
	background: rgba(0, 0, 0, .5);
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
}
</style>
