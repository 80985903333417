<template>
    <div>
        <van-list>
          <van-cell v-for="(item,index) in list" 
          :id="index" 
		  :key="index"
          data-clipboard-action="copy" 
          :class="'cell' + index" 
          :data-clipboard-text="item.copy_text"
          :label="item.card_form==2?'亲友卡使用成功后，您的会员有效期同样会延长3个月！':'致敬女神，给女神一份惊喜！'" 
          @click="cellclick" 
          :title="item.card_form==2?'亲友卡(有效期3个月，限新用户)':'女神卡(有效期2年，限新用户)'" 
          :value="item.validate_time?'已使用':'点击复制'" />
        </van-list>
    </div>
</template>
<script>
	
import { mapActions, mapState } from 'vuex'
import validate from '../utils/Validate'
import hhstore from '../utils/HHStore'
import Vue from 'vue';
import { Toast } from 'vant';
import { List } from 'vant';
import { Cell, CellGroup } from 'vant';
import Clipboard from 'clipboard';
import help from '../utils/Help'

Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(List);

export default {
    name: 'expcards',
    data: function() {
        return {
            list:[]
        }
    },
    props: {

    },
    beforeMount() {

    },
    mounted() {

        if (!hhstore.get("token")) {
            hhstore.set('login_back', "/expcards"+window.location.search)
            this.$router.push('/register')
            return
        }

        var self = this;
        var uid = hhstore.get("uid");
        var token = hhstore.get("token");
        this.post({
            url: "user/exp_cards",
            data: {
                "uid": uid,
                "token": token
            },
            success: function(data) {
                console.info(data)
                self.list = data;
            },
            error: function(code, err) {
                Toast.fail(err);
                if (code == 92834 || code == 10002 || code == 10003) {
                    hhstore.del("uid")
                    hhstore.del("phone")
                    hhstore.del("token")
                    hhstore.set('login_back', "/expcards"+window.location.search)
                    self.$router.push('/register')
                }
            }
        });
    },
    beforeDestroy() {
    },
    computed: {
        ...mapState([])
    },
    methods: {
        ...mapActions(['post']),
        cellclick(Event){
            let card = this.list[Event.currentTarget.id];
            if (!card.validate_time) {
                const clipboard = new Clipboard('.cell'+Event.currentTarget.id);
                clipboard.on('success', e => {
                    Toast.success("卡密已复制到剪贴板！")
                });

                clipboard.on('error', function () {
                   Toast.fail("失败")
                });
            }

            

        }
        
    }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>