<template>
	<div class="content">
		<div class="hybtncontent">
			<img src="http://img.123zhs.com/h5/hy/hyinfo.jpg" class="hyinfo"/>
			<div class="hybtn" @click="btnclick"></div>
		</div>
		
	</div>
</template>

<script>
	import {
		mapActions,
		mapState,
		store
	} from 'vuex'
	import hhstore from '../utils/HHStore'
	import help from '../utils/Help'

	export default {
		data() {
			return {
				
			}
		},
		async mounted() {
			
		},

		onShow() {},
		methods: {
			...mapActions(['post', 'syncpost']),
			btnclick() {
				this.$router.push('/activate')
			},
		}
	}
</script>

<style scoped>
	.hyinfo {
		width: 100%;
		display: block;
	}
	.hybtn{
		background-color: rgba(100, 100, 100, 0);
		width: 50vw;
		height: 5rem;
		position: absolute;
		bottom: 2rem;
		right: 0px;
		
	}
	.hybtncontent{
		position: relative;
	}
	
</style>
