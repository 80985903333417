<template>
	<div class="content">

		<div class="hycontent">
			<input type="text" name="nickname" v-model="nickname" class="nickinput">
			<input type="text" name="phone" v-model="account" class="phoneinput">
			<button v-on:click="onbuy()" class="hybtn"></button>
			<img :src="hybg" class="hybg" />
		</div>
	</div>
</template>

<script>
	import {
		mapActions,
		mapState,
		store
	} from 'vuex'

	import urlParams from '../utils/UrlParams'
	import hhstore from '../utils/HHStore'
	import help from '../utils/Help'
	import validate from '../utils/Validate'
	import {
		Toast,
		Dialog
	} from 'vant';
	export default {
		data() {
			return {
				account: "",
				wechat_id: "",
				wx_appid: 'wx1a5b1b2a6bd07ea0',
				nickname: '',
				hybg: ""
			}
		},
		async mounted() {

			let ch = urlParams.get('card_type')
			if (ch) {
				hhstore.set('card_type', ch)
			}

			let cardType = hhstore.get('card_type')
			if (cardType == 'elrywo') {
				this.hybg = "http://img.123zhs.com/h5/hy/elrywo.jpg"
			} else {
				this.hybg = "http://img.123zhs.com/h5/hy/hy4.png"
			}

			const nickname = urlParams.get('nickname')
			if (nickname) {
				this.nickname = nickname
			}



			const account = urlParams.get('account')
			if (account) {
				this.account = account
				this.complete()
			}
			this.wxauth()
		},

		onShow() {},
		methods: {
			...mapActions(['post', 'syncpost']),
			wxauth() {
				const UA = navigator.userAgent.toLowerCase()
				const inWechat = UA.match(/.*?(micromessenger\/([0-9.]+))\s*/)

				if (!inWechat) return

				if (!urlParams.get('code')) {
					window.location.href = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" + this.wx_appid +
						"&redirect_uri=" + encodeURIComponent(window.location.origin + window.location.pathname + window
							.location.search) + "&response_type=code&scope=snsapi_base#wechat_redirec";
					return
				} else {
					var data = {
						"code": urlParams.get('code'),
					};
					this.post({
						url: 'wx/code2openid_vip',
						data: data,
						success: (data) => {
							this.wechat_id = data.wechat_id
						},
						error: (code, err) => {
							Toast.fail(err);
						}
					});

				}
			},
			onbuy() {
				var username = this.nickname.trim()
				if (!username) {
					Toast.fail('请填写正确的昵称')
					return
				}

				var phone = this.account.trim()
				if (!phone) {
					Toast.fail('请填写正确的手机号')
					return
				}

				if (!validate.phone(phone)) {
					Toast.fail("手机号格式不正确");
					return;
				}

				Dialog.confirm({
						title: '提示',
						message: '确定激活手机号是：' + phone + ' 吗？',
					})
					.then(() => {
						const data = {
							'account': phone,
							'nickname': username
						}

						const UA = navigator.userAgent.toLowerCase()
						const inWechat = UA.match(/.*?(micromessenger\/([0-9.]+))\s*/)

						if (inWechat) {
							data['wechat_id'] = this.wechat_id

							var url = "wx/xiadan_vip";
							var self = this;
							this.post({
								url: url,
								data: data,
								success: function(data) {
									self.dobuy(data)
								},
								error: function(code, err) {
									Toast.fail(err);
								}
							});
						} else {
							var url = "wx/xiadan_h5_vip";
							var self = this;
							this.post({
								url: url,
								data: data,
								success: (data) => {
									let return_url = encodeURIComponent(window.location.origin + window
										.location
										.pathname + window.location.search + (window.location.search ?
											"&" : "?") +
										'account=' + this.account.trim() +
										'&nickname=' + this.nickname.trim())
									window.location.href = data.pay_info.h5_url + '&redirect_url=' +
										return_url;
								},
								error: (code, err) => {
									Toast.fail(err)
								}
							});
						}
					})
					.catch(() => {
						// on cancel
					});


			},
			dobuy(data) {
				WeixinJSBridge.invoke('getBrandWCPayRequest', data,
					(res) => {
						if (res.err_msg == "get_brand_wcpay_request:ok") {
							// 使用以上方式判断前端返回,微信团队郑重提示：
							//res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
							this.complete()
						} else {
							Toast.fail("购买失败");
						}
					}
				);
			},
			complete() {
				Toast.loading({
					message: '确认支付结果...',
					forbidClick: true,
					duration: 0
				});
				var count = 0
				var self = this
				var intervalHandel = setInterval((function handler() {
					console.info(count)
					var url = "wx/check_pay_vip";
					if (count > 2) {
						clearInterval(intervalHandel)
						Toast.clear()
						return
					}

					count++

					self.post({
						url: url,
						data: {
							account: self.account.trim()
						},
						success: (data) => {
							Toast.clear()
							hhstore.set("uid", data.uid)
							hhstore.set("token", data.token)
							hhstore.set("phone", self.account.trim())
							clearInterval(intervalHandel)
							Toast.success('帐号激活成功')
							self.$router.push('/products')
						},
						error: (code, err) => {

						}
					});
					return handler
				})(), 3000)
			},
			dobuymini(data) {

				let payInfo = data.pay_info
				payInfo.redirectUrl = window.location.origin + `/order`;
				wx.miniProgram.navigateTo({
					url: '/pages/payment/payment?key=' + encodeURIComponent(JSON.stringify(payInfo))
				}); // 跳转到小程序支付页面

			}
		}
	}
</script>

<style scoped>
	.hybg {
		width: 100%;
		display: block;
	}

	.hycontent {
		position: relative;
	}

	.phoneinput {
		position: absolute;
		top: 32.3rem;
		left: 8rem;
		height: 2rem;
		width: 12rem;
		background-color: rgba(0, 0, 0, 0);
		border: none;
		-webkit-appearance: none;
		/*去除阴影边框*/
		outline: none;
		-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
		/*点击高亮的颜色*/
		color: #ffffff;

	}

	.nickinput {

		position: absolute;
		top: 27.3rem;
		left: 8rem;
		height: 2rem;
		width: 12rem;
		background-color: rgba(0, 0, 0, 0);
		border: none;
		-webkit-appearance: none;
		/*去除阴影边框*/
		outline: none;
		-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
		/*点击高亮的颜色*/
		color: #ffffff;
	}

	.hybtn {
		position: absolute;
		border: none;
		-webkit-appearance: none;
		/*去除阴影边框*/
		outline: none;
		-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
		/*点击高亮的颜色*/
		background-color: rgba(100, 100, 100, 0);

		width: 50vw;
		height: 5rem;

		bottom: 2rem;
		right: 0px;
	}
</style>
