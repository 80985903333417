<template>
    <div>
        <img  v-show="!showQr" class="c-img" :src="imgsrc">
        <div v-show="showQr" id="qrcode">
            <vue-qr :text="qrcode" :size="300"></vue-qr>
        </div>
        
    </div>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import validate from '../utils/Validate'
import hhstore from '../utils/HHStore'
import { Toast } from 'vant';
import vueQr from 'vue-qr'
export default {
    name: 'register',
    data: function() {
        return {
            imgsrc:"",
            showQr:false,
            qrcode:"dsafsafd"
        }
    },
    components: {
      vueQr
    },
    props: {

    },
    beforeMount() {

    },
    mounted() {
        self = this
        this.post({
            url: "starbucks/code2img"+(window.location.pathname.replace("starbucks/","")),
            data: {
                "url": window.location.href,
            },
            success: function(data) {
                if (data.qrcode) {
                    self.showQr = true;
                    self.qrcode = data.qrcode
				} else if (data.link) {
					window.location.href = data.link
                }else{
                    self.showQr = false;
                    self.imgsrc = data.url  
                }
            },
            error: function(code,error) {
                Toast.fail(error);
            }
        });
        
    },
    beforeDestroy() {

    },
    computed: {
        ...mapState([])
    },
    methods: {
        ...mapActions(['post']),    
    }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .c-img{
        width: 100%;
    }

    #qrcode{
        text-align: center;
        position: relative;
    }

    /*为什么没有效果*/
    #qrcode > img{
        width: 100%;
        margin: auto;
    }

</style>






