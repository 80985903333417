<template>
	<div class="container">
		<div class="logo" @click="login">
			<div class="logo-left">
				<img class="logo-img" src="http://img.123zhs.com/mp-qyw/img/default_avatar.png"></img>
				<div class="user-name">Hi，{{hasLogin ? userName : '您还未登录'}}</div>
			</div>

			<img v-if="!hasLogin" src="http://img.123zhs.com/mp-qyw/img/arrow-right-white.png" class="rightarrow" />
		</div>
		<van-cell-group title=" ">
			<van-cell :title="dueDate" is-link size="large" icon="http://img.123zhs.com/mp-qyw/img/hy.png" v-if="hasLogin"
				@click="showdlg = true" />
			<van-cell v-if="hasLogin && homepage == '/list2'" title="续费特惠【2年2.9】" is-link size="large" icon="http://img.123zhs.com/h5/hy/icon.jpg"
				@click='hydlgclick'>
			</van-cell>
			<van-cell title="在线客服" is-link size="large" icon="http://img.123zhs.com/mp-qyw/img/service.png"
				@click="openkefu" />
		</van-cell-group>

		<van-cell-group v-if="hasLogin && homepage == '/list2'" title=" ">
			
			<van-cell title="领取和赠送" is-link size="large" icon="http://img.123zhs.com/mp-qyw/img/gift.png"
				@click='giftclick'>
				<template #default>
					<div v-show="dot.me_qinyou" class="dot"></div>
				</template>
			</van-cell>
		</van-cell-group>

		<van-dialog title="输入新权益卡，会员延期2年" v-model="showdlg" show-cancel-button confirmButtonText="确定"
			:before-close="dialogclose">
			<van-form>
				<van-field v-model="card_number" name="卡号" label="卡号" placeholder="10位的卡号" style="font-size: 16px;" />
				<van-field v-model="card_secret" name="激活码" label="激活码" placeholder="6位的激活码" style="font-size: 16px;" />
			</van-form>
		</van-dialog>

		<van-cell-group title=" " v-if="hasLogin && homepage == '/list2'">
			<van-cell title="退出登录" is-link size="large" icon="http://img.123zhs.com/mp-qyw/img/logout.png"
				@click='logout' />
		</van-cell-group>


	</div>
</template>
<script>
	import {
		mapActions,
		mapState,
		store
	} from 'vuex'
	import hhstore from '../utils/HHStore'
	import urlParams from '../utils/UrlParams'
	import wxutils from '../utils/Wx'
	import {
		Cell,
		CellGroup,
		Dialog,
		Toast
	} from 'vant';
	import Vue from 'vue';

	import aes from '../utils/Aes'
	var md5 = require('md5')

	export default {
		name: 'list',
		components: {
			[Dialog.Component.name]: Dialog.Component,
			[Cell.name]: Cell,
			[CellGroup.name]: CellGroup,
		},
		computed: {
			...mapState(['homepage', 'kefu', 'nnl', 'dot']),
		},
		data: function() {
			return {
				card_number: '',
				card_secret: '',
				showdlg: false,
				hasLogin: false,
				userName: "",
				dueDate: ""
			}
		},
		props: {

		},
		beforeMount() {

		},
		async mounted() {
			var uid = hhstore.get("uid");
			var token = hhstore.get("token");
			this.hasLogin = !!uid

			this.userinfo()
		},

		methods: {
			...mapActions(['post', 'syncpost']),

			userinfo() {
				var uid = hhstore.get("uid");
				var token = hhstore.get("token");

				if (!uid) {
					return;
				}

				this.post({
					url: "user/info",
					data: {
						"uid": uid,
						"token": token
					},
					success: (data) => {
						console.info(data)
						hhstore.set("phone", data.phone)
						this.userName = data.phone
						this.dueDate = "会员有效期至 " + data.expiration_time.split(" ")[0];

					},
					error: (code, err) => {
						Toast.fail(err);
						if (code == 92834 || code == 10002 || code == 10003) {
							hhstore.del("uid")
							hhstore.del("phone")
							hhstore.del("token")
							this.hasLogin = false
						}
					}
				});
			},
			giftclick() {
				console.info();
				this.$router.push('/expcards')
			},
			logout() {
				Dialog.confirm({
						title: '退出登录',
						message: '确定退出登录吗？',
					})
					.then(() => {
						Toast.loading({
							message: '登出中...',
							forbidClick: true,
							duration: 0
						});
						var uid = hhstore.get("uid");
						var token = hhstore.get("token");
						this.post({
							url: "user/logout",
							data: {
								"uid": uid,
								"token": token
							},
							success: (data) => {
								Toast.clear()
								hhstore.del("uid")
								hhstore.del("phone")
								hhstore.del("token")
								this.hasLogin = false
							},
							error: (code, err) => {
								Toast.clear()
								Toast.fail(err);
							}
						});
					})
					.catch(() => {
						// on cancel
					});
			},
			login() {
				if (this.hasLogin) return
				if (this.nnl) {
					this.$router.push('/login')
				} else {
					this.$router.push('/register')
				}
			},
			dialogclose(action, done) {

				if (action == "cancel") done();

				if (action == "confirm") {
					this.post({
						url: "user/upgrade",
						data: {
							"uid": hhstore.get("uid"),
							"token": hhstore.get("token"),
							"card_number": this.card_number.trim(),
							"card_secret": this.card_secret.trim(),
						},
						success: (data) => {
							Toast.success("延期成功");
							this.dueDate = "会员有效期至 " + data.expiration_time.split(" ")[0];
							done()
						},
						error: (code, msg) => {
							Toast.fail(msg);
							done(false)
						}
					});
				};

			},
			openkefu() {
				if (this.kefu && this.homepage == '/products') {
					window.location.href = this.kefu
				} else {
					window.location.href =
						"https://yzf.qq.com/xv/web/static/chat/index.html?sign=37ef9b97db7301c52b10cfec1ee0b56220793a106493e0135b717919be7d8d2ee50429de80135ec11d6fb487ad06bf9f6a393e20"
				}

			},
			hydlgclick() {
				this.$router.push('/actqy')
			},
		}
	}
</script>
<style scoped>
	.logo {
		height: 7.5rem;
		padding: 0.625rem;
		box-sizing: border-box;
		background-color: #434047;
		flex-direction: row;
		align-items: center;
		display: flex;
		justify-content: space-between;
		/* justify-content: center; */
	}

	.logo-img {
		width: 3.75rem;
		height: 3.75rem;
		border-radius: 1.875rem;
	}

	.rightarrow {
		width: 0.625rem;
		height: 0.625rem;
		margin-right: 0.625rem;
	}

	.user-name {
		color: white;
		margin-left: 0.625rem;
	}

	.logo-left {
		display: flex;
		align-items: center;
	}

	.dot {
		width: 6px;
		height: 6px;
		background-color: #DA3131;
		border-radius: 3px;

	}
</style>
<style>
	.van-cell__value {
		display: flex;
		flex-direction: row-reverse;
		align-items: center;
	}
</style>
