<template>
	<div>
		<div v-show="yindao" class="yindao">
			<img src="http://img.123zhs.com/h5/act.png" />
		</div>
		<div v-show="!yindao">
			<div class="avtive_top">
				<span class="active_toptitle">权益卡</span>
			</div>
			<div class="active_list">
				<div class="active_item rows">
					<div class="active_title">手机号</div>
					<div class="input_div rows">
						<input type="text" class="mobile" name="phone" placeholder="您注册使用的手机号码" ref="phoneinput">
					</div>
				</div>
				<div class="active_item rows">
					<div class="active_title">短信验证码</div>
					<div class="input_div input_divs rows">
						<input class="msg" name="phonecode" type="number"
							oninput="if(value.length>4)value=value.slice(0,4)" placeholder="请输入验证码" ref="codeinput">
					</div>
					<div class="msg_btn" v-on:click="getcode" :class="buttonclass">{{buttonText}}</div>
				</div>
			</div>
			<div class="submit handle" v-on:click="submit">激活权益</div>
		</div>

	</div>
</template>
<script>
	import {
		mapActions,
		mapState
	} from 'vuex'
	import validate from '../utils/Validate'
	import hhstore from '../utils/HHStore'
	import {
		Toast
	} from 'vant';
	import urlParams from '../utils/UrlParams'

	export default {
		name: 'register',
		data: function() {
			return {
				yindao: false,
				content: '',
				intervalId: "",
				buttonText: "获取验证码",
				buttonclass: "msg_btn",
				linkstyle: {
					color: "#8989d9",
				},
				linktext: "如已激活过，去手机号登录"
			}
		},
		props: {

		},
		beforeMount() {

		},
		mounted() {			
			this.yindao = !!hhstore.get("token") && !urlParams.get('half')
		},
		beforeDestroy() {
			clearInterval(this.intervalId);
		},
		computed: {
			...mapState([]),
		},
		methods: {
			...mapActions(['post']),
			getcode() {
				if (this.buttonText != "获取验证码") {
					return;
				}

				if (!validate.phone(this.$refs.phoneinput.value.trim())) {
					Toast.fail("手机号格式不正确");
					return;
				}

				var self = this;
				var url = "sms/loginCode/" + this.$refs.phoneinput.value.trim() + "/KDI3EN2DLS";
				if (process.env.NODE_ENV === "production")
					url = "sms/loginCode/" + this.$refs.phoneinput.value.trim();

				this.post({
					url: url,
					data: {},
					success: function() {
						Toast.success("发送成功");
						// self.$refs.inputText.blur();
						var count = 60;
						self.buttonText = count + "s";
						self.buttonclass = "msg_btn_disable";
						self.intervalId = setInterval(function() {
							count--;
							if (count <= 0) {
								clearInterval(self.intervalId);
								self.buttonText = "获取验证码";
								self.buttonclass = "msg_btn";
							} else {
								self.buttonText = count + "s";
								self.buttonclass = "msg_btn_disable";
							}
						}, 1000);
					},
					error: function(code, err) {
						Toast.fail(err);
					}
				});
			},
			submit() {


				Toast.loading({
					message: '请稍候...',
					forbidClick: true,
					duration: 0
				});
				var self = this;
				const phone = this.$refs.phoneinput.value.trim()
				let params = {
					"phone": phone,
					"card_type": "qyw",
					"desc": urlParams.get('desc') ? urlParams.get('desc') : "xiaodu",
					"code": this.$refs.codeinput.value.trim()
				}
				
				if (urlParams.get('half')) {
					params['half'] = urlParams.get('half')
				}
				
				this.post({
					url: "user/quick_register_code",
					data: params,
					success: (data) => {
						Toast.clear()
						hhstore.set("uid", data.uid)
						hhstore.set("token", data.token)
						this.yindao = true
					},
					error: function(code, err) {
						Toast.fail(err);
					}
				});
			},
		}
	}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
	html,
	body,
	div,
	span,
	applet,
	object,
	iframe,
	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	p,
	blockquote,
	pre,
	a,
	abbr,
	acronym,
	address,
	big,
	cite,
	code,
	del,
	dfn,
	em,
	img,
	ins,
	kbd,
	q,
	s,
	samp,
	small,
	strike,
	strong,
	sub,
	sup,
	tt,
	var,
	b,
	u,
	i,
	dl,
	dt,
	dd,
	ol,
	ul,
	li,
	fieldset,
	form,
	label,
	legend,
	table,
	caption,
	tbody,
	tfoot,
	thead,
	tr,
	th,
	td,
	textarea,
	input {
		margin: 0;
		padding: 0;
	}

	.avtive_top {
		width: 100vw;
		height: 43.6vw;
		position: relative;
		background: url("http://img.123zhs.com/assets/active_top.png?x-oss-process=image/resize,w_1500/quality,q_80") no-repeat;
		background-size: 100% 100%;
	}

	.active_toptitle {
		position: absolute;
		top: 50%;
		left: 36%;
		color: #695E4A;
		font-size: 0.8rem;
	}

	.active_list {
		margin-top: 6vw;
	}

	.active_item {
		width: 92vw;
		margin: 0 auto 4vw;
		color: #252525;
	}

	.rows {
		display: flex;
		flex-direction: row;
		align-items: center;
	}

	.active_title {
		width: 20vw;
		font-size: 1rem;
		margin-right: 2vw;
		text-align: justify;
		text-align-last: justify;
	}

	.input_div {
		width: 63vw;
		height: 10vw;
		line-height: 10vw;
		padding: 0 3vw;
		background: #FAFAFA;
		border: 1px solid #E0D5BF;
	}

	input {
		width: 100%;
		outline: 0;
		font-size: 0.8rem;
		border: 0;
		height: 8vw;
		line-height: 8vw;
		border-radius: 4px;
		background: transparent;
	}

	input[type=password],
	input[type=text],
	textarea {
		resize: none;
		outline: 0;
		-webkit-appearance: none;
		white-space: pre-wrap;
		word-wrap: break-word;
	}

	.input_divs {
		width: 32vw;
	}

	.msg_btn {
		margin-left: 4vw;
		width: 27vw;
		height: 10vw;
		line-height: 10vw;
		border-radius: 10vw;
		background: #C9B186;
		color: #fff;
		font-size: 0.9rem;
		text-align: center;
	}

	.msg_btn_disable {
		margin-left: 4vw;
		width: 27vw;
		height: 10vw;
		line-height: 10vw;
		border-radius: 10vw;
		background: rgba(201, 177, 134, 0.6);
		color: #fff;
		font-size: 0.9rem;
		text-align: center;
	}

	.submit {
		width: 92vw;
		margin: 10vw auto 0;
		height: 12vw;
		line-height: 12vw;
		border-radius: 12vw;
		text-align: center;
		background: #C9B186;
		color: #fff;
		font-size: 1rem;
	}

	.jump {
		margin-top: 1rem;
		text-align: center;
		/*position: absolute;*/
		/*bottom: 1rem;*/

		/*left: 0rem;
    right: 0rem;*/
	}

	.jump a {
		text-decoration: underline;
		font-size: 1.3rem;
	}

	input[type="text"],
	input[type="number"] {
		font-size: 16px;
	}

	.yindao {
		background-image: url("http://img.123zhs.com/h5/bg.png");
		height: 100vh;
	}

	.yindao img {
		width: 100%;
	}
</style>
