import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios';
import uuid from '../utils/Uuid'
import hhstore from '../utils/HHStore'
import help from '../utils/Help'

Vue.use(Vuex)

class NetworkError extends Error {
	constructor(code, message) {
		super(message); // (1)
		this.code = code; // (2)
	}
}

export async function postf(options) {
	// 塞入did
	var did = hhstore.get("__did");
	if (!did) {
		did = uuid.uuid();
		hhstore.set("__did", did)
	}
	options.data.did = did;

	// 塞入card_type
	var cardType = hhstore.get('card_type')
	if (cardType) {
		options.data.card_type = cardType;
	}

	// 塞入_pos参数
	var pos = hhstore.get('pos')
	if (pos) {
		options.data._pos = pos;
	}


	const UA = navigator.userAgent.toLowerCase();
	options.data.in_wechat = !!UA.match(/.*?(micromessenger\/([0-9.]+))\s*/);

	var url = process.env.VUE_APP_perUrl + options.url;
	let params = help.sign(options.data)
	let ret;
	try {
		ret = await axios({
			method: 'POST',
			data: params,
			url: url,
			headers: {
				'content-type': 'application/json'
			},
		})
	} catch (e) {
		throw new NetworkError(999, e.name + "：" + e.message)
	}

	if (ret.data.rc == 1) {
		return ret.data.data
	} else {
		throw new NetworkError(ret.data.rc, ret.data.rc + ":" + ret.data.error);
	}
}
var perUrl = process.env.VUE_APP_perUrl

const store = new Vuex.Store({
	state: {
		perUrl: perUrl,
		nnl: 0, //是否可以直接登录，不需要激活
		yingelist: [],
		homepage: '/list2',
		kefu: '',
		dot: {
		},
		hy: false,
	},
	mutations: {
		SET_NNL(state, v) {
			state.nnl = v
		},
		SET_HY(state, v) {
			state.hy = v
		},
		SET_KEFU(state, kefu) {
			state.kefu = kefu
		},
		SET_HOMEPAGE(state, homepage) {
			state.homepage = homepage
		},
		SET_DOT(state, map) {
			state.dot = {
				...state.dot,
				...map
			}
		},
	},
	actions: {
		post({
			state
		}, options) {
			postf(options).then(function (data) {
				options.success && options.success(data)
			}).catch(function (e) {
				options.error && options.error(e.code, e.message)
			})
		},

		async syncpost({
			state
		}, options) {
			try {
				return await postf(options)
			} catch (e) {
				throw e
			}
		},
	}
})
export default store
