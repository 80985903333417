import { render, staticRenderFns } from "./Yinge.vue?vue&type=template&id=243307a9&scoped=true&"
import script from "./Yinge.vue?vue&type=script&lang=js&"
export * from "./Yinge.vue?vue&type=script&lang=js&"
import style0 from "./Yinge.vue?vue&type=style&index=0&id=243307a9&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "243307a9",
  null
  
)

export default component.exports