<template>
	<div class="list">
		<div class="list_1">
			<div class="welcomediv">
				<a class='astyle' href="#">
					<img src="http://img.123zhs.com/h5/mentxiongquan/top.jpeg?x-oss-process=image/resize,w_1500/quality,q_80">
				</a>
				<div class="dueDate" v-show="showdueDate" v-on:click="showdlg = true">{{dueDate}}到期，延期 >></div>
			</div>
			
			<a class='astyle' @click="cellclicked(item, index)" v-for="(item,index) in list">
				<img :src="item.img"></a>

		</div>

		<van-dialog title="输入新权益卡，会员延期2年" v-model="showdlg" show-cancel-button confirmButtonText="确定"
			:before-close="dialogclose">
			<van-form>
				<van-field v-model="card_number" name="卡号" label="卡号" placeholder="10位的卡号" style="font-size: 16px;" />
				<van-field v-model="card_secret" name="激活码" label="激活码" placeholder="6位的激活码" style="font-size: 16px;" />
			</van-form>
		</van-dialog>
	</div>
</template>
<script>
	import {
		mapActions,
		mapState,
		store
	} from 'vuex'
	import help from '../utils/Help'
	import hhstore from '../utils/HHStore'
	import urlParams from '../utils/UrlParams'
	import wxutils from '../utils/Wx'
	import {
		Toast,
		Dialog,
		Form,
		Field,
		Button,
		NoticeBar
	} from 'vant';
	import Vue from 'vue';

	import aes from '../utils/Aes'
	var md5 = require('md5')
	Vue.use(NoticeBar);
	Vue.use(Form);
	Vue.use(Field);
	Vue.use(NoticeBar);

	export default {
		name: 'list',
		components: {
			[Dialog.Component.name]: Dialog.Component,
			[Button.name]: Button,
		},
		data: function() {
			return {
				list: [],
				card_number: '',
				card_secret: '',
				kfc: {
					menuVoList: [],
					store: {
						address: ""
					}
				},
				kfcitem: null,
				filmList: [],
				expiration_time: null,
				dueDate: "",
				info: null,
				cardType: null,
				logo: "",
				showdueDate: false,
				showdlg: false,
			}
		},
		props: {

		},
		beforeMount() {

		},
		async mounted() {

			// 获取卡类型，存本地，每个网络请求都带上这个参数
			this.cardType = urlParams.get('ch')
			if (urlParams.get('pos')) {
				hhstore.set('pos', urlParams.get('pos'))
			}

			if (this.cardType) {
				hhstore.set('card_type', this.cardType)
			} else {
				this.cardType = hhstore.get('card_type')
			}



			// 如果url里带了phone参数
			var phone = urlParams.get('phone')
			if (phone && phone != hhstore.get("phone")) {
				const ret = await this.quicklogin(phone)
				if (!ret) {
					return
				}
			}

			if (!this.nnl && !hhstore.get("token")) {
				// 微信登录失败，返回注册页面
				hhstore.set('login_back', "/products2" + window.location.search)
				this.$router.push('/register')
				return;
			}

			await this.userinfo()

			await this.homeindex(phone)
		},
		computed: {
			...mapState(['nnl', 'kefu']),

		},
		methods: {
			...mapActions(['post', 'syncpost']),


			kfcclicked() {
				this.cellclicked(this.kfcitem, 0)
			},

			async userinfo() {

				var uid = hhstore.get("uid");
				var token = hhstore.get("token");

				if (!uid) {
					return;
				}

				try {
					var data = await this.syncpost({
						url: "user/info",
						data: {
							"uid": uid,
							"token": token
						}
					});
				} catch (e) {
					if (e.code == 92834 || e.code == 10002 || e.code == 10003) {
						hhstore.del("uid")
						hhstore.del("phone")
						hhstore.del("token")
						if (!this.nnl) {
							hhstore.set('login_back', "/products2" + window.location.search)
							this.$router.push('/register')
						}
					} else {
						Toast.fail(err);
					}
				}


				this.showdueDate = true;
				this.dueDate = data.expiration_time.split(" ")[0];
				this.info = data;
				hhstore.set("phone", data.phone)
				this.expiration_time = "您的权益有效期至：" + data.expiration_time.split(' ')[0]
			},
			async quicklogin(phone) {
				try {
					let data = await this.syncpost({
						url: "user/quick_register",
						data: {
							"phone": phone,
						}
					});

					hhstore.set("uid", data.uid)
					hhstore.set("token", data.token)
					hhstore.set("phone", phone)
					return true
				} catch (e) {
					Toast.fail(e.message);
					return false
				}
			},
			async homeindex() {
				console.info(11)
				// 优先使用缓存
				try {
					this.list = JSON.parse(hhstore.get('product_list'))
				} catch (e) {}

				try {
					let uid = hhstore.get("uid");

					let data = await this.syncpost({
						url: "home2/index",
						data: {
							uid: uid
						}
					});

					this.logo = data.logo
					this.list = data.list


					this.$store.commit('SET_KEFU', data.kefu)
					hhstore.set('kefu', data.kefu)

					for (let item of data.list) {
						if (item.name == '肯德基') {
							this.kfcitem = item
						}
					}

					hhstore.set('product_list', JSON.stringify(data.list))
					this.$store.commit('SET_NNL', data.nnl)

					let auto = urlParams.get('auto')
					console.info(auto)
					for (var i = 0; i < data.list.length; i++) {
						let item = data.list[i]
						if (item.jumptag == auto) {
							this.cellclicked(data.list[0])
						}
					}

				} catch (e) {
					console.info(e)
				}
			},
			cellclicked(item, index) {
				let uid = hhstore.get("uid");
				let token = hhstore.get("token");
				let phone = hhstore.get("phone");

				if (!token) {
					Dialog.confirm({
							title: '提示',
							message: this.nnl ? '您还没有登录，立即去登录？' : '您还没有激活权益，立即去激活权益？',
						})
						.then(() => {
							hhstore.set('login_back', "/products2" + window.location.search)
							if (this.nnl) {
								this.$router.push('/login')
							} else {
								this.$router.push('/register')
							}
						})
					return;
				}


				if (item.type == "H5") {
					this.launchH5(item)
				} else if (item.type == "kfc") {
					this.launchkfc(item, phone);
				} else if (item.type == "kfczj") {
					this.launchkfczj(item, phone);
				} else if (item.type == "mdl") {
					this.launchmdl(item, phone);
				} else if (item.type == "jy" || item.type == "xjjy") {
					this.launchjy(item, phone, token, uid);
				} else if (item.type == "xbkzx") {
					this.launchxbkzx(item, phone);
				} else if (item.type == "xbkzx_hw") {
					this.launchxbkzx_hw(item, phone);
				} else if (item.type == "bgy") {
					this.launchbgy(item, phone);
				} else if (item.type == "nx") {
					this.launchnx(item, phone);
				} else if (item.type == "nxzx") {
					this.launchnxzx(item, phone);
				} else if (item.type == "nxzx_hw") {
					this.launchnxzx_hw(item, phone);
				} else if (item.type == "rx") {
					this.launchrxzx(item, phone);
				} else if (item.type == "xc") {
					this.launchxc(item, phone);
				} else if (item.type == "dy") {
					this.launchdy(item, phone);
				} else if (item.type == "dy2") {
					this.launchdy2(item, phone);
				} else if (item.type == "bsk") {
					this.launchbsk(item, phone);
				} else if (item.type == "didi") {
					this.launchdidi(item);
				} else if (item.type == "jiayou") {
					this.launchjiayou(item, uid, token);
				} else if (item.type == "didijiayou") {
					this.launchdidijiayou(item);
				} else if (item.type == "xiaozhu") {
					this.launchxiaozhu(item);
				} else if (item.type == "list") {
					this.launchlist(item);
				}
			},
			launchH5(item) {
				help.click_log(item.name, item.src)
				window.location.href = item.src;
			},
			launchlist(item) {
				hhstore.set('yinge', JSON.stringify(item))
				window.location.href = "/yinge"
			},
			launchjy(item, phone, token, uid) {
				this.post({
					url: "xjjy/geturl",
					data: {
						"uid": uid,
						"token": token
					},
					success: function(data) {
						help.click_log(item.name, data)
						window.location.href = data;
					},
					error: function(error) {
						console.info(error)
					}
				});
			},
			launchkfc(item, phone) {
				const kfcurl = item.src + "&phone=" + phone;
				help.click_log(item.name, kfcurl)
				window.location.href = kfcurl;
			},
			launchkfczj(item, phone) {
				var url =
					"http://kfc.quhaodian.cn/diancan/new.html?v=1.1&platmnt=gh_90fb3ad61e18&notify_url=http://api.zhsyh.cn/callback/kfc"

				const kfcurl = url + "&buyer_phone=" + phone;
				help.click_log(item.name, kfcurl)
				window.location.href = kfcurl;
			},
			launchbsk(item, phone) {
				// let sign = md5(phone + "8BE8WHUqUvrf3m7Y64WWgGAjRZzt")
				// let url = "http://zd.taillessbear.com/zd/bsk.php?channelid=102106&appuid=" + phone + "&sign=" + sign
				// window.location.href = url;
				let urlparams = this.hdzsign({
					"distributorCode": "1362",
					"platformuid": phone,
				}, "1fa5406e1747484e948ee4c8dabed9a9");
				console.info(urlparams)
				let url = "https://ptlxhy.cn/#/independentPizza?" + urlparams;
				help.click_log(item.name, url)
				window.location.href = url;

			},
			launchjiayou(item, uid, token) {
				this.post({
					url: "xjjy/geturl",
					data: {
						"uid": uid,
						"token": token
					},
					success: function(data) {
						help.click_log(item.name, data)
						window.location.href = data;
					},
					error: function(error) {
						Toast.fail(error);
					}
				});
			},
			async launchdidi(item) {
				Toast.loading({
					message: '加载中...',
					forbidClick: true,
					duration: 0
				});

				try {
					// h5的
					let data = await this.syncpost({
						url: "xjjy/generate_link_url",
						data: {
							"pid": item.pid,
							"type": "h5",
						},
					});

					Toast.clear()
					help.click_log(item.name, data.link)
					window.location.href = data.link;
				} catch (e) {
					Toast.fail(e.message);
				}
			},
			//花小猪
			async launchxiaozhu(item) {
				Toast.loading({
					message: '加载中...',
					forbidClick: true,
					duration: 0
				});

				try {
					// h5的
					let data = await this.syncpost({
						url: "xjjy/generate_xiaozhu_url",
						data: {
							"pid": item.pid,
							"type": "h5",
						},
					});

					Toast.clear()
					help.click_log(item.name, data.link)
					window.location.href = data.link;
				} catch (e) {
					Toast.fail(e.message);
				}
			},
			async launchdidijiayou(item) {
				Toast.loading({
					message: '加载中...',
					forbidClick: true,
					duration: 0
				});

				try {
					// h5的
					let data = await this.syncpost({
						url: "xjjy/generate_jiayou_url",
						data: {
							"pid": item.pid,
							"type": "h5",
						},
					});

					Toast.clear()
					help.click_log(item.name, data.link)
					window.location.href = data.link;
				} catch (e) {
					Toast.fail(e.message);
				}
			},
			launchxbkzx(item, phone) {
				let url = "https://ptlxhy.cn/#/independentXbk?distributorCode=1362&flag=independent"
				help.click_log(item.name, url)
				window.location.href = url
			},
			launchxbkzx_hw(item, phone) {
				let urlparams = this.hwsign({
					"shareCode": "6492aNymP",
					"userId": phone,
				}, "408957BF28AD4A84B359D45D0DDCE8EE");
				let url = "https://ot.jfshou.cn/sbkplus/auth/index?" + urlparams;
				help.click_log(item.name, url)
				window.location.href = url;
			},
			launchbgy(item, phone) {
				let urlparams = this.hwsign({
					"shareCode": "6492aNymP",
					"userId": phone,
				}, "408957BF28AD4A84B359D45D0DDCE8EE");
				let url = "https://ot.jfshou.cn/privilege-api/pagoda/auth/index?" + urlparams;
				help.click_log(item.name, url)
				window.location.href = url;
			},
			launchmdl(item, phone) {
				let timestamp = new Date().getTime();
				let shareCode = "6492aNymP";
				let s = "408957BF28AD4A84B359D45D0DDCE8EE";

				let beforesign = "shareCode=" + shareCode + "&timestamp=" + timestamp + "&userId=" + phone +
					"&secretKey=" + s;
				console.info(beforesign);

				let sign = md5(beforesign).toUpperCase();
				console.info(sign);

				let urlparams = "share_code=" + shareCode + "&timestamp=" + timestamp + "&user_id=" + phone + "&sign=" +
					sign;
				console.info(urlparams);

				const mdlurl = "https://ot.jfshou.cn/api/mcdonald/entrance?" + urlparams;
				console.info(mdlurl);
				help.click_log(item.name, mdlurl)
				window.location.href = mdlurl;
			},
			launchnxzx(item, phone) {
				let url = "https://ptlxhy.cn/#/independentNx?distributorCode=1362&flag=independent"
				help.click_log(item.name, url)
				window.location.href = url
			},
			launchnxzx_hw(item, phone) {
				let urlparams = this.hwsign({
					"shareCode": "6492aNymP",
					"userId": phone,
				}, "408957BF28AD4A84B359D45D0DDCE8EE");
				let url = "https://ot.jfshou.cn/api/nayuki/entrance?" + urlparams;
				help.click_log(item.name, url)
				window.location.href = url;
			},
			launchnx(item, phone) {
				let urlparams = this.hwsign({
					"id": "94",
					"machine_code": phone,
					"single_sign": "true",
					"distribution_code": "yF4FMa4",
				}, "T4KHEanXQcbf3sRmzZcTMZyetS4mZ3QG");
				let url = "https://tq.jfshou.cn/seller/videoApp/appVideo?" + urlparams;
				help.click_log(item.name, url)
				window.location.href = url;
			},
			launchxc(item, phone) {
				let urlparams = this.hwsign({
					"shareCode": "6492aNymP",
					"userId": phone,
				}, "408957BF28AD4A84B359D45D0DDCE8EE");
				let url = "https://ot.jfshou.cn/privilege-api/heytea/auth/index?" + urlparams;
				help.click_log(item.name, url)
				window.location.href = url;
			},
			launchrxzx(item, phone) {
				let urlparams = this.hwsign({
					"shareCode": "647J8fs9s",
					"userId": phone,
				}, "4607280171F242359D12205473FD0FF5");
				let url = "https://ot.jfshou.cn/privilege-api/luckin/auth/index?" + urlparams;
				help.click_log(item.name, url)
				window.location.href = url;
			},
			launchdy(item, phone) {
				let url = "https://ptlxhy.cn/#/independentFilm?distributorCode=1362&flag=independent"
				help.click_log(item.name, url)
				window.location.href = url;
			},
			launchdy2(item, phone) {
				let urlparams = this.hwsign({
					"shareCode": "6492aNymP",
					"userId": phone,
				}, "408957BF28AD4A84B359D45D0DDCE8EE");
				let url = "https://ot.jfshou.cn/ticket/auth/index?" + urlparams;
				window.location.href = url;
			},
			// 海威的签名
			hwsign(data, secretKey) {
				data["timestamp"] = new Date().getTime();

				let beforesign = this.jsonSort(data) + "&secretKey=" + secretKey
				console.info(beforesign);

				let sign = md5(beforesign).toUpperCase();
				console.info(sign);

				data['sign'] = sign;
				let urlparams = this.jsonSort(data)
				console.info(urlparams);

				return urlparams
			},
			jsonSort(jsonObj) {
				let arr = [];
				for (var key in jsonObj) {
					arr.push(key)
				}
				arr.sort();
				let str = '';
				for (var i in arr) {
					str += arr[i] + "=" + jsonObj[arr[i]] + "&"
				}
				return str.substr(0, str.length - 1)
			},
			handlekefu() {
				window.location.href = this.kefu
			},
			hdzsign(data, secretKey) {
				data["time"] = new Date().getTime();

				let beforesign = this.joinmap(data) + secretKey
				console.info(beforesign);

				let sign = md5(beforesign);
				console.info(sign);

				data['sign'] = sign;
				let urlparams = this.jsonSort(data, false)
				console.info(urlparams);

				return urlparams
			},
			// 海威的签名字符串链接
			joinmap(jsonObj) {
				let arr = [];
				for (var key in jsonObj) {
					arr.push(key)
				}
				arr.sort();
				let str = '';
				for (var i in arr) {
					str += jsonObj[arr[i]]
				}
				return str
			},
			dialogclose(action, done) {

				if (action == "cancel") done();

				if (action == "confirm") {
					var self = this;
					this.post({
						url: "user/upgrade",
						data: {
							"uid": hhstore.get("uid"),
							"token": hhstore.get("token"),
							"card_number": self.card_number.trim(),
							"card_secret": self.card_secret.trim(),
						},
						success: function(data) {
							Toast.success("延期成功");
							self.dueDate = data.expiration_time.split(" ")[0];
							done()
						},
						error: function(code, msg) {
							Toast.fail(msg);
							done(false)
						}
					});
				};

			},
		}
	}
</script>
<style scoped>
	.welcomediv {
		position: relative;
	}

	.dueDate {
		position: absolute;
		right: 0.5rem;
		top: 10px;
		color: #fff;
		font-size: 1rem;
		border-radius: 5px;
		border-color: #000;
		background-color: #C9B186;
		padding: 0px 5px;
	}


	.astyle {
		display: block;
	}

	.astyle>img {
		background-size: contain|cover;
		width: 100%;
		height: auto;
	}

	.wxbtn {
		display: block;
	}

	.topdiv {
		background-color: #c9b186;
		height: 25px;
	}
</style>
