<template>
	<van-popup v-model="shopPop" position="bottom" round :style="{ height: '250px' }" @closed="popclosed">
		<div class="loginform">
			<div class="logintitle">一键登录</div>
			<div class="phonediv"><input class="phoneinput" v-model="phone" placeholder="输入手机号" /> </div>
			<div class="codediv"><input class="codeinput" v-model="code" placeholder="输入验证码" /><button class="codebutton"
					@click="sendsms">{{ codetext }}</button> </div>
			<div><button class="loginbtn" @click="login">登录</button></div>
		</div>
	</van-popup>
</template>

<script>
import {
	Toast
} from 'vant';
import hhstore from '../utils/HHStore'

import {
	mapActions,
	mapState
} from 'vuex'
export default {
	props: {
		showLogin: {
			type: Boolean,
			default: true
		},
	},
	watch: {
		// 监视属性名
		showLogin: function (newValue, oldValue) {
			this.shopPop = newValue
		},
	},
	data: function () {
		return {
			shopPop: false,
			phone: '',
			codetext: '获取验证码',
			code: '',
		}
	},
	methods: {
		...mapActions(['post']),

		sendsms() {
			if (this.codetext != '获取验证码') {
				return
			}

			if (this.phone.trim().length < 11) {
				Toast.fail('手机号格式不正确');
				return
			}
			Toast.loading({
				message: '请稍候...',
				forbidClick: true,
				duration: 0
			});

			var url = "sms/loginCode/" + this.phone.trim() + "/KDI3EN2DLS";
			if (process.env.NODE_ENV === "production")
				url = "sms/loginCode/" + this.phone.trim();

			this.post({
				url: url,
				data: {},
				success: () => {
					Toast.success("发送成功");

					var count = 60;
					this.codetext = count + "s";
					this.intervalId = setInterval(() => {
						count--;
						if (count <= 0) {
							clearInterval(this.intervalId);
							this.codetext = "获取验证码";
						} else {
							this.codetext = count + "s";
						}
					}, 1000);
				},
				error: function (code, err) {
					Toast.fail(err);
				}
			});
		},
		popclosed() {
			this.$emit('update:showLogin', false);

		},
		login() {
			if (this.code.trim().length < 1) {
				Toast.fail('请输入验证码');
				return
			}

			var url = "user/quick_register_code";


			this.post({
				url: url,
				data: {
					phone: this.phone,
					code: this.code,
					desc: "酷我快速注册"
				},
				success: (data) => {
					console.info(data)
					hhstore.set("uid", data.uid)
					hhstore.set("token", data.token)
					this.$emit('update:showLogin', false);
					Toast.success("登录成功");
				},
				error: function (code, err) {
					Toast.fail(err);
				}
			});

		},
	}
}
</script>

<style scoped>
.loginform {
	display: flex;
	flex-direction: column;
	background-color: white;
	width: 100%;
	height: 100%;
	/* align-items: center; */
	padding: 20px;
	box-sizing: border-box;

}


.logintitle {
	font-size: 1.25rem;
	font-weight: bold;
}

.phonediv {
	border-bottom-style: solid;
	border-bottom-width: 1px;
	border-bottom-color: #e7e7e7;
	width: 90vw;
	margin-top: 5px;
}

.phoneinput {
	width: 80vw;
	height: 40px;
	border-style: none;
}

.codediv {
	margin-top: 5px;
	border-bottom-style: solid;
	border-bottom-width: 1px;
	border-bottom-color: #e7e7e7;
	width: 90vw;
	display: flex;
	justify-content: space-between;
}

.codeinput {
	width: 40vw;
	height: 40px;
	border-style: none;
}

.codebutton {
	font-size: 14px;
	text-align: center;
	border-style: none;
	background-color: rgba(0, 0, 0, 0);
	width: 120px;
}

.loginbtn {
	margin-top: 20px;
	width: 90vw;
	height: 40px;
	border-style: none;
	background-color: #57BE6A;
	color: white;
}
</style>
