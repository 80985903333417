<template>
	<div class="contain">
		<div class="scrollow">
			<div>
				<div class="head">{{ info.name }}</div>
				<div class="head-desc">
					{{ info.info }}
				</div>
			</div>


			<div class="aui_box">
				<div @click="clicked(item2)" class="aui-palace-grid" v-for="(item2, index2) in list">
					<div class="aui-palace-grid-icon">
						<img v-bind:src="item2.img">
					</div>
					<div class="aui-palace-grid-text">
						<div class="listtitle">{{ item2.title }}</div>
						<div class="listprice">

							<div class="listprice-1">{{ formatRMB(item2.price / 100) }}</div>
							<div class="listprice-2">市场价{{ formatRMB(item2.original_price / 100) }}</div>
						</div>

					</div>
				</div>

			</div>
		</div>

		<LoginPopup :showLogin.sync="showLogin"></LoginPopup>
	</div>
</template>
<script>
import help from '../utils/Help'

import {
	mapActions,
	mapState
} from 'vuex'
import hhstore from '../utils/HHStore'
import urlParams from '../utils/UrlParams'
import LoginPopup from '../components/LoginPopup.vue'
import wxutils from '../utils/Wx'
import {
	Toast
} from 'vant';
var md5 = require('md5')
import {
	Form
} from 'vant';
import { Popup } from 'vant';

import {
	Field
} from 'vant';
import {
	Dialog
} from 'vant';
import Vue from 'vue';
import aes from '../utils/Aes'

Vue.use(Form);
Vue.use(Field);
Vue.use(Popup);

export default {
	name: 'list',
	components: {
		LoginPopup,
		[Dialog.Component.name]: Dialog.Component,
	},
	data: function () {
		return {
			showLogin: false,
			info: {},
			list: []
		}
	},
	props: {

	},
	beforeMount() {

	},
	mounted() {
		// hhstore.set('card_type', 'lmqvhe')
		let ch = urlParams.get('card_type')
		if (ch) {
			hhstore.set('card_type', ch)
		}

		this.init()

	},
	computed: {
		...mapState([])
	},
	methods: {
		...mapActions(['post', 'syncpost']),

		async init() {
			let id = urlParams.get('id')
			// 
			try {
				this.info = await this.syncpost({
					url: "starbucks/qm_category_info",
					data: {
						"id": id,
					}
				});
			} catch (e) {
				console.info(e)
				return;
			}
			

			this.post({
				url: "starbucks/qm_list",
				data: {
					cat: urlParams.get('cat')
				},
				success: data => {
					this.list = data
				},
				error: function (error) {
					console.info(error)
				}
			});
		},
		clicked(item) {
			if (!hhstore.get("token")) {
				this.showLogin = true
				return
			}
			this.$router.push(`/qm?gid=${item.id}`)
		},
		formatRMB(amount) {
			return help.formatRMB(amount)
		}
	}
}
</script>
<style scoped>
.contain {
	height: 100vh;
	overflow-y: scroll;
	background-color: #F0F0F0;
	font-family: -apple-system, BlinkMacSystemFont, 'Helvetica Neue', Helvetica, Segoe UI, Arial, Roboto, 'PingFang SC', miui, 'Hiragino Sans GB', 'Microsoft Yahei', sans-serif;
}

.head {
	background-color: white;
	color: #222;
	font-size: 1.125rem;
	font-weight: 700;
	border-bottom: 1px solid #e5e5e5;
	padding: 0.90667rem 0.85333rem;
}

.head-desc {
	background-color: white;
	padding: 0.90667rem 0.85333rem;
}

.aui_box {
	margin-top: .625rem;
	background-color: white;
	padding: 15px;
	/* padding: 0.8rem 0 0.53333rem; */
}

.scrollow {}

.aui-palace-grid {
	gap: 10px;
	display: flex;
	margin-bottom: 20px;
}

.aui-palace-grid:last-child {
	margin-bottom: 0;
}

.aui-palace-grid-icon img {
	width: 100px;
	height: 100px;
	border-radius: 50px;
}

.aui-palace-grid-text {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: 100%;
}

.listtitle {
	color: #222;
	font-size: 1.125rem;
	font-weight: 700;
}

.listprice {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: flex-end
}

.listprice-1 {

	font-size: 1.125rem;
	font-weight: 700;
	color: rgb(251, 96, 70);

}

.listprice-2 {
	text-decoration: line-through;
	display: flex;
	color: rgb(187, 187, 187);
	font-size: 14px;
	justify-content: space-between;
}
</style>


<style>
p {
	margin-top: 2px;
	margin-bottom: 2px;
}
</style>
